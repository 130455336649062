<template>
  <section class="header-section">
    <div class="wrapper">
      <div class="for-hover-align">
      <div class="col-lg-12">
        <div class="row nav-bar">
          <div class="col-lg-4 col-md-4">
            <div class="header-logo">
              <a href="/" class="d-flex align-items-center">
                <img src="../assets/images/vayal-new-logo.png" alt="" />
                <p>VAYAL<span>AGRO</span>&#8482;</p>
              </a>
              <div class="top-advertise-btn">
               <p :class="{ 'tamil-lan': selectedLanguage === 'ta', 'header-content': selectedLanguage !== 'ta' }">
                <img src="../assets/images/lang-icon.svg"  alt="" class="lan-icon">
              <select  class="language-select" v-model="selectedLanguage">
                <option value="en" >EN</option>
                <option value="ta" >TN</option>
              </select>
              </p> 
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-8">
            <div class="header-cont">
              <div class="hover-container" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
              <p :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'header-content forhover': selectedLanguage !== 'ta' }"><img src="../assets/images/header-component/nav-category-icon.svg" alt="">
                <!-- Categories -->
                {{ translations.categories }}
              </p>
             <div class="col-lg-12 col-md-12" v-if="isHovered">
                  <div class="advertise-products-main-div">
                    <div class="col-lg-7 col-md-7">
                      <div class="advertise-products-div">  
                        <div class="product-div">
                          <h4 class="product-heading">{{ translations.products }}</h4>             
                          <p v-for="(item, index) in category_data" :key="index" class="product-content"  @click="closeDropdown">
                            <router-link
                            :class="{ 'tamil-route-link': selectedLanguage === 'ta', 'route-link': selectedLanguage !== 'ta' }"
                              :to="
                                item.hasSubCategory
                                  ? {
                                      name: 'SubcategoryPage',
                                      params: {
                                        categoryId: item.category_id,
                                        categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                        location: 'tamilnadu',
                                      },
                                    }
                                  : {
                                      name: 'ListComponent',
                                      params: {
                                        product: item.name.replace(/\s/g, '-').toLowerCase(),
                                        location: 'tamilnadu',
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                      },
                                    }
                              "
                            >
                              {{ selectedLanguage === "en" ? item.name : item.tn_name }}
                            </router-link>
                          </p>            
                          <router-link  :class="{ 'tamil-route-link v-more': selectedLanguage === 'ta', 'route-link v-more': selectedLanguage !== 'ta' }" :to="'/tamilnadu/agriculture-products'" @click.native="closeDropdown"> {{ translations.viewmore }}... </router-link>

                        </div>

                        <div class="product-div">
                          <h4 class="product-heading">{{ translations.equipments }}</h4>
                          <p v-for="(item, index) in equipment_data" :key="index" class="product-content" @click="closeDropdown">
                            <router-link
                             :class="{ 'tamil-route-link': selectedLanguage === 'ta', 'route-link': selectedLanguage !== 'ta' }" 
                              :to="
                                item.hasSubCategory
                                  ? {
                                      name: 'SubcategoryPage',
                                      params: {
                                        categoryId: item.category_id,
                                        categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                        location: 'tamilnadu',
                                      },
                                    }
                                  : {
                                      name: 'ListComponent',
                                      params: {
                                        product: item.name.replace(/\s/g, '-').toLowerCase(),
                                        location: 'tamilnadu',
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                      },
                                    }
                              "
                            >
                             {{ selectedLanguage === "en" ? item.name : item.tn_name }}
                            </router-link>
                          </p>
                          <router-link  :class="{ 'tamil-route-link  v-more': selectedLanguage === 'ta', 'route-link  v-more': selectedLanguage !== 'ta' }" :to="'/tamilnadu/agriculture-equipments'" @click.native="closeDropdown">  {{ translations.viewmore }}...</router-link>
                        </div>

                        <div class="product-div">
                          <h4 class="product-heading">{{ translations.livestocks }}</h4>
                          <p v-for="(item, index) in livestock_data" :key="index" class="product-content" @click="closeDropdown">
                            <router-link
                             :class="{ 'tamil-route-link': selectedLanguage === 'ta', 'route-link': selectedLanguage !== 'ta' }" 
                              :to="
                                item.hasSubCategory
                                  ? {
                                      name: 'SubcategoryPage',
                                      params: {
                                        categoryId: item.category_id,
                                        categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                        location: 'tamilnadu',
                                      },
                                    }
                                  : {
                                      name: 'ListComponent',
                                      params: {
                                        product: item.name.replace(/\s/g, '-').toLowerCase(),
                                        location: 'tamilnadu',
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                      },
                                    }
                              "
                            >
                            {{ selectedLanguage === "en" ? item.name : item.tn_name }}
                            </router-link>
                          </p>
                          <router-link  :class="{ 'tamil-route-link v-more': selectedLanguage === 'ta', 'route-link v-more': selectedLanguage !== 'ta' }" :to="'/tamilnadu/livestocks'" @click.native="closeDropdown">  {{ translations.viewmore }}...</router-link>
                        </div>
                      </div>
                    </div>    
                    <div class="col-lg-5 col-md-5">
                      <div class="product-img-div">
      <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank"><img src="../assets/images/header-component/Header-banner.webp" alt=""></a>
                      </div>
                    </div>      
                  </div>
                </div>
              </div>
              <p :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'header-content': selectedLanguage !== 'ta' }" ><img src="../assets/images/header-component/nav-mp-icon.svg" alt=""><a href="/market-price">
              <!-- Market Price -->
              {{ translations.marketPrice }}
              </a>
              </p>
              <p :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'header-content': selectedLanguage !== 'ta' }"><img src="../assets/images/header-component/nav-adver-icon.svg"
                  alt=""><a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank">
                    <!-- Advertise Products -->
                    {{ translations.advertiseProducts }}
                    </a></p>
                   <p :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'header-content': selectedLanguage !== 'ta' }"><img src="../assets/images/lang-icon.svg"
                  alt=""><select  class="language-select" v-model="selectedLanguage">
                <option value="en" >EN</option>
                <option value="ta" >TN</option>
              </select></p> 
            </div>
          </div>
          <div class="mobile-cont">
            <div class="mobile-div" @click="toggleAdvertiseProduct">
              <img src="../assets/images/header-component/nav-category-icon.svg" alt="">
              <p class="mbl-header-content all-cate">
                <!-- Categories -->
               {{ translations.Allcategories }}
              </p>
            </div>

            <div class="mobile-div advertise-product" >
              <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank">
              <div class="login-div">
                <i class="fa-solid fa-plus" style="color: #ffffff;"></i>
              </div>
              <p class="mbl-header-content ad-product">
                <!-- Advertise Products -->
                {{ translations.advertiseProducts }}
              </p>
              </a>
            </div>
            <div class="mobile-div">
              <img src="../assets/images/header-component/nav-mp-icon.svg" alt="">
              <a href="/market-price"><p class="mbl-header-content all-cate">
                <!-- Market Price -->
                {{ translations.marketPrice }}
              </p></a>
            </div>       
          </div>
        </div>
      </div>
      </div>
    </div>
    <div :class="{ 'show': isAdvertiseVisible }" class="advertise-content">
     
  <div class="mobile-advertise-products-div">  
    <div @click="toggleAdvertiseProduct" class="advertise-div">
        <i class="fa-solid fa-xmark advertise-btn"></i>
      </div>
    <div class="mobile-product-div">
      <h4 class="mobile-product-heading">{{ translations.products }}</h4>             
      <p v-for="(item, index) in category_data" :key="index" class="mobile-product-content"  @click="toggleAdvertiseProduct" >
        <router-link 
          class="mobile-router-link"
          :to="
            item.hasSubCategory
              ? {
                  name: 'SubcategoryPage',
                  params: {
                    categoryId: item.category_id,
                    categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                    category:
                      item.category_type === 'Live stocks'
                        ? 'livestocks'
                        : item.category_type
                            .replace(/\s/g, '-')
                            .replace('farm', 'agriculture'),
                    location: 'tamilnadu',
                  },
                }
              : {
                  name: 'ListComponent',
                  params: {
                    product: item.name.replace(/\s/g, '-').toLowerCase(),
                    location: 'tamilnadu',
                    category:
                      item.category_type === 'Live stocks'
                        ? 'livestocks'
                        : item.category_type
                            .replace(/\s/g, '-')
                            .replace('farm', 'agriculture'),
                  },
                }
          "
         
        >
          {{ selectedLanguage === "en" ? item.name : item.tn_name }}
        </router-link>
      </p>   
      <p @click="toggleAdvertiseProduct">
        <router-link 
        class="mobile-router-link mobile-router-link-view-more" 
        :to="'/tamilnadu/agriculture-products'"
      >
        {{ translations.viewmore }}...
      </router-link>
      </p>   
    </div>      
      <div class="mobile-product-div">
                          <h4 class="mobile-product-heading">{{ translations.equipments }}</h4>
                          <p v-for="(item, index) in equipment_data" :key="index" class="mobile-product-content" @click="toggleAdvertiseProduct">
                            <router-link
                            class="mobile-router-link"
                              :to="
                                item.hasSubCategory
                                  ? {
                                      name: 'SubcategoryPage',
                                      params: {
                                        categoryId: item.category_id,
                                        categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                        location: 'tamilnadu',
                                      },
                                    }
                                  : {
                                      name: 'ListComponent',
                                      params: {
                                        product: item.name.replace(/\s/g, '-').toLowerCase(),
                                        location: 'tamilnadu',
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                      },
                                    }
                              "
                            >
                              {{ selectedLanguage === "en" ? item.name : item.tn_name }}
                            </router-link>
                          </p>
                          <p @click="toggleAdvertiseProduct"><router-link class="mobile-router-link mobile-router-link-view-more" :to="'/tamilnadu/agriculture-equipments'">  {{ translations.viewmore }}...</router-link></p>
                          
                        </div>

                        <div class="mobile-product-div livestock-div">
                          <h4 class="mobile-product-heading">{{ translations.livestocks }}</h4>
                          <p v-for="(item, index) in livestock_data" :key="index" class="mobile-product-content" @click="toggleAdvertiseProduct">
                            <router-link
                            class="mobile-router-link"
                              :to="
                                item.hasSubCategory
                                  ? {
                                      name: 'SubcategoryPage',
                                      params: {
                                        categoryId: item.category_id,
                                        categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                        location: 'tamilnadu',
                                      },
                                    }
                                  : {
                                      name: 'ListComponent',
                                      params: {
                                        product: item.name.replace(/\s/g, '-').toLowerCase(),
                                        location: 'tamilnadu',
                                        category:
                                          item.category_type === 'Live stocks'
                                            ? 'livestocks'
                                            : item.category_type
                                                .replace(/\s/g, '-')
                                                .replace('farm', 'agriculture'),
                                      },
                                    }
                              "
                            >
                              {{ selectedLanguage === "en" ? item.name : item.tn_name }}
                            </router-link>
                          </p>
                          <p @click="toggleAdvertiseProduct"><router-link class="mobile-router-link mobile-router-link-view-more" :to="'/tamilnadu/livestocks'">{{ translations.viewmore }}...</router-link></p>
                          
    </div>
    <div class="product-img-div">
      <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank"><img src="../assets/images/header-component/Header-banner.webp" alt=""></a>
    </div>
  </div>
</div>
<div v-if="isAdvertiseVisible" class="overlayy"></div>
  </section>
</template>
<script>
import { en, ta } from "../translations.js";
import axios from "axios";

export default {
  name: "HeaderComponent",
  data() {
    return {
       isAdvertiseVisible: false,
      isHovered: false,
       payload: {
        categorytype: "farm products",
        equipments: "farm equipments", 
        livestock: "livestocks",
      },
      category_data: [],
      equipment_data: [], 
      livestock_data: [],
      selectedLanguage: "en", // Default language
      translations: en, // Default translations
    };
  },
  created() {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
      this.translations = storedLanguage === "en" ? en : ta;
    }
  },
    methods: {
   
    toggleAdvertiseProduct() {
      this.isAdvertiseVisible = !this.isAdvertiseVisible;
      if (this.isAdvertiseVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
         closeDropdown() {
        this.isHovered = false; 
        if (this.isHovered) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
  },
    async fetchCategoryData(type, key) {
      this.isLoading = true;
      try {
        const response = await axios.post("https://vaiyal-app.herokuapp.com/categorytype", {
          categorytype: type,
        });
        this[key] = response.data?.data?.slice(0, 5).map((item) => ({
          id: item.category_id || "N/A",
          name: item.category_name || "No Name",
          tn_name: item.tn_name || "No Name",
          hasSubCategory: item.hasSubCategory || false,
          category_name: item.category_name,
          category_type: item.category_type
        })) || [];
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
        this[key] = [];
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchCategoryData(this.payload.categorytype, "category_data");
    this.fetchCategoryData(this.payload.equipments, "equipment_data"); 
    this.fetchCategoryData(this.payload.livestock, "livestock_data");
  },
  watch: {
    selectedLanguage(newLang) {
      localStorage.setItem("selectedLanguage", newLang);
      this.translations = newLang === "en" ? en : ta;

      // Emit event to parent (App.vue)
      this.$emit("language-changed", newLang);

      // Clear the language after 5 minutes
      setTimeout(() => {
        localStorage.removeItem("selectedLanguage");
      }, 5 * 60 * 1000);
    },
    //  isHovered(newValue) {
    //   if (newValue) {
    //      document.body.style.overflow = "hidden";
    //   } else {
    //     document.body.style.overflow = "auto";
    //   }
    // },
  },
};

</script>
<style>
@import url("/src/components/Header.scss");
</style>