var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"category-page-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('div',{staticClass:"title-banner"},[_c('div',{staticClass:"title-content"},[_c('h1',{class:{
                  'tamil-title-h2': _vm.selectedLanguage === 'ta',
                  'title-h2': _vm.selectedLanguage !== 'ta',
                }},[_vm._v(" "+_vm._s(_vm.translations[`${_vm.$route.params.category.replace(/-/g, "_")}`])+" ")]),_c('div',{staticClass:"address-section"},[_c('img',{attrs:{"src":require("../assets/images/category-page-home-icon.svg"),"alt":""}}),_c('p',{staticClass:"footer-add"},[_c('a',{attrs:{"href":"/"}},[_vm._v("  "+_vm._s(_vm.translations.home_lite))])])])])])])])]),_c('div',{staticClass:"col-lg-12 mt-3"},[_c('div',{staticClass:"row",attrs:{"id":"row-reverse"}},[_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-12 order-2 order-lg-1"},[_c('SidebarComponent',{attrs:{"selectedLanguage":_vm.selectedLanguage}}),_c('NewproductComponent',{attrs:{"selectedLanguage":_vm.selectedLanguage}})],1),_c('div',{staticClass:"col-lg-9 col-md-8 col-sm-12 order-1 order-lg-2"},[(_vm.categories.length == 0 && _vm.isLoading)?_c('div',[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"row"},_vm._l((12),function(data){return _c('div',{key:data.id,staticClass:"col-6 col-sm-6 col-md-4 col-lg-3 mb-3"},[_c('p',{staticClass:"shimmer-line-img"}),_c('p',{staticClass:"shimmer-line"}),_c('p',{staticClass:"shimmer-line"})])}),0)])]):(_vm.categories.length === 0)?_c('div',[_vm._m(0),_c('h2',{staticClass:"my-3 no-data"},[_vm._v(" Subcategory is Not Available for here!! ")]),_c('p',{staticClass:"no-data-para"},[_vm._v(" We're sorry, Please check back later. You can browse our website to find alternative Category. ")])]):_vm._e(),_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"subcategorys"},[(_vm.hasNonEmptySubCategory(category))?_c('h2',[_vm._v(" "+_vm._s(_vm.selectedLanguage === "en" ? category.category_name : category.tn_category)+" ")]):_vm._e(),(category.hasSubCategory)?_c('div',{staticClass:"row mt-4"},_vm._l((category.subCategories),function(subCategory){return _c('div',{key:subCategory.sub_category_id,staticClass:"col-6 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"subcategory-grid"},[_c('router-link',{attrs:{"to":_vm.navigateToAllList(
                        category.category_name,
                        subCategory.sub_category_name
                      )}},[_c('div',{staticClass:"sub-cate"},[_c('img',{staticClass:"sub-img",attrs:{"src":subCategory.images[0] ||
                          require('../assets/images/No-Data-list.webp'),"alt":""}})]),_c('h3',[_vm._v(" "+_vm._s(_vm.selectedLanguage === "en" ? subCategory.sub_category_name : subCategory.tn_sub_name)+" ")]),_c('p',[_vm._v(" "+_vm._s(subCategory.list)+" "+_vm._s(_vm.translations.totalItems)+" ")])])],1)])}),0):_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-12"},[_c('div',{staticClass:"subcategory-grid"},[_c('router-link',{attrs:{"to":_vm.navigateToAllList(category.category_name)}},[_c('div',{staticClass:"sub-cate"},[_c('img',{staticClass:"sub-img",attrs:{"src":category.images[0] ||
                        require('../assets/images/No-Data-list.webp'),"alt":""}})]),_c('h3',[_vm._v(" "+_vm._s(_vm.selectedLanguage === "en" ? category.category_name : category.tn_category)+" ")]),_c('p',[_vm._v(_vm._s(category.list)+" "+_vm._s(_vm.translations.totalItems))])])],1)])])}),(_vm.categories.length != 0 && _vm.isLoading)?_c('div',{staticClass:"loader"},[_c('div',{staticClass:"row"},_vm._l((12),function(data){return _c('div',{key:data.id,staticClass:"col-6 col-sm-6 col-md-4 col-lg-3 mb-3"},[_c('p',{staticClass:"shimmer-line-img"}),_c('p',{staticClass:"shimmer-line"}),_c('p',{staticClass:"shimmer-line"})])}),0)]):_vm._e(),_c('div',{staticClass:"subcategorys text-center mt-4"},[( _vm.currentPage < _vm.totalPages && !_vm.isLoading )?_c('button',{staticClass:"show-more-btn",on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.translations.viewmore)+" ")]):_vm._e()]),_c('div',{staticClass:"col-lg-12"})],2)])])]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 bottom-ads"},[_c('AdsComponent')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nodata-img mt-5"},[_c('img',{attrs:{"src":require("../assets/images/emptypro.webp"),"loading":"lazy","alt":"empty"}})])
}]

export { render, staticRenderFns }