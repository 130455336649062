<template>
 <section>
 <section class="banner-section">
  <div class="wrapper">
    <div class="col-12 vayal-head">
      <p :class="{ 'vayal-small-content-tamil': selectedLanguage === 'ta', 'vayal-small-content': selectedLanguage !== 'ta' }">{{ translations.bannerHead }}</p>
      <h1 :class="{ 'bestsel-tamil-fonts': selectedLanguage === 'ta', 'bestsel-title': selectedLanguage !== 'ta' }">
        {{ translations.bestSel }}
      </h1>
      <p  :class="{ 'agri-para-tamil': selectedLanguage === 'ta', 'agri-para': selectedLanguage !== 'ta' }">{{ translations.bannerList }}</p>
    </div>
  </div> 
 </section>
  <section
   :class="['search-section', { 'popup-search': isPopupVisible, 'hidden': !isPopupVisible }]"
  
>
  <div  :class="['wrapper', { 'popup-wrapper': isPopupVisible }]">
    <div :class="['search-seion', { 'popup-sech': isPopupVisible }]" @click="openSearch">
       <h5>Pick Your Product from your nearby location?</h5>
   </div>
     <div :class="[ 'close-search',{'close-icon': isPopupVisible }]" >
      <i class="fa-solid fa-xmark" style="color: #ffffff;" @click="closePopup"></i>
     </div>
    <div :class="['search-seion1', { 'popup-sech1': isPopupVisible }]" @click="openSearch">
       <h5></h5>
   </div>

    <div :class="['col-lg-12 search-bar-banner', { 'popup-search-bar': isPopupVisible }]" @click="openSearch">
      
      <div class="row">
        <div class="col-lg-4 col-md-4 location">
          <form class="custom col-lg-12 col-md-12">
          <img src="../assets/images/banner-location.svg" alt="" class="banner-loction-icon" />
          <input :placeholder="translations.tamilnadu" class="form-control border-0 shadow-none form-control-lg location-form"
                v-model="location" @input="updateSearchResults" @focus="updateSearchResults"
                @click="onSearchResultsClick" autocomplete="off" type="search" id="example-search-input4" />
              <ul v-if="searchResults.length" class="search-results">
                <li v-for="result in searchResults" :key="result.market_id"
                  @click="selectSearchResult(result, 'location')">
                  <span>
                    {{ selectedLanguage === "en"
                      ? result.market_name
                      : result.tn_name
                    }}
                  </span>
                </li>
              </ul>

          </form>
        </div>
        <div class="col-lg-6 col-md-6 location">
          <form class="custom col-lg-12 col-md-12">
            <img src="../assets/images/search-icon.svg" alt="" class="location-icon"/>
          <input type="text" :placeholder="translations.bannerSearch"
              class="form-control border-0 shadow-none form-control-lg product-form" id="inputCity" v-model="product"
              @input="updateProductSearchResults" autocomplete="off" />

            <ul v-if="productSearchResults.length" class="search-results">
              <li v-for="result in productSearchResults" :key="result.category_id || result.sub_category_id"
                @click="selectSearchResult(result, 'product')">
                <span>
                  {{ selectedLanguage === "en"
                            ?
                        (result.sub_category_name || result.category_name) : (result.sub_category_tamil_name || result.category_tamil_name) 
                      }}
                </span>
              </li>
            </ul>
          </form>
        </div>
        <div class="col-lg-2 col-md-2">
          <div class="search-btn" @click="submitSearch" :disabled="product.trim() === ''">
          <button class="search-btn-banner">{{ translations.search }}</button>
          </div>
        </div>
      </div>
      
    </div>
    <div class="row">
        <div :class="['col-lg-12 promoted-sec ', { 'promoted': isPopupVisible }]">
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Ghee</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Palkova</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Panner</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Potato</p>
           <p><span><img src="../assets/images/trading-icon.svg" alt=""></span>Sweet Potato</p>     
        </div>
      </div>
  </div>
</section>

</section>
</template>

<script>
import { useUserStore } from "@/stores/userStore";
import { en, ta } from "../translations";

export default {
  name: "app",
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      isPopupVisible: false,
      location: "",
      product: "",
      searchResults: [],
      productSearchResults: [],
      routeName: "",
      params: "",
      isNameSelected: false,
      isLocSelected: false,
      defaultLocationEn: "",
      defaultLocationTa: "",
      defaultProductEn: "",
      defaultProductTa: "",
      activeSearchType: null,
    };
  },
  created() {
    if (this.$route.params.market_name) {
      this.location = this.$route.params.market_name;
    }
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
    }
    this.isNameSelected = false;
    this.isLocSelected = false;
  },
 computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
      openSearch() {
    this.isPopupVisible = true;
    document.body.classList.add('no-scroll'); // Add class to disable scrolling
  },
  closePopup(event) {
     this.searchResults = [];
        this.productSearchResults = [];
    event.stopPropagation();
    this.isPopupVisible = false;
    document.body.classList.remove('no-scroll'); // Remove class to enable scrolling
  },
  
    onClickOutside(event) {
      if (
        event.target.id !== "example-search-input4" &&
        !event.target.classList.contains("search-results") &&
        event.target.id !== "inputCity" &&
        !event.target.classList.contains("search-results-city")
      ) {
        this.searchResults = [];
        this.productSearchResults = [];
      }
    },
    setSearchParamsFromRoute() {
      if (this.$route.params.market_name) {
        this.location = this.$route.params.market_name.replace(/-/g, " ");
        this.defaultLocationEn = this.location;
      } else {
        this.defaultLocationEn = '',
        this.location = ''
      }
      if (this.$route.params.product && (this.$route.name == "ListComponent")  ) {
        this.product = this.$route.params.product.replace(/-/g,' ');
        this.defaultProductEn = this.product
      }
      else{
        this.defaultProductEn = "",
          this.product = "";
      }
      console.log(this.$route.name,'route params:',this.$route.params)
    },
  
    clearLocation() {
      this.location = "";
      this.defaultLocationEn = "";
      this.searchResults = [];
    },
    onDocumentClick(event) {
      if (
        this.$refs.locationInputContainer &&
        !this.$refs.locationInputContainer.contains(event.target)
      ) {
        this.searchResults = [];
      }
      if (
        this.$refs.productInputContainer &&
        !this.$refs.productInputContainer.contains(event.target)
      ) {
        this.productSearchResults = [];
      }
    },
 async updateSearchResults() {
   this.activeSearchType = "location"; // Set active search type to location
    this.productSearchResults = [];
      this.filterNumbers();
      try {
        // console.log('updateSearchResults',this.product)
        this.defaultLocationEn = this.location;
        const response = await fetch(
          `https://vaiyal-app.herokuapp.com/get/location?search=${this.location}`
        );
        const data = await response.json();
        if (data.status === "success") {
          this.searchResults = data.data.filter(
            item => item.market_name !== undefined
          );
        } else {
          console.error("Error fetching search results:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }

,
     filterNumbers() {
      this.location = this.location.replace(/[0-9]/g, '');
    },
async updateProductSearchResults() {
  this.activeSearchType = "product"; // Set active search type to product
    this.searchResults = []; 
      this.filterOutNumbers();
      try {
        this.productSearchResults = []
        // console.log('updateProductSearchResults')
        this.defaultProductEn = this.product;
        const response = await fetch(
         `https://vaiyal-app.herokuapp.com/get/lists?search=${this.product}`
        );
        const data = await response.json();
        if (data.status === "success") {
          this.productSearchResults = data.data;
        } else {
          console.error("Error fetching product search results:", data.msg);
        }
      } catch (error) {
        console.error("Error fetching product search results:", error);
      }
    },
    filterOutNumbers() {
      this.product = this.product.replace(/[0-9]/g, '');
    },
    onSearchResultsClick() {
      this.isNameSelected = false;
      this.isLocSelected = false;
    },
 selectSearchResult(item, field) {
  if (field === "location") {
    this.location = this.selectedLanguage === "en" ? item.market_name : item.tn_name;
    this.defaultLocationEn = item.market_name;
    this.defaultLocationTa = item.tn_name;
    this.marketId = item.market_id;
    this.isLocSelected = true;
    this.searchResults = [];
    this.$emit("updateMarketId", this.marketId); 
    console.log("this.defaultLocationEn",this.defaultLocationEn);
  } else if (field === "product") {
    this.product = this.selectedLanguage === "en" 
      ? item.sub_category_name ?? item.category_name 
      : item.sub_category_tamil_name ?? item.category_tamil_name;
    this.defaultProductEn = item.sub_category_name ?? item.category_name;
    this.defaultProductTa = item.sub_category_tamil_name ?? item.category_tamil_name;
    this.isNameSelected = true;
    this.$route.params.category = item.category_type.replace(/\s+/g, '-').toLowerCase().replace('farm', 'agriculture').replace('live-stocks', 'livestocks');
    this.$route.params.product = item.sub_category_name?.replace(/\s+/g, '-')?.toLowerCase() ?? item.category_name.replace(/\s+/g, '-').toLowerCase();
    this.$route.params.categoryName = item.sub_category_name ? item.category_name?.replace(/\s+/g, '-')?.toLowerCase() : null;
  }
}
,
updateMarketId() {
  const userStore = useUserStore()
  userStore.updateMarketId(this.marketId);
},
    submitSearch() {
      
   if (
        (
          this.defaultProductEn.trim() === "" &&
        this.defaultLocationEn.trim() !== "" ) 
      ) {
        alert(
          "Please select the correct location or product before searching."
        );
        return;
      }
  this.updateMarketId();
  this.$route.params.id = null;
  this.$route.params.category = 'agriculture-products';
  console.log('this.defaultLocationEn:', this.defaultLocationEn);

  let params = {
    ...this.$route.params,
        location: (this.defaultLocationEn && this.defaultLocationEn.trim()) ? this.defaultLocationEn : "tamilnadu",
    product: (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-') : ''
  };

  params.market_name = params.location;
  params.product ? params.product : (this.defaultProductEn && this.defaultProductEn.trim()) ? this.defaultProductEn.replace(/\s+/g, '-') : '';

  console.log('Params in submitSearch:', params);

  const name = params.product || this.$route.name === 'DetailComponent' || this.$route.name === 'equipmentdetail' || this.$route.name === 'livestockdetails' ? 'ListComponent' : this.$route.params.name;

  console.log('Navigating to:', name, 'with params:', params);

  this.$router.push({
    name: name,
    params: params,
  }).catch(err => {
    console.error('Routing error:', err);
  });
    document.body.classList.remove('no-scroll'); 
}


  },
  watch: {
    
selectedLanguage(newLang) {
    if (newLang === "en") {
      this.location = this.defaultLocationEn;
      this.product = this.defaultProductEn;
    } else if (newLang === "ta") {
      this.location = this.defaultLocationTa;
      this.product = this.defaultProductTa;
    }
  },
  },
  mounted() {
    document.addEventListener("click", this.onDocumentClick);
    document.addEventListener("click", this.onClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
    document.removeEventListener("click", this.onClickOutside);
  }
};
</script>

<style>
@import url("/src/components/Banner.scss");
</style>
