<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1 :class="{ 'tamil-title-h2': selectedLanguage === 'ta', 'title-h2': selectedLanguage !== 'ta' }" v-if="this.$route.params.categoryName">
                  {{ this.$route.params.categoryName.replace(/-/g, " ") }}
                </h1>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;{{ translations.home_lite }}</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">
                   <router-link
  :to="
    this.$route.params.category === 'agriculture-products'
      ? `/${this.$route.params.location}/agriculture-products`
      : this.$route.params.category === 'agriculture-equipments'
      ? `/${this.$route.params.location}/agriculture-equipments`
      : `/${this.$route.params.location}/livestocks`
  "
>
  {{ this.$route.params.category === 'agriculture-products' ? translations.farmProducts : this.$route.params.category === 'agriculture-equipments' ? translations.farmEquipments : this.$route.params.category === 'livestocks' ? translations.Livestocks : this.$route.params.category }}
</router-link>

                  </p>
                  <!-- <img src="../assets/images/next-page.svg" alt="" class="next-page">
                                <p class="footer-add">&nbsp;&nbsp;Dairy Product </p> -->
                </div>
                <!-- <img src="../assets/images/category-page-home-icon.svg" alt=""><span>Home</span> &nbsp; <img src="../assets/images/next-page.svg" alt=""> &nbsp;<span>Product Category </span> -->
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div class="col-lg-12 mt-3">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-12 order-2 order-lg-1">
            <SidebarComponent :selectedLanguage="selectedLanguage"/>
            <NewproductComponent :selectedLanguage="selectedLanguage"/>
          </div>
          <div class="col-lg-9 col-md-9 col-sm-12 order-1 order-lg-2">
            <div v-if="isLoading">
              <div class="loader">
                <div class="row">
                  <div
                    v-for="data in 4"
                    :key="data.id"
                    class="col-6 col-sm-6 col-md-4 col-lg-3"
                  >
                    <p class="shimmer-line-img"></p>
                    <p class="shimmer-line"></p>
                    <p class="shimmer-line"></p>
                  </div>
                </div>
              </div>
            </div>
             <div v-else-if="responseData.data.length === 0">
                  <div class="nodata-img mt-5">
          <img
            class=""
            src="../assets/images/emptypro.webp"
            loading="lazy"
            alt="empty"
          />
        </div>
        <h2 class="my-3 no-data">
          <!-- {{ translations.no_data_market }}  -->
         Subcategory is Not Available for here!!
        </h2>

        <p class="no-data-para">
          <!-- {{ translations.no_data_found }} -->
          We're sorry, Please check back later. You can browse our website to find
          alternative Category.
        </p>
            </div>
            <div class="subcategorys" v-else>
              <div class="row mt-4">
                <div
                  class="col-6 col-sm-6 col-md-4 col-lg-3"
                  v-for="subCategory in responseData.data"
                  :key="subCategory.sub_category_id"
                >
                  <div class="subcategory-grid" @click="navigateToAllList(subCategory.sub_category_name)">
                    <div class="sub-cate">
                      <img
                        :src="
                          subCategory.images[0] ||
                          require('../assets/images/No-Data-list.webp')
                        "
                        alt="Subcategory Image"
                        class="sub-img"
                      />
                    </div>
                   <h3>{{ selectedLanguage === "en" ? subCategory.sub_category_name : subCategory.tn_sub_name }}</h3>
                    <p>{{ subCategory.list }} {{ translations.totalItems }}</p>
                  </div>
                </div>
              </div>
                   <div class="col-lg-12 mt-3" ref="LongDes">
              <div v-html="more" :id="$style.more" class="mb-5"   v-if="more.length > 0"></div>
            </div>
            </div>
       
          </div>
          
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { en, ta } from "../translations";
import { useItemStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";
import NewproductComponent from "./NewproductComponent.vue";
import SidebarComponent from "./SidebarComponent.vue";
import metaDataJson from "../meta.json"; // Adjust the path based on where your JSON file is located

export default {
  name: "SubcategoryPage",
    props: {
    selectedLanguage: String
  },
  data() {
    return {
      isLoading: false,
      responseData: null,
      isSidebarOpen: false,
      filterCategories: [],
       isExpanded: false,
    };
  },
  created() {
    this.scrollToTop();
       this.fetchData();
    console.log("dhdd", this.$route.params);
    this.getItemId();

    this.updateMetaTags(); // Initialize meta tags on component creation
  },
  computed: {
        translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    // Map state from Pinia store to computed properties
    ...mapState(useItemStore, ["categoryId", "subCategoryId"]),


  },

  watch: {
    categoryId(newVal) {
      this.categoryId = newVal;
      console.log("this.categoryId:", this.categoryId);
    },
    "$route.params": function () {
      this.getItemId();
      this.updateMetaTags(); // Update meta tags whenever route params change
    },
  },
  components: {
    SidebarComponent,
    NewproductComponent,
  },

  methods: {
    async getItemId() {
      const itemStore = useItemStore();
      await itemStore.updateItemId(
        this.$route.params.categoryName?.replace(/-/g, " "),
        "category_name"
      );
      this.fetchData();
    },
    ...mapActions(useItemStore, ["updateItemId"]),

    async fetchData() {
       this.scrollToTop();
      try {
        this.isLoading = true;
        let apiUrl = "https://vaiyal-app.herokuapp.com/categoryweb";

        if (this.$route.params.location !== "tamilnadu") {
          apiUrl += `?marketname=${this.$route.params.location}`;
        }

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            categoryid: this.categoryId,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        this.responseData = data || []; // Assuming 'categories' is the key with the list
        console.log("Fetched categories:", this.responseData);
        this.more = this.responseData.cat_more;
        console.log("this.mores:", this.more);
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isLoading = false;
      }
    },
       navigateToAllList(subcategoryName) {
      const location = this.$route.params.location; // Assuming location is available in your current route params
      this.$router.push({
        name: "ListComponent",
        params: {
          category: this.$route.params.category,
          categoryName: this.$route.params.categoryName,
          product: subcategoryName.replace(/\s+/g, "-").toLowerCase(),
          location: location
        }
      });
    },
     async updateMetaTags() {
  const categoryName = this.$route.params.categoryName;
 // Static page name, as per your structure
  const city = this.$route.params.location || "Tamilnadu";
  const created = this.$route.params.created;

  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const capitalizedCity = capitalizeFirstLetter(city || '');

  try {
    // Find the metadata for the given category
    const metaData = metaDataJson.subcategorypage.find(
      (item) => item.category_name === categoryName
    );

    if (metaData) {
      // Update the title
      const title = metaData.title.replace(/{district}/g, capitalizedCity);
      console.log("🚀 ~ updateMetaTags ~ title:", title);
      document.title = title;

      // Update the description
      const description = metaData.description
        .replace(/{district}/g, capitalizedCity)
        .replace("{date}", created); // In case you need to replace the created date
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
       const metaOgTitle = document.querySelector('meta[property="og:title"]');
              if (metaOgTitle) {
                metaOgTitle.setAttribute("content", title);
              }
const metaOgDescription = document.querySelector('meta[property="og:description"]');
              if (metaOgDescription) {
                metaOgDescription.setAttribute("content", description);
              }
      // Update additional meta tags (e.g., title for SEO purposes)
      const head = metaData.title
        .replace("{city}", capitalizedCity ? `${capitalizedCity},` : "")
        .replace("{district}", capitalizedCity);
      const metaHead = document.querySelector('meta[name="title"]');
      if (metaHead) {
        metaHead.setAttribute("content", head);
      }
    } else {
      // this.$router.replace({path:"/error"})
      console.warn(`No metadata found for category_name: ${categoryName}`);
    }
  } catch (error) {
    console.warn("Error fetching meta data:", error);
  }
},
     scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
</style>
<style module>
#more {
  border: 1px solid #ececec !important;
  padding: 3%;
  border-radius: 10px;
  text-align: left;
  height: auto;
  overflow: auto;
  transition: height 0.3s ease;
}

#more.expanded {
  height: auto;
  overflow: visible;
}
#more > h1 {
  font-size: 22px;
  font-family: var(--font-family-quicksand);
  font-weight: 700;
  letter-spacing: -1px;
}
#more > h2 {
  font-size: 22px;
  font-family: var(--font-family-quicksand);
  font-weight: 800;
}
#more > h3 {
  font-size: 18px;
  font-family: var(--font-family-quicksand);
  font-weight: 800;
}

#more > span {
  font-family: var(--font-family-quicksand);
}
#more > ul > p > span {
  font-family: var(--font-family-quicksand);
}

#more > ul > li > h4 {
  font-size: 16px;
  font-family: var(--font-family-quicksand);
  font-weight: 800;
}
#more > ol > li > h4 {
  font-size: 16px;
  font-family: var(--font-family-quicksand);
  font-weight: 800;
}

#more > ul > li > h4 a {
  color: black;
}
#more > ol > li > h4 a {
  color: black;
}

#more > p {
  font-size: 13px;
  font-family: var(--font-family-quicksand);
  text-align: justify !important;
  font-weight: 600;
  color: var(--black);
}
#more > ol > li {
  font-size: 14px;
  font-family: var(--font-family-quicksand);
  padding-bottom: 2%;
}
#more > ol > li > span {
  font-size: 14px;
  font-family: var(--font-family-quicksand);
  font-weight: 600;
}
#more > ul > li {
  font-size: 14px;
  padding-bottom: 2%;
  font-family: var(--font-family-quicksand);
}
#more > ul > li > span {
  font-size: 14px;
  font-family: var(--font-family-quicksand);
  font-weight: 600;
}
#more > ul > li:nth-child(5) {
  padding-bottom: 0%;
}




</style>
