<template>
  <section class="gooogle-ads-section" ref="adsSection">
    <div class="wrapper">
      <div class="col-12 advertisement">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-none">
            <!-- google ads display -->
            <div class="left-ads">
              <!-- <img src="../assets/images/dariling.png" alt=""> -->
              
     <Adsense
    data-ad-client="ca-pub-1763856178878021"
    data-ad-slot="6910003215">
</Adsense>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12">
            <!-- google ads display -->
            <div class="right-ads">
              <!-- <img src="../assets/images/darlingg.jpg" alt=""> -->
               <Adsense
    data-ad-client="ca-pub-1763856178878021"
    data-ad-slot="6428763273">
</Adsense>
            </div>
          </div>
        </div>
      </div>
    </div>
   
      <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="blank">
        <p v-if="isHomePage" class="fixed-ad-button" :style="buttonStyle"> 
        Download App
      </p>
      </a>
 
  </section>
</template>

<script>
export default {
  name: "AdsComponent",
  data() {
    return {
      isHomePage: false, // Default to false to prevent unwanted display on non-home pages
      isSticky: false, // Controls whether the button is sticky or not
      buttonOffset: 0, // Stores the initial offset of the button
    };
  },
  mounted() {
    this.checkHomePage(); // Ensure correct visibility on first load
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    buttonStyle() {
      return {
        position: this.isSticky ? "fixed" : "absolute",
        top: this.isSticky ? "47%" : "215px",
        right: "-73px",
        rotate: "270deg",
      };
    },
  },
  watch: {
    "$route": {
      handler: function () {
        this.checkHomePage();
      },
      immediate: true, // Ensures it runs on first load
    },
  },
  methods: {
    checkHomePage() {
      this.isHomePage = this.$route.name === "HomePage"; 
    },
    handleScroll() {
      const adsSection = this.$refs.adsSection;
      if (adsSection) {
        const sectionRect = adsSection.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        // Make the button sticky when the section reaches 30% of the viewport height
        this.isSticky = sectionRect.top <= viewportHeight * 0.1;
      }
    },
  },
};
</script>


<style>
 @import url("/src/components/Ads.scss");
</style>