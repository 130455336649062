<template>
  <section class="contact-us-section category-page-section">
    <div class="wrapper">
        <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1 :class="{ 'tamil-title-h2': selectedLanguage === 'ta', 'title-h2': selectedLanguage !== 'ta' }" >Contact Us</h1>
                <div class="address-section">
                  <img src="../assets/images/category-page-home-icon.svg" alt="" />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                   <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="">Contact us</a></p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="contactus-heading-div">
              <h2 class="contactus-main-heading">{{translations.get_in}}</h2>
              <p class="contactus-sub-heading">
                {{ translations.contactDescription }}
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="contact-us-main-div">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="left-side-contactus">
                  <form @submit.prevent="sendEmail">
                    <input
                      required
                      type="text"
                      :placeholder="translations.namePlaceholder"
                      v-model="name"
                    />
                    <input
                      type="text"
                      :placeholder="translations.lastName"
                    />
                    <input
                      required
                      type="email"
                      :placeholder="translations.emailPlaceholder"
                      v-model="email"
                    />

                   

                    <textarea
                      rows="8"
                      cols="50"
                     :placeholder="translations.messagePlaceholder"
                      required
                     v-model="message"
                    ></textarea>
                    <div >
                      <input type="submit" :value="translations.sendButton" class="contactus-button"/>
                    </div>
<div v-if="showSuccessMessage" class="success-message">
        {{ translations.successMessage }}
      </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="right-side-contactus">
                  <h3 class="label-name">{{ translations.address }}</h3>
                  <div class="address-div-sec">
                    <div class="location-icon-div">
                      <img
                        src="../assets/images/footer-component/icon.svg"
                        alt=""
                      />
                    </div>
                    <p class="contact-info">
                      M/S. Vayal Agro Tech (Opc) Private Limited
                      <span>28 Ncn Garden,</span>
                      <span>Thirunagar (Opposite to Muthu Mahal ),</span>
                      <span>Gobichettipalayam,</span>
                      <span>Erode, Tamil Nadu.</span>
                    </p>
                  </div>
                  <h3 class="label-name">{{ translations.emails }}</h3>
                  <div class="address-div-sec">
                    <div class="mail-icon-div">
                      <img
                        src="../assets/images/footer-component/Mail-icon.svg"
                        alt=""
                      />
                    </div>
                    <p class="contact-info">
                      <span><a href="mailto:support@vayalagro.com">support@vayalagro.com</a></span>
                    </p>
                  </div>
                  <div class="contactus-img-div">
                    <img
                      src="../assets/images/contactus-component/contactus-background.webp"
                      class="contactus-img"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
   
            <div class="map-div">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d988.3330994120984!2d77.41775309143928!3d11.458840452428094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTHCsDI3JzMxLjgiTiA3N8KwMjUnMDQuNiJF!5e0!3m2!1sen!2sin!4v1691988195977!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
  </section>
</template>

<script>
import { en, ta } from "../translations.js";

import emailjs from 'emailjs-com';

const EMAILJS_USER_ID = '8BXWKT4hWl3AcLvXv';
export default {
  props: ["selectedLanguage"],
  name: "ContactUsPage",
  data() {
    return {
    name: '',
      email: '',
      message: '',
      showSuccessMessage: false,
    };
  },
   computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  methods: {
   sendEmail() {
      const templateParams = {
        from_name: this.name,
        from_email: this.email,
        to_name: 'Team Vayal', // Replace with the recipient's name
        message: this.message,
      };

      emailjs
        .send(
          'service_cs61s16', // Replace with your EmailJS service ID
          'template_wlbwphz', // Replace with your EmailJS template ID
          templateParams,
          EMAILJS_USER_ID // Use the User ID directly here
        )
        .then((response) => {
           console.log('Email sent successfully!', response);
          this.showSuccessMessage = true; // Display the success message
          this.resetForm(); // Clear the form fields
          setTimeout(() => {
            this.showSuccessMessage = false; // Hide the success message after some time (optional)
          }, 3000); // Adjust the time (in milliseconds) as needed
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          // Handle error: display an error message or perform any other actions
        });
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.message = '';
    },
},
beforeRouteLeave(to, from, next) {
 if (to.name === "Home") {
      window.location.reload();
  } else {
    next();
  }
}
};
</script>



<style>
@import url("/src/components/ContactUsPage.scss");
@import url("/src/components/CategoryPage.scss");
</style>