<template>
  <section class="home-categorys">
      <div class="wrapper home-wrap">
         <div class="col-12">
             <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 agri-border">
                   <div class="agri-product" @click="getModifiedCategoryLink('agriculture products', defaultLocationEn)">
                      <div class="agri-product-img">
                         <img src="../assets/images/category-component/product-category-home.svg" alt="">              
                      </div>
                      <h3 :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'Product-heading': selectedLanguage !== 'ta' }">{{translations.products}}</h3>
                      <p :class="{ 'Product-description_tamil': selectedLanguage === 'ta', 'Product-description': selectedLanguage !== 'ta' }">{{ translations.Cate_pro_des }}</p>
                      <p :class="{ 'view-more-com_tamil': selectedLanguage === 'ta', 'view-more-com': selectedLanguage !== 'ta' }">{{ translations.viewmore }} <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                   </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 agri-border">
                    <div class="agri-product" @click="getModifiedCategoryLink('agriculture equipments', defaultLocationEn)">
                      <div class="agri-product-img">
                        <img src="../assets/images/category-component/jcb.svg" alt=""> 
                      </div>
                      <h3 :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'Product-heading': selectedLanguage !== 'ta' }">{{translations.equipments}}</h3>
                      <p :class="{ 'Product-description_tamil': selectedLanguage === 'ta', 'Product-description': selectedLanguage !== 'ta' }">{{ translations.Equp_pro_des }}</p>
                      <p :class="{ 'view-more-com_tamil': selectedLanguage === 'ta', 'view-more-com': selectedLanguage !== 'ta' }">{{ translations.viewmore }} <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                   </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 agri-border">
                    <div class="agri-product"  @click="getModifiedCategoryLink('livestocks', defaultLocationEn)">
                      <div class="agri-product-img">
                        <img src="../assets/images/category-component/cow.svg" alt=""> 
                      </div>
                      <h3 :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'Product-heading': selectedLanguage !== 'ta' }">{{ translations.Livestocks }}</h3>
                      <p :class="{ 'Product-description_tamil': selectedLanguage === 'ta', 'Product-description': selectedLanguage !== 'ta' }">{{ translations.Live_pro_des }}</p>
                      <p :class="{ 'view-more-com_tamil': selectedLanguage === 'ta', 'view-more-com': selectedLanguage !== 'ta' }">{{ translations.viewmore }} <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                   </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                    <div class="agri-product">
                     <a href="/market-price">
                      <div class="agri-product-img">
                        <img src="../assets/images/category-component/market.svg" alt=""> 
                      </div>
                      <h3 :class="{ 'tamil-fonts': selectedLanguage === 'ta', 'Product-heading': selectedLanguage !== 'ta' }">{{ translations.marketPrice }}</h3>
                      <p  :class="{ 'Product-description_tamil': selectedLanguage === 'ta', 'Product-description': selectedLanguage !== 'ta' }">{{ translations.Mp_pro_des }}</p>
                      <p :class="{ 'view-more-com_tamil': selectedLanguage === 'ta', 'view-more-com': selectedLanguage !== 'ta' }">{{ translations.viewmore }} <span><img src="../assets/images/Vector (2).png" alt=""></span> </p>
                      </a>
                   </div>
                </div>
             </div>
         </div>
      </div>
        <RibbenSection v-if="!isAboutPage"/>

  </section>
  
</template>

<script>
import RibbenSection from './RibbenComponent.vue';
import { en, ta } from "../translations.js";

export default {
   
components: {
   RibbenSection
  },
   props: {
    selectedLanguage: String
  },
   data() {
    return {
      isPopupVisible: false,
      location: "",
      product: "",
      searchResults: [],
      productSearchResults: [],
      isNameSelected: false,
      isLocSelected: false,
      defaultLocationEn: "",
      defaultLocationTa: "",
      defaultProductEn: "",
      defaultProductTa: "",
       isAboutPage: true,
    };
  },
  
  created() {
    if (this.$route.params.market_name) {
      this.location = this.$route.params.market_name;
    }
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
    }
    this.isNameSelected = false;
    this.isLocSelected = false;
    
     this.checkAboutPage();
  },
 computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
  methods: {
         checkAboutPage() {
      this.isAboutPage = this.$route.name === 'AboutUsPage'
    },
  getModifiedCategoryLink(category, locationData) {
  // Validate location selection
  const location = locationData || "tamilnadu";

  // Navigate to CategoryPage
  this.$router.push({
    name: "CategoryPage",
    params: {
      category: category.replace(/\s+/g, "-").toLowerCase(),
      location: location.toLowerCase()
    }
  }).then(() => {
    // Scroll to the top after navigation
    window.scrollTo(0, 0);
  });
}

},
 mounted() {
    this.checkAboutPage(); // Check on initial load
    this.$watch('$route', this.checkAboutPage); 
  },
 watch:{
  '$route': function() {
      this.checkAboutPage();
      
    },

     
  },
   
}
</script>

<style>
@import url("/src/components/CategoryComponent.scss");

</style>