import Vue from 'vue';
import App from './App.vue';
import router from './router/index.js'; // Correct router import
import { createPinia, PiniaVuePlugin } from 'pinia';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import Ads from 'vue-google-adsense'

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
const pinia = createPinia();
Vue.use(VueMaterial); // Vue Material plugin

new Vue({
  router, // Pass the router to Vue instance
  pinia,
  render: (h) => h(App), // Render the App component
}).$mount('#app');
