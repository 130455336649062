<template>
    <section>
        <section class="footer-section">
            <div class="wrapper">
                <div class="row">
                    <div class="col-lg-4 col-md-4 first-sec">
                      <a href="/">
                        <div class="foot-logo">
                            <img src="../assets/images/vayal-new-logo.png" alt="Vayal logo" class="logo" loading="lazy">
                            <h3 class="brand-name">VAYAL<span>AGRO</span>&#8482;</h3>
                        </div>
                    </a>
                        <p class="foot-desc">{{translations.footerDecs}}</p>
                        <div class="social-icons">
                            <a href="https://wa.me/9888685678?text=Hello%20there!" target="_blank"><img src="../assets/images/footer-component/footer-whatsapp-icon.png" alt="whatsapp"></a>
                            <a href="https://www.instagram.com/_vayalagro/" target="_blank"><img src="../assets/images/footer-component/footer-insta-icon.png" alt="instagram"></a>
                        </div>
                        <p class="download">Download App</p>
                        <div class="foot-logo d-flex align-items-center">
                            <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank">
                            <img src="../assets/images/googleplay.svg" alt="get play" class="play-logo">
                            </a>
                        </div>
                        <p class="copy">Copyright &copy; 2025 Vayal. All Rights Reserved.</p>

                    </div>
                    <!-- <div class="col-lg-1"></div> -->
                    <div class="col-lg-4 col-md-4">
                        <ul class="quick-links">
                            <li><a href="/">{{ translations.home_lite }}</a></li>
                            <li>{{ translations.blogs }}</li>
                            <li><a href="/market-price">{{ translations.marketPrice }}</a></li>
                            <li><a href="/about">{{ translations.about }}</a></li>
                            <li><a href="/contact">{{ translations.contact }}</a></li>
                            <li><a href="/privacy">{{ translations.privacy }}</a></li>
                            <li><a href="/termsandconditions">{{ translations.termsOfUse }}</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <ul class="contact-info">
                            <div class="address-section"> 
                                <img src="../assets/images/footer-component/icon.svg" alt="">
                                <p class="footer-address">{{translations.address1}}</p>
                            </div>
                            <div class="address-section"> 
                                <img src="../assets/images/footer-component/Mail-icon.svg" alt="">
                                <li><a href="mailto:support@vayalagro.com">support@vayalagro.com</a></li>
                            </div>
                            
                           
                                <p class="mobile-copy">Copyright &copy; 2025 Vayal. All Rights Reserved.</p>
                       

                            <div class="foot-bot-img">
                                <img src="../assets/images/footer-component/Group.webp" loading="lazy">
                            </div>
                        </ul>
                        
                    </div>
                </div>
                
            </div>
        </section>
    </section>
</template>

<script>
import { en, ta } from "../translations.js";
export default {
    name: 'FooterComponent',
     props: {
    selectedLanguage: String
  },
    data() {
    return {
   
    };
  },
    computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
    mounted() {
        document.getElementById('year').textContent = new Date().getFullYear();
    },
};
</script>

<style>
@import url("/src/components/FooterComponent.scss");
</style>