<template>
    <div class="maintenance">
      <img v-if="this.img && this.img !== ''" :src="img" loading="lazy" alt="Icon1" class="pro-icon">
      <img v-else src="../assets/images/vayal-new-logo.png" loading="lazy" alt="Icon" class="logo-icon">
      <h1>We&rsquo;ll be back soon!</h1>
      <p>{{ maintenanceMsg }}</p>
    </div>
</template>

<script>

export default {
  name: "MaintenancePage",
    data() {
      return {
        showMaintenance: false,
        maintenanceMsg: '',
        img: '',
      };
    },
    methods: {
      closePopup() {
        this.showMaintenance = false;
      },
      fetchPopup() {
        // Fetch data from the API
        fetch('https://vaiyal-app.herokuapp.com/maintenance?type=maintenance')
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success' && data.data.isactive) {
                this.img = data.data.img;
                this.maintenanceMsg = data.data.msg;
                this.showMaintenance = true;
              
            } else {
              console.error('Failed to fetch data:', data.msg);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
    },
    mounted() {
      this.fetchPopup();
    },
  };
</script>

<style scoped>



.maintenance {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  font-family: 'Quicksand-Regular', sans-serif;
  background-color: #ffffc8;
  height: 100vh;
}
.logo-icon {
  margin-bottom: 3%;
  width: 100px;
  height: 100px;
}

.pro-icon {
  margin-bottom: 3%;
  width: 500px;
  height: 300px;
  object-fit: cover;
}
  
h1 {
  font-size: 70px;
  font-weight: 800;
}
p{
  font-size: 20px;
  font-weight: 400;
}

@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 768px) {
}
@media only screen and (max-device-width: 480px) {

    .maintenance {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5%;
  font-family: 'Quicksand-Regular', sans-serif;
  background-color: #ffffc8;
  height: 100% !important;
}
.pro-icon {
  margin-bottom: 3%;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
    h1 {
  font-size: 20px;
}
p{
    font-size: 16px;
    width: 100%;
}
}
</style>
