<template>
  <section class="errorpage-section">
    <div class="wrapper">
      <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="errorpage-main-div">
            <div class="header-logo">
              <a href="/" class="d-flex align-items-center">
                <img src="../assets/images/vayal-new-logo.png" alt="" />
                <p>VAYAL<span>AGRO</span>&#8482;</p>
              </a>
            </div>            
            <div class="error-div">
              <h1 class="left-four">4</h1>
              <div class="egg-div">
                <img src="../assets/images/error-component/error-egg-img.webp" alt="">
              </div>
                <h1 class="right-four">4</h1>
            </div>
            <div class="opps-div">
                <h3 class="oops">OOPS! SOMETHING'S MISSING</h3>
                <p class="error-content">
                    The page you are looking for doesn't exist. It may have been moved or removed altogether. <span>Please try searching for some other page, or return to the website's homepage to find</span>
                    what you're looking for. 
                </p>
                <a href="/"><button class="homepage-btn">Back To Homepage</button></a>
            </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
 import { en, ta } from "@/translations";
  
  export default {
     props: {
      selectedLanguage: String,
    },
      computed: {
      translations() {
        return this.selectedLanguage === "en" ? en : ta;
      },
      },
    name: "ErrorPage",
    metaInfo() {
      return {
        title: this.$route.meta.title,
        meta: this.$route.meta.metaTags
      };
    }
    
  };
</script>

<style>
@import url("/src/components/ErrorPage.scss");
</style>