import Vue from 'vue';
import Router from 'vue-router';
import AboutUsPage from '../components/AboutUsPage.vue'; // Your AboutPage component
import MarketPrice from '../components/MarketPrice.vue'
import HistoryPrice from '../components/HistoryPrice.vue'
import HomePage from '../pages/HomePage.vue';
import CategoryPage from '../components/CategoryPage.vue';
import SubcategoryPage from '../components/SubcategoryPage.vue';
import ListComponent from '../components/ListComponent.vue';
import DetailComponent from '../components/DetailComponent.vue';
import EquipDetail from '../components/EquipDetail.vue';
import ContactUsPage from '../components/ContactUsPage.vue';
import PrivacyPolicy from  '../components/PrivacyPolicy.vue';
import ErrorPage from '../components/ErrorPage.vue';
import TermsandCondition from '../components/TermsandCondition.vue';
import metaDataJson from "../meta.json"; 
import axios from "axios";

Vue.use(Router); // Use Router plugin
const updateMetaTags = (title, description) => {
  // Update the document title
  // document.title = title;
  const metaTitle = document.querySelector('meta[name="title"]');
  if (metaTitle) {
      metaTitle.setAttribute("content", title);
  } else {
    const metaTag = document.createElement("meta");
    metaTag.setAttribute("name", "title");
    metaTag.setAttribute("content", title);
    document.head.appendChild(metaTag);
  }
  // Update the meta description tag
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  } else {
    const metaTag = document.createElement("meta");
    metaTag.setAttribute("name", "description");
    metaTag.setAttribute("content", description);
    document.head.appendChild(metaTag);
  }

  const metaOgTitle = document.querySelector('meta[property="og:title"]');
            if (metaOgTitle) {
              metaOgTitle.setAttribute("content", title);
            }
  
            const metaOgDescription = document.querySelector('meta[property="og:description"]');
            if (metaOgDescription) {
              metaOgDescription.setAttribute("content", description);
            }
  // Push updated meta data to GTM dataLayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "metaUpdate",
    pageTitle: title,
    pageDescription: description,
  });
};
const routes = [
  { 
    path: '/', 
    name: 'HomePage', 
    component: HomePage,
    meta: {
      title: "VayalAgro", // Default title
    },
    beforeEnter: (to, from, next) => {
      const homeMeta = metaDataJson.homepage.find((item) => item.pagename === "home");

      if (homeMeta) {
        updateMetaTags(homeMeta.title, homeMeta.description);
      }
      next();
    },
  },
  { 
    path: '/about', 
    name: 'AboutUsPage', 
    component: AboutUsPage,
    meta: {
      title: "VayalAgro | About Us",
  },
  beforeEnter: async (to, from, next) => {
      const aboutMeta = metaDataJson.aboutpage.find((item) => item.pagename === "AboutUsPage");

      if (aboutMeta) {
        updateMetaTags(aboutMeta.title, aboutMeta.description);
      }
      next();
  }, 
  },
  { 
    path: '/contact', 
    name: 'ContactUsPage', 
    component: ContactUsPage,
    meta: {
      title: "VayalAgro | Contact",
  },
  beforeEnter: async (to, from, next) => {
      const contactMeta = metaDataJson.contactpage.find((item) => item.pagename === "ContactUsPage");

      if (contactMeta) {
        updateMetaTags(contactMeta.title, contactMeta.description);
      }
      next();
  }, 
  },
  { 
    path: '/privacy', 
    name: 'PrivacyPolicy', 
    component: PrivacyPolicy,
    meta: {
      title: "VayalAgro | PrivacyPolicy", // Placeholder title
  }, 
  },
  { 
    path: '/termsandconditions', 
    name: 'TermsandConditions', 
    component: TermsandCondition 
  },
  { 
    path: '/market-price', 
    name: 'MarketPrice', 
    component: MarketPrice ,
    props: true,
    meta: {
      title: "VayalAgro | MarketPrice", // Placeholder title
  },
  beforeEnter: async (to, from, next) => {
      const marketMeta = metaDataJson.marketpage.find((item) => item.pagename === "MarketPrice");

      if (marketMeta) {
        updateMetaTags(marketMeta.title, marketMeta.description);
      }
      next();
  },
  },
  {
    path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?",
    name: "pricesublist",
    component: MarketPrice,
    props: true,
    meta: {
        title: "MarketPrice", // Placeholder title
    },
    beforeEnter: async (to, from, next) => {
        const categoryName = to.params.categoryName;
        const pageName = "pricesublist"; // Page name as per your requirement
        const city = to.params.city;
        const district = to.params.location;
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        // Capitalize city and district
        const capitalizedCity = capitalizeFirstLetter(city || "");
        const capitalizedDistrict = capitalizeFirstLetter(district || "");
        try {
            // Fetch meta data from the API
            const response = await axios.get(
                "https://vaiyal-app.herokuapp.com/meta",
                {
                    params: {
                        category_name: categoryName,
                        page_name: pageName,
                    },
                }
            );

            const metaData = response.data.data;

            if (metaData) {
                // Update the title
                const title = metaData.title
                    .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                    .replace("{district}", capitalizedDistrict);
                to.meta.title = title;
                document.title = to.meta.title; // Update document title

                // Update the description
                const description = metaData.description
                    .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                    .replace("{district}", capitalizedDistrict)
                to.meta.metaTags[0].content = description;

                // Update meta tags in the document head
                const metaDescription = document.querySelector(
                    'meta[name="description"]'
                );
                if (metaDescription) {
                    metaDescription.setAttribute("content", description);
                }
                const head = metaData.title
                    .replace("{city}", this.$route.params.city?capitalizedCity + "," : "")
                    .replace("{district}", capitalizedDistrict)
                to.meta.metaTags[0].content = head;
                const metaHead = document.querySelector(
                    'meta[name="title"]'
                );
                if (metaHead) {
                    metaHead.setAttribute("content", head);
                }
            }
        } catch (error) {
            console.warn("Error fetching meta data:", error);
        }

        next();
    },
},
{
    path: "/market-price/tamilnadu/:categoryName-market-price:location?/:city?/:created?",
    name: "pricesublist1",
    component: MarketPrice,
    props: true,
    meta: {
      title: "MarketPrice", // Placeholder title
     
  },
  beforeEnter: async (to, from, next) => {
    const categoryName = to.params.categoryName;
    const pageName = "pricesublist1"; // Page name as per your requirement
    const city = to.params.city;
    const district = to.params.location;
    const created = to.params.created;
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Capitalize city and district
    const capitalizedCity = capitalizeFirstLetter(city || "");
    const capitalizedDistrict = capitalizeFirstLetter(district || "");

    try {
        // Fetch meta data from the API
        const response = await axios.get(
            `https://vaiyal-app.herokuapp.com/meta`,
            {
                params: {
                    category_name: categoryName,
                    page_name: pageName,
                },
            }
        );

        const metaData = response.data.data;

        if (metaData) {
            // Update the title
            const title = metaData.title
                .replace("{city}", to.params.city ? capitalizedCity + ", " : "")
                .replace("{district}", capitalizedDistrict);
            to.meta.title = title;
            document.title = to.meta.title; // Update document title

            // Update the description
            const description = metaData.description
                .replace("{city}", to.params.city ? capitalizedCity + ", " : "")
                .replace("{district}", capitalizedDistrict)
                .replace("{date}", created);
            to.meta.metaTags[0].content = description;

            // Update meta tags in the document head
            const metaDescription = document.querySelector(
                'meta[name="description"]'
            );
            if (metaDescription) {
                metaDescription.setAttribute("content", description);
            }

            // Add or update the canonical link
            const canonicalLink = document.querySelector('link[rel="canonical"]');
            const canonicalHref = `https://vayalagro.com/market-price/${categoryName}`;
            if (canonicalLink) {
                canonicalLink.setAttribute("href", canonicalHref);
            } else {
                const linkElement = document.createElement("link");
                linkElement.setAttribute("rel", "canonical");
                linkElement.setAttribute("href", canonicalHref);
                document.head.appendChild(linkElement);
            }
        }
    } catch (error) {
        console.warn("Error fetching meta data:", error);
    }

    next();
}

},
{
  path: "/market-price/tamilnadu/:categoryName-history-price:location/:city/:name-history-price",
  name: "AllRecent",
  component: HistoryPrice,
  meta: {
    title: "VayalAgro | Recent Price",
  },
  beforeEnter: async (to, from, next) => {
    const categoryName = to.params.categoryName.replace(/-/g, ' ');
    const subName = to.params.name.replace(/-/g, ' ');
    const district = to.params.location ? to.params.location.replace(/-/g, ' ') : "Tamilnadu";
    const city = to.params.city ? to.params.city.replace(/-/g, ' ') : "Tamilnadu";

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const capitalizedCate = capitalizeFirstLetter(categoryName || "");
    const capitalizedSubCate = capitalizeFirstLetter(subName || "");
    const capitalizedCity = capitalizeFirstLetter(city || "");
    const capitalizedDistrict = capitalizeFirstLetter(district || "");

    try {
      const historyMeta = metaDataJson.historypage.find((item) => item.pagename === "AllRecent");

      if (historyMeta) {
        const title = historyMeta.title.replace("{city}", capitalizedCity).replace("{district}", capitalizedDistrict.trim()).replace("{Category}", capitalizedCate);
        const description = historyMeta.description.replace("{city}", capitalizedCity).replace("{district}", capitalizedDistrict.trim()).replace("{Category}", capitalizedCate).replace("{subcategory}", capitalizedSubCate);

        const metaTitle = document.querySelector('meta[name="title"]');
        if (metaTitle) {
          metaTitle.setAttribute("content", title);
        }
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute("content", description);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "metaUpdate",
          pageTitle: title,
          pageDescription: description,
        });
      }
    } catch (error) {
      console.error("Error fetching meta data:", error);
    }

    next();
  },
},

  { 
    path: '/historyprice', 
    name: 'HistoryPrice', 
    component: HistoryPrice ,
    props: true,
  },
  { 
    path: '/:location/:category',
    name: "CategoryPage",
    component: CategoryPage,
    props: true,
        meta: {
            title: "CategoryPage", 
          },
          beforeEnter: async (to, from, next) => {
            const categoryType = to.params.category;
            const district = to.params.location.trim() || "Tamilnadu"; 
            const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
            };

            // Capitalize city and district
           
            const capitalizedDistrict = capitalizeFirstLetter(district || "");
            try {
                const farmMeta = metaDataJson.categorypage.find(
                    (item) => item.category_type === categoryType
                );
        
                if (farmMeta) {
                    const title = farmMeta.title.replace("{district}", capitalizedDistrict);
                    const description = farmMeta.description.replace("{district}", capitalizedDistrict);
        
                    // Dynamically update meta tags
                    document.title = title;
                    const metaTitle = document.querySelector('meta[name="title"]');
                    if (metaTitle) {
                        metaTitle.setAttribute("content", title);
                    }
                    const metaDescription = document.querySelector('meta[name="description"]');
                    if (metaDescription) {
                        metaDescription.setAttribute("content", description);
                    }
                    const metaOgTitle = document.querySelector('meta[property="og:title"]');
                    if (metaOgTitle) {
                      metaOgTitle.setAttribute("content", title);
                    }
          
                    const metaOgDescription = document.querySelector('meta[property="og:description"]');
                    if (metaOgDescription) {
                      metaOgDescription.setAttribute("content", description);
                    }
                    // Push meta data to GTM dataLayer
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "metaUpdate",
                        pageTitle: title,
                        pageDescription: description,
                    });
                } else {
                    console.warn("No metadata found for category:", categoryType);
                }
            } catch (error) {
                console.error("Error updating meta data:", error);
            }
        
            next();
        },
    },
    { path: "/:location/:category/:categoryName",
        name: "SubcategoryPage",
        component: SubcategoryPage,
        props: true,
        meta: {
          title: "SubcategoryPage", // Placeholder title
        },
        beforeEnter: async (to, from, next) => {
          const categoryName = to.params.categoryName;
          const city = to.params.location || "Tamilnadu"; // Default location if none is provided
    
          try {
            // Fetch the JSON file (or this could be an API endpoint)
            // const response = await axios.get('/meta.json');
            const metaData = metaDataJson.data.subcategorypage.find(
              (item) => item.category_name === categoryName
            );
    
            if (metaData) {
              // Replace {district} with the actual location (city)
              const title = metaData.title.replace("{district}", city);
              const description = metaData.description.replace("{district}", city);
    
              // Update the document title
              document.title = title;
    

              // Update the meta description tag
              const metaDescription = document.querySelector('meta[name="description"]');
              if (metaDescription) {
                metaDescription.setAttribute("content", description);
              }

              const metaOgTitle = document.querySelector('meta[property="og:title"]');
              if (metaOgTitle) {
                metaOgTitle.setAttribute("content", title);
              }
    
              const metaOgDescription = document.querySelector('meta[property="og:description"]');
              if (metaOgDescription) {
                metaOgDescription.setAttribute("content", description);
              }
              // Push the updated meta data to the GTM dataLayer
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "metaUpdate",
                pageTitle: title,
                pageDescription: description,
              });
            }
          } catch (error) {
            console.error("Error fetching meta data:", error);
          }
    
          next();
        },
    },
    { 
      path: "/:location/:category/:categoryName?/:product-list/:city?",
      name: "ListComponent",
      component: ListComponent,
      props: true,
      meta: {
          title: "ListComponent", 
        }, 
      },
      {
        path: "/:location/:category/:categoryName?/:product-list/vap/:id",
        name: "DetailComponent",
        component: DetailComponent,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
      },
      {
        path: "/:location/:category/:categoryName?/:product-list/vae/:id",
        name: "equipmentdetail",
        component: EquipDetail,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
      {
        path: "/:location/:category/:categoryName?/:product-list/vls/:id",
        name: "livestockdetails",
        component: DetailComponent,
        beforeEnter: (to, from, next) => {
            const { id } = to.params;
            if (!/^\d+$/.test(id)) {
              console.warn("Invalid ID in URL, redirecting to error page.");
              next({ name: "Error" });
            } else {
              next(); // Allow navigation
            }
          },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: ErrorPage,
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
    meta: {
        title: "404 Error",
        metaTags: [{ name: "Error", content: "vayal Error." }],
    },
},
  // You can add other routes here
];
let preventScroll = true;
const router = new Router({
  routes, // Register the routes
  mode: 'history', 
    scrollBehavior(to, from, savedPosition) {
        if (preventScroll) {
            preventScroll = false; // Reset the flag
            return false; // Maintain the current scroll position
        }

        if (savedPosition) {
            
            return savedPosition;
        } else if (to.hash) {
            const element = document.getElementById(to.hash.slice(1));
            if (element) {
                return { el: element, behavior: "smooth" };
            }
        } else {
            return { top: 0, behavior: "smooth" };
        }
    },
});

export function disableScroll() {
    preventScroll = true;
}

function validateParams(params) {
    const validRegex = /^[a-z0-9-&/]+$/; // Allows only lowercase, numbers, hyphens, & and /
  
    for (const key in params) {
      const value = params[key];
      if (!validRegex.test(value)) {
        console.error(`Invalid parameter: ${key}=${value}`);
        return false;
      }
    }
    return true;
  }
  
  router.beforeEach((to, from, next) => {
    // Update document title if meta title is provided
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  
    if (to.name === "DetailComponent" && "EquipDetail") {
      const robotsMeta = document.querySelector('meta[name="robots"]');
      if (robotsMeta) {
        robotsMeta.setAttribute("content", "noindex, follow");
      } else {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", "robots");
        metaTag.setAttribute("content", "noindex, follow");
        document.head.appendChild(metaTag);
      }
    } else {
      // Remove the robots meta tag for other routes
      const robotsMeta = document.querySelector('meta[name="robots"]');
      if (robotsMeta) {
        robotsMeta.remove();
      }
    }
  
    // Validate route parameters
    if (!validateParams(to.params)) {
      console.warn("Redirecting due to invalid parameters.");
      next({ name: "Error" }); // Redirect to a safe route or 404 page
    } else {
      next();
    }
  
    // Normalize route parameters (e.g., convert to lowercase)
    const newParams = { ...to.params };
    if (
      to.params.market_name &&
      to.params.market_name !== to.params.market_name.toLowerCase()
    ) {
      newParams.market_name = to.params.market_name.toLowerCase();
    }
  
    if (
      to.params.location &&
      to.params.location !== to.params.location.toLowerCase()
    ) {
      newParams.location = to.params.location.toLowerCase();
    }
  
    if (
      to.params.product &&
      to.params.product !== to.params.product.toLowerCase()
    ) {
      newParams.product = to.params.product.toLowerCase();
    }
  
    // Redirect if parameters are modified
    if (JSON.stringify(newParams) !== JSON.stringify(to.params)) {
      next({
        name: to.name,
        params: newParams,
        query: to.query,
        hash: to.hash,
      });
    } else {
      next();
    }
  });



export default router;
