<template>
   <section class="aboutus-section category-page-section">
      <div class="wrapper">
         <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1 :class="{ 'tamil-title-h2': selectedLanguage === 'ta', 'title-h2': selectedLanguage !== 'ta' }" >About the VayalAgro</h1>
                <div class="address-section">
                  <img src="../assets/images/category-page-home-icon.svg" alt="" />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="">About us</a></p>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      </div>
        <div class="col-12 mt-5">
          <div class="row">
            <div class="col-lg-5 col-md-3 col-sm-12">
                <div class="aboutus-left-heading-div">
                    <h3>Welcome to VayalAgro</h3>
                    <h1>Providing The Finest 
                             Products To The Best 
                     Feed Suppliers.</h1>
                </div>
            </div>
            <div class="col-lg-7 col-md-9 col-sm-12">
                <div class="aboutus-right-heading-div">
                   <p>Welcome to VayalAgro, The first Tamil agricultural classifieds marketplace dedicated exclusively to agricultural products. Our mission is to digitize non-digitized customers in the agricultural sector, Transforming the way farmers, Buyers, and sellers connect and transact.</p>
                </div>
            </div>
          </div>  
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="aboutus-main-content-div">
                    <div class="about-us-img-div">
                       <img src="../assets/images/Aboutus-component/aboutus-image-1.webp" alt="">
                </div>
                <h3>Our Marketplace</h3>
                <p>VayalAgro specializes in agricultural classifieds, connecting farmers directly with buyers for equipment, seeds, livestock, and produce. Our user-friendly platform eliminates middle agents, ensuring smooth transactions. With new listings daily, buyers enjoy a wide variety of fresh options.</p>
                <button><a href="/market-price">View More</a></button>
                </div>           
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="aboutus-main-content-div">
                    <div class="about-us-img-div">
                        <img src="../assets/images/Aboutus-component/aboutus-image-2.webp" alt="">
                </div>
                <h3>Unique Data Insights</h3>
                <p>What sets us apart in our region is our commitment to providing valuable insights. We offer near real-time data, verified by our dedicated data collection agents. This ensures that farmers can access accurate information, empowering them to make informed decisions about planting, selling, and purchasing.</p>
                <button><a href="/market-price">View More</a></button>
                </div>           
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="aboutus-main-content-div">
                    <div class="about-us-img-div">
                        <img src="../assets/images/Aboutus-component/aboutus-image-3.webp" alt="">
                </div>
                <h3>Join Our Community</h3>
                <p>At VayalAgro, we believe in the power of community. We are dedicated to supporting local farmers and businesses by creating a space where they can thrive. Our platform is more than just a marketplace; it’s a hub for collaboration, learning, and growth in the agricultural sector.</p>
                <button><a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank">View More</a></button>
                </div>           
            </div>
            <div class="bottom-line"></div>
          </div>   
          <div class="row">
            <div class="subcategory-contactus-div">
                <div class="left-subcategory-contactus-div">
                <h4>We are confident that we are the 
                    leading Company in providing agricultural products at Best Price.</h4>
             </div>
             <div class="right-subcategory-contactus-div">
                <div class="agricultural-div">
                    <div class="contactus-icon-div">
                    <img src="../assets/images/Aboutus-component/vegetable.svg" alt="">
                </div>
                <div class="right-subcategory-contactus-content">
                    <h1>1300+<span>Agricultural Products</span></h1>
                </div>
                </div>
                <div class="agricultural-div">
                    <div class="contactus-icon-div">
                    <img src="../assets/images/Aboutus-component/smile.svg" alt="">
                </div>
                <div class="right-subcategory-contactus-content">
                    <h1>1200+<span>Trust By Buyers</span></h1>
                </div>
                </div>            
             </div>
            </div>
          </div>
          <div class="row">

          </div>
        </div>
      </div>
      <div class="our-works-div">
          <div class="row">  
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="ourworks-content-div ourwork-first-div">
                        <h3>Our Mission</h3>
                        <p>At VayalAgro, we specialize in agricultural classifieds, allowing users to post items ranging from equipment and seeds to livestock and fresh produce. Our user-friendly platform enables farmers to connect directly with customers, eliminating middle agents.</p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="ourworks-content-div">
                        <h3>Our Vision</h3>
                        <p>At VayalAgro, we specialize in agricultural classifieds, allowing users to post items ranging from equipment and seeds to livestock and fresh produce. Our user-friendly platform enables farmers to connect directly with customers, eliminating middle agents.</p>
                    </div>
                </div>
            </div>
          </div>
           <div class="category-component">
            <CategoryComponent :selectedLanguage="selectedLanguage"/>
          </div>
    </section>
    
</template>

<script>
import CategoryComponent from '../components/CategoryComponent.vue';
import { en, ta } from "../translations.js";

export default {
    
     name: "AboutUsPage",
     components: {
    CategoryComponent
},
 props: {
    selectedLanguage: String
  },
 computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
  },
}


</script>

<style>
@import url("/src/components/AboutUsPage.scss");
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/CategoryComponent.scss");
</style>