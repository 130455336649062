<template>
  <section>
    <section class="equipment-section">
      <div class="wrapper">
        <div class="col-lg-12" v-if="categories.length > 0">
          <div v-if="isLoading" class="loader">
          <div class="row">
            <div
              v-for="product in 1"
              :key="product.id"
              class="col-lg-6 col-md-6 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
            <div
              v-for="product in 2"
              :key="product.id"
              class="col-lg-3 col-md-3 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
          </div>
        </div>
          <div class="row" v-else>
            <div class="col-lg-6 col-md-4">
              <div class="equipment-content">
                <h2 :class="{ 'tamil-equipment-title': selectedLanguage === 'ta', 'category-equipment-title': selectedLanguage !== 'ta' }">{{translations.equipments}}</h2>
                <p :class="{ 'tamil-equipment-para': selectedLanguage === 'ta', 'category-equipment-para': selectedLanguage !== 'ta' }">
                  {{ translations.Equip_des }}
                </p>
                <a href="/tamilnadu/agriculture-equipments"><button :class="{ 'tamil-equipment-button': selectedLanguage === 'ta', 'category-equipment-button': selectedLanguage !== 'ta' }">{{translations.allProduct}}</button></a>
              </div>
            </div>
            <div
              v-for="(item, index) in displayedCategories"
              :key="index"
              class="col-lg-3 col-md-4"
            >
              <div class="tractor">
                <div class="Diesel_div" @click="handleClick(item)">
                  <img
                    :src="item.image"
                    :alt="item.category_name"
                    class="diesel-img"
                  />
                  <div class="overlay">
                    <div class="text">
                      <h3  :class="{ 'tamil-product-title': selectedLanguage === 'ta', 'category-product-title': selectedLanguage !== 'ta' }">{{ selectedLanguage === "en" ? item.category_name : item.category_tn_name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <div v-if="isLoading" class="loader">
          <div class="row">
            <div
              v-for="product in 2"
              :key="product.id"
              class="col-lg-3 col-md-3 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
            <div
              v-for="product in 1"
              :key="product.id"
              class="col-lg-6 col-md-6 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
          </div>
        </div>
          <div class="row show-all-equip" v-else>
            <div v-for="(item, index) in displayedCategoriesIndex" :key="index" class="col-lg-3 col-md-4">
              <div class="tractor">
                <div class="Diesel_div" @click="handleClick(item)">
                  <img
                    :src="item.image"
                    :alt="item.category_name"
                    class="diesel-img"
                  />
                  <div class="overlay">
                    <div class="text">
                      <h3 :class="{ 'tamil-product-title': selectedLanguage === 'ta', 'category-product-title': selectedLanguage !== 'ta' }"> {{ selectedLanguage === "en" ? item.category_name : item.category_tn_name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-4">
              <div class="equipment-bottom-content" @click="navigateToEquipments">
                <h4>{{translations.typeOf}}</h4>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="isLoading" class="loader">
          <div class="row">
            <div
              v-for="product in 2"
              :key="product.id"
              class="col-lg-3 col-md-3 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
            <div
              v-for="product in 1"
              :key="product.id"
              class="col-lg-6 col-md-6 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
             <div class="row">
            <div
              v-for="product in 1"
              :key="product.id"
              class="col-lg-6 col-md-6 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
            <div
              v-for="product in 2"
              :key="product.id"
              class="col-lg-3 col-md-3 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
          </div>
          </div>
        </div>
        <div v-else>
              <div class="nodata-img">
          <img
            class=""
            src="../assets/images/emptypro.webp"
            loading="lazy"
            alt="empty"
          />
        </div>
        <h2 class="my-3 no-data">
          <!-- {{ translations.no_data_market }}  -->
         Equipment Not Available for here!!
        </h2>

        <p class="no-data-para">
          <!-- {{ translations.no_data_found }} -->
          We're sorry, Please check back later. You can browse our website to find
          alternative Category.
        </p>
        </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { en, ta } from "../translations.js";

export default {
   props: {
    selectedLanguage: String
  },
  data() {
    return {
      categories: [], 
      isLoading: true,
    };
  },
  computed: {
      translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    displayedCategories() {
      return this.categories.slice(0, 2).map((category) => ({
        ...category, // Preserve all properties for routing
        category_name: category.category_name,
        category_tn_name: category.tn_name,
        image: category.images[0], 
      }));
    },
    displayedCategoriesIndex() {
      return this.categories.slice(2, 4).map((category) => ({
        ...category, // Preserve all properties for routing
        category_name: category.category_name,
        category_tn_name: category.tn_name,
        image: category.images[0],
      }));
    },
  },
  mounted() {
    this.fetchCategories();
  },
 
  methods: {
    async fetchCategories() {
      try {
        this.isLoading = true; 
        const payload = { categorytype: "farm equipments" };
        const response = await axios.post(
          "https://vaiyal-app.herokuapp.com/categorytype",
          payload
        );
        if (response.data.status === "success") {
          this.categories = response.data.data;
        } else {
          console.error("Failed to fetch categories");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally{
          this.isLoading = false; 
      }
    },
    generateRoute(item) {
      const baseCategory = 
        item.category_type === 'Live stocks'
          ? 'livestocks'
          : item.category_type
              .replace(/\s/g, '-')
              .replace('farm', 'agriculture');

      if (item.hasSubCategory) {
        return {
          name: 'SubcategoryPage',
          params: {
            categoryId: item.category_id,
            categoryName: item.category_name.replace(/\s/g, '-').toLowerCase(),
            category: baseCategory,
            location: 'tamilnadu',
          },
        };
      } else {
        return {
          name: 'ListComponent',
          params: {
            product: item.category_name.replace(/\s/g, '-').toLowerCase(),
            location: 'tamilnadu',
            category: baseCategory,
          },
        };
      }
    },
    navigateToEquipments() {
      this.$router.push({
        path: '/tamilnadu/agriculture-equipments',
      });
    },
    handleClick(item) {
      const route = this.generateRoute(item);
      this.$router.push(route);
    },
  },
};
</script>


<style>
@import url("/src/components/EquipmentComponent.scss");


</style>
