<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1 :class="{ 'tamil-title-h2': selectedLanguage === 'ta', 'title-h2': selectedLanguage !== 'ta' }">{{ translations.marketPrice }}</h1>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;{{ translations.home_lite }}</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add">{{ translations.marketPrice }}</p>
                  <!-- <img src="../assets/images/next-page.svg" alt="" class="next-page">
                                <p class="footer-add">&nbsp;&nbsp;Dairy Product </p> -->
                </div>
                <!-- <img src="../assets/images/category-page-home-icon.svg" alt=""><span>Home</span> &nbsp; <img src="../assets/images/next-page.svg" alt=""> &nbsp;<span>Product Category </span> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="marketprice-select">
        <h1>{{ selectedLanguage === "en" ? 
    this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
    selectedProduct
  }} 
 {{ selectedLanguage === "en" 
  ? translations.marketPrice + (isToday(created) ? ' ' + translations.today : '') 
  : translations.today + ' ' + translations.marketPrice + (isToday(created) ? '' : '') 
}}   {{ selectedLanguage === "en" ? 
    (this.$route.params.location?.startsWith('-') ? 
      this.$route.params.location?.split('-')[1]?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu' : 
      this.$route.params?.location?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu') : 
    selectedDistrict ?? translations.tamilnadu
  }}
<!-- Market Price today in
  {{
    (this.$route.params.location?.startsWith('-') ? 
      this.$route.params.location?.split('-')[1]?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu' : 
      this.$route.params?.location?.replace(/\b\w/g, char => char.toUpperCase()) || 'Tamilnadu') 
  }} -->
  </h1>
  
         <div v-if="content" class="content ">
          <p>{{ content }}</p>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-10">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>{{ translations.categories }}</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class=" select-section">
                      <md-field>
                        <md-select v-model="selectedCategory" searchable  
                        @md-opened="disableBodyScroll"
                        @md-closed="enableBodyScroll">
                          <md-option value="0" disabled>{{ translations.selectProduct }}</md-option>
                          <md-option 
                            v-for="category in categories"
                            :key="category.category_id"
                            :value="category.category_id">
                            {{
                              selectedLanguage === "en"
                                ? category.category_name
                                : category.tn_name
                            }}
                            </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>{{ translations.district }}</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                     <div class=" select-section">
                      <md-field>
                        <md-select  v-model="selectedMarket"
                         @md-opened="disableBodyScroll"
                        @md-closed="enableBodyScroll"
                        searchable
                        :disabled="!markets.length">
                          <md-option value="0" disabled> {{ translations.selectDist1 }}</md-option>
                          <md-option 
                            v-for="market in markets"
                          :key="market.market_id"
                          :value="market.market_id">
                            {{
                            selectedLanguage === "en"
                              ? market.market_name
                              : market.tn_name
                          }}
                            </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>{{ translations.citys }}</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                     <div class=" select-section">
                      <md-field>
                        <md-select  v-model="selectedPlace"
                         @md-opened="disableBodyScroll"
                        @md-closed="enableBodyScroll"
                        :disabled="!placeData.length">
                          <md-option value="0"> {{ translations.all }}</md-option>
                          <md-option 
                            v-for="place in placeData"
                          :key="place.market_place_id"
                          :value="place.market_place_id">
                            {{
                            selectedLanguage === "en"
                              ? place.place
                              : place.tn_place
                          }}
                            </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="catgory">
                  <h5>{{ translations.date }}</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <input
                        type="date"
                        v-model="created"
                        :max="currentDate"
                        :min="minDate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12">
            <div class="" @click="searchMarketData">
              <button
                class="catgory-button"
                :disabled="selectedMarket == '0' || selectedCategory == '0'"
                :class="{
                  'disabled-red':
                    selectedMarket == '0' || selectedCategory == '0',
                }"
              >
                {{translations.search}}
              </button>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="row">
          
            <div class="col-lg-12 col-md-12 col-sm-12">
               <h2 >{{ translations.trendMp }}</h2>
              <div class="table-container" ref="MarketTable">
                <div v-if="isLoading">
                      <!-- <p>{{ logValue(isLoading) }}</p> -->

                  <!-- Shimmer effect for loading -->
                  <div v-for="index in 6" :key="index" class="container">
                    <p class="shimmer-line" style="height: 40px"></p>
                    <p class="shimmer-line" style="height: 40px"></p>
                    <p class="shimmer-line" style="height: 40px"></p>
                  </div>
                </div>
                <div  v-else-if="sublistData.length || (tumpData.length && !this.$route.params.location) ">
                  
                  <table>
                    <tr>
                      <th>{{translations.category}}</th>
                      <th >{{translations.selectdist}}</th>
                      <th >{{translations.city}}</th>
                      <th>{{translations.price}}</th>
                      <th>{{ translations.units }}</th>
                      <th>{{ translations.history }}</th>
                    </tr>
                    <tr
                      v-for="date in sublistData.length
                        ? sublistData
                        : tumpData"
                      :key="date.uniqueId"
                    >
                      <td>
                        <div class="category-name-table">
                          <img src="../assets/images/vegetable.png" alt="" class="veg-icon"/>
                          <p>
                            {{
                              selectedLanguage === "en"
                                ? date.sub_category_name
                                : date.tn_sub_name
                            }}
                            <!-- {{date.sub_category_name}} -->
                          </p>
                        </div>
                      </td>
                      <td>
                        {{
                          selectedLanguage === "en"
                            ? date.market_name
                            : date.tn_name
                        }}
                        <!-- {{date.market_name}} -->
                      </td>
                      <td>
                        {{
                          selectedLanguage === "en" ? date.place : date.tn_place
                        }}
                        <!-- {{date.place}} -->
                      </td>
                      <td class="amt">
                        Rs. {{ date.price }}
                        </td>
                      <td class="weight">
                        {{
                          selectedLanguage === "en"
                            ? date.quantity_type
                            : date.quantity_type_tamil
                        }}
                        <!-- {{date.quantity_type}} -->
                      </td>
                      <td>
                        <router-link :to="{
                      name: 'AllRecent',
                      params: {
                        name: date.sub_category_name.trim().replace(/\s+/g, '-').toLowerCase() || '',
                        categoryName: date.category_name.replace(/\s+/g, '-').toLowerCase(),
                        location: '-' + date.market_name.replace(/\s+/g, '-').toLowerCase(),
                        city: date.place.replace(/\s+/g, '-').toLowerCase(),
                      },
                    }" target="_blank">
                          <button>
                          
                            <img
                              src="../assets/images/history.svg"
                              alt=""
                              class="history-price"
                            />
                            &nbsp;{{ translations.view }}
                         
                        </button>
                         </router-link>
                      </td>
                    </tr>
                  </table>
                </div>
                   <div v-else>
             <div class="nodata-img">
              <img class="" src="../assets/images/emptypro.webp" loading="lazy" alt="empty">
            </div>
              <h2 class="my-3 no-data">
                {{ translations.no_data_market }} 
                <!-- Market price is not available for selected date -->
               </h2>
               
               <p class="no-data-para">
                {{ translations.no_data_found }}
                <!-- We're sorry, but the Market price not available for the selected date filter. Please check back later. You can browse our website to find alternative market price categories. -->
                </p>
           
          </div> 
              </div>
                 
              <div class="page-nation" v-if="sublistData.length">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6" >
                    <div class="show-page">
                      <p v-if="currentPage < totalPages">Showing {{ currentPage }} / {{ totalPages }} of {{ totalItems }} item(s)</p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="page-btns">
                       <nav >
      <ul class="pagination">
    <!-- First Page -->
    <li :class="{ disabled: currentPage === 1 }">
      <a @click="onPageChange(1)" aria-label="First">
            <span aria-hidden="true">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
      </a>
    </li>

    <!-- Previous Page -->
    <li :class="{ disabled: currentPage === 1 }">
      <a @click="onPageChange(currentPage - 1)" aria-label="Previous">
            <span aria-hidden="true">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
      </a>
    </li>

        <li :class="{ active: currentPage === pageNumber }" v-for="pageNumber in pageNumbers" :key="pageNumber">
          <a @click="onPageChange(pageNumber)">{{ pageNumber }}</a>
    </li>
        <!-- Next Page Button -->
    <li :class="{ disabled: currentPage === totalPages }">
      <a @click="onPageChange(currentPage + 1)" aria-label="Next">
            <span aria-hidden="true">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
      </a>
    </li>

        <!-- Last Page Button -->
    <li :class="{ disabled: currentPage === totalPages }">
      <a @click="onPageChange(totalPages)" aria-label="Last">
            <span aria-hidden="true">
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
      </a>
    </li>
  </ul>
</nav>
                    </div>
                  
                  </div>
                </div>
                
              </div>
                    <h3 class="todays-head mt-3" v-if="Newmarket.length > 1">
  {{ translations.todays }} 
  {{ selectedLanguage === 'en' ? 
    this.$route.params?.categoryName?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase()) : 
    selectedProduct 
  }} 
  {{ translations.marketPrices1 }} {{ translations.tamilnadu }}
</h3>
<div class="market-container">
  <div
    v-for="(market, index) in Newmarket"
    :key="market.market_id"
    :value="market.market_id"
    class="inter-links"
  >
    <span
    
      @click="navigateToMarketPrice(market)"  
    >
      {{ selectedLanguage === 'en' ? market.market_name : market.tn_name }} {{ 
    
      }} {{ translations.marketPrice  }}
      <span v-if="index !== Newmarket.length - 1"> | </span>
    </span>
  </div>
</div>
             <div class="disclaimer">
      <p class="text-center my-3 container" v-if="disclaimer"><strong>Disclaimer:</strong> {{ disclaimer }}</p>
    </div>
            </div>

            <!-- <div class="col-lg-2 col-md-2 col-sm-12 mt-5">
            <div class="vertical-ad">
              <Adsense
     data-ad-client="ca-pub-1763856178878021"
     data-ad-slot="4892250513">
</Adsense>
            </div>
            <div class="vertical-ad mt-3">
              <Adsense
     data-ad-client="ca-pub-1763856178878021"
     data-ad-slot="4892250513">
</Adsense>
            </div>
          </div> -->
          </div>
        </div>
      </section>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 bottom-ads">
      <AdsComponent />
    </div>
  </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { disableScroll } from "../router/index";
import { useItemStore, useUserStore } from "../stores/userStore";
import { mapActions, mapState } from "pinia";
import AdsComponent from "./AdsComponent.vue";
import { en, ta } from "../translations";

export default {
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      marketName: null,
      categoryName: null,
      sublistData: [],
      isLoading: true,
      place: null,
      totalItems: 0,
      itemsPerPage: 10, // Example: set the default number of items per page
      currentPage: 1,
      totalPages: 0, // Add this property to store the total number of pages
      selectedMarket: "0",
      markets: [],
      Newmarket: [],
      tumpData: [],
      selectedPlace: "0",
      placeData: [],
      selectedCategory: "0",
      categories: [],
      dates: [],
      chooseDate: "",
      // created: this.selectedDate || new Date().toISOString().split('T')[0],
      isFreshPage: this.$route.params.location ? true : false,
      created: new Date().toISOString().split("T")[0],
      currentDate: new Date().toISOString().split("T")[0],
      minDate: this.getMinDate(),
      selectedProduct: "",
      selectedDistrict: "",
      searchedPlace: "0",
      subListDataFetched: false,
      isPlaceDataFetched: false,
    };
  },
  components: {
    AdsComponent,
  },
  head() {
    return {
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "https://vayalagro.com/market-price",
            url: "https://vayalagro.com/market-price",
            name: "Market Prices in Tamil Nadu",
            description:
              "Get the latest market prices for various agricultural products and livestock in Tamil Nadu. Stay informed for better trading decisions.",
            inLanguage: "en-US",
            datePublished: "2024-10-17T00:00:00+00:00",
            dateModified: "2024-10-21T00:00:00+00:00",
            breadcrumb: {
              "@type": "BreadcrumbList",
              "@id": "https://vayalagro.com/market-price/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://vayalagro.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Market Prices",
                  item: "https://vayalagro.com/market-price",
                },
              ],
            },
          },
        },
      ],
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    ...mapState(useItemStore, ["categoryId", "subCategoryId"]),
    ...mapState(useUserStore, ["marketId", "marketPlaceId"]),
    selectedDate() {
      return this.$router.history?.state?.selectedDate || false;
    },

    formatDate() {
      return (date) => moment(date).format("DD-MM-YYYY");
    },

    sublistDate() {
      return (date) => date.split("/").join("-");
    },
    pageNumbers() {
      const visiblePageCount = 3; // Number of page numbers to display
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(visiblePageCount / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + visiblePageCount - 1
      );

      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
  },
  beforeCreate() {
    if (!Object.keys(this.$route.params).length) {
      this.$route.params = {
        location: "",
        categoryName: "",
        city: "",
        created: "",
        categoryId: "",
      };
      if (!this.selectedCategory) this.selectedCategory = "0";
      if (!this.selectedMarket) this.selectedMarket = "0";
      if (!this.selectedPlace) {
        // console.log('mpId to 0 (0)')
        this.selectedPlace = "0";
      }
    }
    if (this.selectedDate) {
      this.created = this.selectedDate;
      disableScroll();
      console.log("created set from state!", this.selectedDate);
    }
  },
  watch: {
    categoryId(newVal, oldVal) {
      if (newVal !== oldVal) {
        // this.fetchMarketData();
        // this.updateMarketPlaceId(this.$route.params?.city?.replace(/-/g,' '),this.categoryId,this.marketId)
      }
    },
    subCategoryId(newVal) {
      console.log("SubCategory ID changed:", newVal);
    },
    marketId(newVal, oldVal) {
      // console.log('Market ID changed:', newVal);
      if (
        newVal !== oldVal &&
        this.marketId != "0" &&
        this.categoryId != "0" &&
        !this.isPlaceDataFetched
      )
        this.fetchPlaceData();
    },
    marketPlaceId(newVal, oldVal) {
      console.log("Marketplace ID changed from:", oldVal, " to ", newVal);
    },
    // Watch for changes in the route parameters
    "$route.params": {
      handler(newParams, oldParams) {
        // console.log('route params watcher called...')
        this.updateMetaTags();
        this.handleRouteChange(newParams, oldParams);
      },
      deep: true,
      immediate: true,
    },
    selectedMarket: {
      handler(newVal, oldVal) {
        console.log(
          "selectedMarket watcher called... new:",
          newVal,
          "old:",
          oldVal
        );
        if (this.selectedMarket != this.marketId) {
          // console.log("mId changing...")
          this.updateMarketId(this.selectedMarket);
        } else {
          // console.log("not changed")
        }
        if (
          !this.subListDataFetched &&
          this.$route.params.categoryName &&
          this.selectedCategory != "0" &&
          this.selectedMarket != "0" &&
          !this.$route.params?.city
        ) {
          // console.log("line-454", this.$route.params)
          this.fetchSublistData();
          this.isFreshPage = false;
        } else {
          // console.log("mId not changed here", this.subListDataFetched, )
        }
        this.currentPage = 1; // Reset the current page to 1 when the market changes
        if (this.selectedMarket != "0") {
          // this.selectedPlace = '0';
          this.fetchPlaceData();
        }
      },
      immediate: true,
    },
    selectedPlace: {
      handler(newPlace, oldPlace) {
        // console.log('newPlace:',newPlace,"oldPlace:",oldPlace,'marketPlaceId',this.marketPlaceId)
        if (this.selectedPlace != this.marketPlaceId) {
          // console.log("mpId changing...")
          this.updateMarketPlaceId(newPlace);

          if (
            !this.subListDataFetched &&
            this.$route.params.categoryName &&
            this.selectedPlace != "0" &&
            Object.keys(this.$route.params).length &&
            newPlace !== oldPlace &&
            this.categoryId &&
            this.marketId
          ) {
            // console.log('line reload sublist')
            this.fetchSublistData();

            this.isFreshPage = false;
          } else {
            // console.log('line reload sublist not called')
          }

          this.currentPage = 1; // Reset the current page to 1 when the place changes
        } else {
          // console.log("mpId not changed")
        }
      },
      immediate: true,
    },
    selectedCategory: {
      handler() {
        if (this.selectedCategory != "0") {
          this.fetchMarketData();
        }
        if (this.selectedCategory != this.categoryId) {
          // console.log("cat changing...")
          this.updateCatId(this.selectedCategory);
        } else {
          // console.log("cat not changed")
        }
        this.currentPage = 1;
      },
    },
    created: {
      // when changing date, data should come bcoz we are not passing in url to fetch when search
      handler() {
        if (
          this.selectedCategory &&
          this.selectedCategory != "0" &&
          this.selectedMarket &&
          this.selectedMarket != "0"
        ) {
          console.log("line-498");
          this.fetchSublistData((this.currentPage = 1));
          this.isFreshPage = false;
        }
      },
        

    },
  },
  beforeUnmount() {
    (this.sublistData = []),
      (this.markets = []),
      (this.tumpData = []),
      (this.placeData = []),
      (this.categories = []),
      (this.dates = []);
  },

  async created() {
       console.log("mp-created",this.$route);
    this.fetchCategoryData(true);
    if (this.selectedCategory) {
      await this.fetchMarketData();
    }
    if (this.selectedCategory != "0" && this.selectedMarket != "0") {
      console.log("line-521");
    }

    if (!this.$route.params.location) {
      await this.fetchInitData();
    }
    this.updateMetaTags();
  },

  mounted() {
    this.marketName = this.$route.params?.location;
    this.categoryName = this.$route.params?.categoryName?.replace(/-/g, " ");
  },
  methods: {
     disableBodyScroll() {
      // Disable body scroll when the dropdown is opened
      document.body.style.overflow = "hidden";
    },
    enableBodyScroll() {
      // Re-enable body scroll when the dropdown is closed
      document.body.style.overflow = "auto";
    },
   logValue(value) {
      console.log("Value:", value);
      return ""; // Prevents the method from rendering anything in the DOM
    },
    ...mapActions(useItemStore, [
      "updateItemId",
      "updateMarketCatId",
      "updateSubCatId",
      "updateCatId",
    ]),
    ...mapActions(useUserStore, [
      "updateMarketId",
      "updateMarketIdByDist",
      "updateMarketPlaceId",
      "updateMarketPlaceIdByCity",
    ]),
    redirectToContact() {
      this.$router.push("/contact");
    },
    updatePaginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedData = this.sublistData.slice(start, end);
    },
    onPageChange(page) {
      this.$nextTick(() => {
        const container = this.$refs.MarketTable;
        if (container) {
          container.scrollIntoView({ behavior: "smooth" });
        }
      });
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        console.log("this.currentPage", this.currentPage);
        this.updatePaginatedData();
      }
      this.fetchSublistData();
    },
    navigateToMarketPrice(market) {
      const categoryName = this.$route.params.categoryName;
      const district = market.market_name;
console.log("categoryName-mp",categoryName,"district",district)
      this.created = new Date().toISOString().split("T")[0];
      console.log("market-data", this.created);

      this.$router.push({
        name: "pricesublist",
        params: {
          categoryName: categoryName,
          location: `-${district}`,
        },
      });
   
    },

  
    async handleRouteChange(newParams = null) {
      console.log("handleRouteChange");
      

      if (!Object.keys(newParams).length) {
        // console.log("line-624", Object.keys(newParams).length)
        if (!this.selectedCategory) this.selectedCategory = "0";
        if (!this.selectedMarket) this.selectedMarket = "0";
        if (!this.selectedPlace) this.selectedPlace = "0";
        this.fetchInitData();
        this.sublistData = [];
      } else {
        // console.log("line-592",this.selectedCategory,this.selectedMarket)
        await this.fetchMarketData();
        if (this.selectedCategory != "0" && this.selectedMarket != "0") {
          // console.log("line-592")
          this.fetchSublistData();
        }
      }
      this.fetchCategoryData();
    },

    getMinDate() {
      const today = new Date();
      today.setDate(today.getDate() - 30); // Subtract 30 days
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    isToday(date) {
      const today = new Date();
      const todayString = today.toISOString().split("T")[0];
      return date === todayString;
    },
    searchMarketData() {
  console.log("searchMarketData");
  this.isLoading = true;
  var params = {
    ...this.$route.params,
  };

  this.Newmarket = [];

  for (let cat of this.categories) {
    if (cat.category_id == this.selectedCategory) {
      params.categoryName = cat?.category_name?.replace(/\s+/g, '-')?.toLowerCase();
      break;
    }
  }

  for (let district of this.markets) {
    if (district.market_id == this.selectedMarket) {
      params.location = district?.market_name?.replace(/\s+/g, '-')?.toLowerCase();
      break;
    }
  }

  // Only include city if a city is selected
  if (this.selectedPlace != '0') {
    for (let city of this.placeData) {
      if (city.market_place_id == this.selectedPlace) {
        params.city = city?.place?.replace(/\s+/g, '-')?.toLowerCase();
        break;
      }
    }
  }

  // Ensure location starts with a hyphen
  params.location = params.location && params.location[0] == '-' ? params.location : `-${params.location}`;

  this.isFreshPage = true;
  disableScroll();

  this.$nextTick(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    if (mediaQuery.matches) {
      const container = this.$refs.MarketTable;
      if (container) {
        container.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  // Remove city from params if it's not selected
  if (!params.city) {
    delete params.city;
  }

  this.$router.push({
    name: 'pricesublist1',
    params,
    state: {
      selectedDate: this.created
    }
  });

  console.log("marketprice-params", this.$route);

  this.currentPage = 1;
  this.isLoading = false;
},

    storeIds(data) {
      if (data.sub_category_id) {
        if (data.sub_category_id != this.subCategoryId) {
          // console.log("subcat changing...")
          this.updateSubCatId(data.sub_category_id);
        } else {
          // console.log("subcat not changed")
        }
      } else {
        if (this.selectedCategory != this.categoryId) {
          // console.log("cat changing...")
          this.updateCatId(this.selectedCategory);
        } else {
          // console.log("cat not changed")
        }
      }
    },
    fetchCategoryData(loader = null) {
      const data = {
        categorytype: "market price",
      };

      if (loader) {
        // console.log("loader on categories")
        this.isLoading = true;
      } // Set isLoading to true to show the loading spinner

      axios
        .post("https://vaiyal-app.herokuapp.com/categorytype", data)
        .then((response) => {
          this.categories = response.data.data;
          console.log("this.categories", this.categories);
          if (response.data.status == "success") {
            this.categories = [];
            response.data.data.map((category) => {
              if (category.isdatafound) {
                this.categories.push(category);

                if (
                  category.category_name.replace(/\s+/, "-").toLowerCase() ==
                  this.$route.params?.categoryName
                ) {
                  this.selectedCategory = category.category_id;
                  this.selectedProduct = category.tn_name;
                }
              }
            });
            // console.log("response.data.data",response.data.data)

            //TODO: if cat in params, run this loop.
            for (let category of this.categories) {
              if (
                category.category_name.replace(/\s+/, "-").toLowerCase() ==
                this.$route.params?.categoryName
              ) {
                this.selectedCategory = category.category_id;
                this.selectedProduct = category.tn_name;
                break;
              }
            }
            // console.log('loader cat 1')
            if (!this.$route.params?.location) this.isLoading = false;
            this.$nextTick(() => {
              const container = this.$refs.categoryContainer;
              if (container) {
                container.scrollIntoView({ behavior: "smooth" });
              }
            });
          }
        })
        .catch((error) => {
          console.error("Post error:", error);
          // console.log('loader cat finally')
          // this.isLoading = false;
        });
    },
    async fetchMarketData() {
      try {
        if (this.selectedCategory != 0) {
          const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=market&categoryid=${this.selectedCategory}`;
          const response = await fetch(apiUrl);
          const data = await response.json();

          if (data.status === "success") {
            this.markets = data.data;

            if (!this.Newmarket.length) {
              this.Newmarket = [...this.markets];
            } 

            let change;
            this.markets.map((dist) => {
              if (
                this.$route.params.location.split("-")[1].replace(/-/g, " ") ===
                dist.market_name.toLowerCase()
              ) {
                this.marketId = dist.market_id;
                this.selectedMarket = dist.market_id;
                this.selectedDistrict = dist.tn_name;
                change = true;
              }
            });

            if (!change) {
              this.selectedMarket = "0";
            }
          } else {
            console.error("API response error:", data.msg);
          }

          this.fetchPlaceData();
        }
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    },
    async fetchInitData() {
      fetch("https://vaiyal-app.herokuapp.com/get/recentlist?type=market")
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            this.tumpData = data.data;
            this.recentCategory = data.data.categoryName;
            console.log("123AAAAA", this.tumpData);
          } else {
            console.error("Failed to fetch recent product data:", data.msg);
          }
        })
        .catch((error) => {
          console.error("Error fetching recent product data:", error);
        });
    },
    async fetchPlaceData() {
      if (this.selectedMarket) {
        try {
          const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=market&categoryid=${this.selectedCategory}&marketid=${this.selectedMarket}`;
          const response = await fetch(apiUrl);

          const data = await response.json();
          if (data.status === "success") {
            this.isPlaceDataFetched = true;
            this.placeData = [];
            this.placeData = data.data;
            let change;
            for (let i of data.data) {
              if (
                i.place.toLowerCase() ==
                this.$route.params?.city?.replace(/-/g, " ")
              ) {
                change = true;
                if (this.selectedPlace != i.market_place_id) {
                  // console.log("mpId 2 changing...")
                  this.selectedPlace = i.market_place_id;
                  // this.updateMarketPlaceId(i.market_place_id)
                  break;
                }
              } else {
                // console.log("mpId 2 not changed", this.selectedPlace, i.market_place_id)
                // if (reset) this.selectedPlace = '0'
              }

              // this.selectedPlace = i.market_place_id
              // this.updateMarketPlaceId(i.market_place_id)
              // change = true;
            }
            if (!change) {
              if (this.selectedPlace != "0") {
                // console.log('mpId to 0 (1)',reset)
                this.selectedPlace = "0";
              }
            }
          } else {
            console.error("API response error:", data.msg);
          }
        } catch (error) {
          console.error("Error fetching place data:", error);
        }
      } else {
        this.placeData = []; // Reset placeData if no market is selected
        this.selectedPlace = "0"; // Reset selectedPlace
        // console.log('mpId to 0 (2)')
      }
    },

    fetchSublistData() {
      this.isLoading = true;

      const apiUrl = `https://vaiyal-app.herokuapp.com/getplacemarket?page=${this.currentPage}`;
      const payload = {
        categoryid: this.selectedCategory,
        marketid: this.selectedMarket,
        marketplaceid: this.selectedPlace,
        created: this.created,
      };

      axios
        .post(apiUrl, payload)
        .then((response) => {
          if (response.data.status === "success") {
            this.sublistData = response.data.data.Dates; // All data
            console.log("this.sublistData",this.sublistData)
            this.totalItems = response.data.data.totalItems; // Total items (from API)
            this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
            this.content = response.data.data.content;
            this.disclaimer = response.data.data.disclaimer
            this.updatePaginatedData();
          } else {
            console.error("API Error:", response.data.msg);
          }
        })
        .catch((error) => {
          console.error("sublist:", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async updateMetaTags() {
      const categoryName = this.$route.params.categoryName;
      const pageName = "pricesublist";
      const city = this.$route.params.city;
      const district =
        this.$route.params.location?.split("-")[1] || "Tamilnadu";
      const created = this.created;

      const formatDate = (date) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, "0");
        const month = String(d.getMonth() + 1).padStart(2, "0");
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
      };

      const formattedCreatedDate = formatDate(created);
      console.log("formattedCreatedDate", formattedCreatedDate);
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const capitalizedCity = capitalizeFirstLetter(city || "");
      const capitalizedDistrict = capitalizeFirstLetter(district || "");

      try {
        const response = await axios.get(
          "https://vaiyal-app.herokuapp.com/meta",
          {
            params: {
              category_name: categoryName,
              page_name: pageName,
            },
          }
        );

        const metaData = response.data.data;

        if (metaData) {
          const title = metaData.title
            .replace(
              "{city}",
              this.$route.params.city ? capitalizedCity + "," : ""
            )
            .replace("{district}", capitalizedDistrict);
          document.title = title;

          const description = metaData.description
            .replace(
              "{city}",
              this.$route.params.city ? capitalizedCity + "," : ""
            )
            .replace("{district}", capitalizedDistrict)
            .replace("{date}", formattedCreatedDate);
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute("content", description);
          }
          const head = metaData.title
            .replace(
              "{city}",
              this.$route.params.city ? capitalizedCity + "," : ""
            )
            .replace("{district}", capitalizedDistrict);
          const metaHead = document.querySelector('meta[name="title"]');
          if (metaHead) {
            metaHead.setAttribute("content", head);
          }

          const canonicalLink = document.querySelector('link[rel="canonical"]');
          const canonicalHref = `https://vayalagro.com/market-price/${categoryName}`;
          if (canonicalLink) {
            canonicalLink.setAttribute("href", canonicalHref);
          } else {
            const linkElement = document.createElement("link");
            linkElement.setAttribute("rel", "canonical");
            linkElement.setAttribute("href", canonicalHref);
            document.head.appendChild(linkElement);
          }
        }
      } catch (error) {
        console.error("Error fetching meta data:", error);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "Home") {
      window.location.reload();
    } else {
      next();
    }
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
@import url("/src/components/Marketprice.scss");
</style>
