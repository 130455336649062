<template>
   <section class="app-display-section">
       <div class="wrapper">
          <div class="col-lg-12 app-background">
             <div class="row">
                 <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="download-content">
                       <h1>Download <span>VayalAgro App</span></h1>
                       <p>Buy, Sell & Trade – All in One Agri App</p>
                       <div class="google-paly">
                        <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="blank">
                          <img src="..//assets/images/googleplay.svg" alt="">
                          </a>
                       </div>
                     </div>
                 </div>
                 <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="app-img-display">
                        <div class="app-home-img">
                            <img src="..//assets/images/Mobile-App.png" alt="">
                        </div>
                    </div>                     
                 </div>
             </div>
          </div>
       </div>
   </section>
</template>

<script>
export default {

}
</script>

<style>
 @import url("/src/components/AppComponent.scss");
</style>