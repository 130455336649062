// translations.js

export const en = {
    vayal: "Vayal",
    agro: "Agro",
    vayalagro: "Vayalagro",
    logotxt: "VAYALAGRO",
    vayalDesc:
        "Buy and sell crops, livestock, farm equipment, tractors, and more locally. Check daily prices for vegetables and flowers.",
    search: "Search",
    home: "HOME",
    market: "MARKET",
    home_lite: "Home",
    market_lite: "Market",
    about: "About",
    contact: "Contact",
    articles: "Articles",
    products: "Products",
    categories: "Categories",
    advertiseProducts: "Advertise Products",
    farmProducts: "Agriculture Products",
    farmEquipments: "Agriculture Equipments",
    equipments: "Equipments",
    typeOf:"50+ Type Of Products",
    totalItems: "Items",
    district: "Districts",
    citys: "City",
    trendMp: "Trend of Market Price Today",
    units: "Units",
    broeseCate: "Browse By Category",
    bannerHead: "Empowering Farmers, Connecting Markets",
    allProduct:"All Products",
    bestSel: "Buy & Sell Best in Agriculture",
    bannerSearch: "Search Your Agriculture Product & Equipments",
    bannerList: "Crops | Livestock | Farm Equipment Tractors | Check Daily Prices for Vegetables Flowers",
    PopulrProduct: "Popular Products",
    DailySells: "Daily Best Sells",
    marketPrice: "Market Price",
    marketPrices1:"Market Prices in",
    privacy: "Privacy Policy",
    termsOfUse: "Terms of Use",
    downloadOnGooglePlay: "Download on Google Play",
    pages: "Pages",
    quickLinks: "Quick Links",
    other: "Other",
    downloadOn: "Download on the",
    sendButton: "Send",
    successMessage: "Email sent successfully!",
    contactUs: "Contact us",
    contactDescription:
        "Feel free to contact us if you need any assistance, any help or another question.",
    namePlaceholder: "Name",
    lastName: "Last Name",
    emailPlaceholder: "Enter your Email",
    messagePlaceholder: "Message",
    copyRight: "Copyright © 2022-2023 Vayal. All Rights Reserved.",
    selectProduct: "Select a Category",
    selectCity: "Select city",
    // selectDist: "Select district",
    selectDist1: "Select district",
    talktous: "Talk to Us",
    talkhead: "Can't Find The Product You Are Looking For?",
    blogs: "Blogs",
    blogtag: "",
    blogtag_: "The Future of Agro innovation.",
    view: "View",
    viewall: "View All",
    viewmore: "View More",
    readmore: "Read On Site",
    signup: "Sign Up",
    signupsub: "for news letters and daily updates.",
    emails: "Email To Us:",
    address: "Address:",
    address1:
        "M/S. Vayal Agro Tech (Opc) Pvt Ltd, 28 Ncn Garden , Thirunagar (Opp to Muthu Mahal ) , Gobichettipalayam, Erode , Tamil Nadu.",
    all: "All",
    category: "Category",
    buy: "Buy",
    sell: "Sell",
    click: "Click here to see",
    recentproduct: " Recent Price - Agriculture Products",
    selectone: "Select One",
    recentequip: " Recent Price - Agriculture Equipments",
    allLabel: "All",
    price: "Price",
    createdon: "Created On",
    results: "Results",
    quantity: "Quantity",
    posted: "Item Created",
    user: "User Type",
    marketplace: "Market Place",
    contactno: "Contact Number",
    business: "Business Name ",
    sub: "Subcategory",
    clear: "Clear Filter",
    producttag:
        "Classifieds for Dairy, Fresh Produce, Seeds, poultry and Livestock!",
    equiptag:
        "Your Marketplace for Farming: Tractors, Irrigation, Motors Pumps and More.",
    markettag:
        "Daily and Historical Prices for Flowers, Vegetables, and Meat Market.",
    listdate: "List of Date",
    defaultContent: "error",
    selectonemark: "Select place ",
    selectdist: "District",
    selecttown: "Cities",
    city: "City",
    startdate: "Start Date",
    enddate: "End Date",
    location: "Location",
    locationFilter: "Location Filter",
    only: "Only 30days of data you will get by Date.",
    newreccomendation: "New Recommendations",
    selectDC: "Select District & City ",
    place: "Place",
    date: "Date",
    history: "History Price",
    recentlyadded: "New Added",
    recentlyaddedtag: "",
    recentlyaddedtag_: "LIST OF TRENDS IN THE AGRICULTURAL SECTOR",
    explore: "Explore All",
    promotedProds: "Featured",
    promotedEquips: "Featured Equipments",
    daily: "Daily",
    cp: "Contact Person:",
    itemid: "Item Id",
    signuptag: "for News Letters and Daily Updates",
    tamilnadu: "Tamilnadu",
    report: "Report Issue",
    prnm: "Price Not Mentioned",
    pm: "not mentioned",
    sp: "Similar Products",
    reportitem: "Report This Item",
    inpropriate: "Inappropriate profile picture",
    Offensive: "Offensive Content",
    fraud: "Fraud",
    Livestocks: "Livestocks",
    reason: "Reason for Report",
    submit: "Submit",
    getsite: "Get More on Related site",
    useapp: "To Showcase your product use VayalAgro app",
    getapp: "Get App on play store",
    recentprice: "Recent Price",
    links: "Quick Links",
    delivery: "Delivery Available",
    getcate: "Categories",
    viewprice: "View Price",
    hideexplore: "Show Less",
    showmore: "Show More",
    historyPrice: "History Price of",
    searchFor: "Product's Found",
    footerDecs:
        "VayalAgro - Agriculture Classifieds, Buy, Sell, Daily & History Market Prices, Updates and news",
    history_of: "History Price of",
    filer_category: "Filter Categories",
    agriculture_products: "Agriculture Products",
    agriculture_equipments: "Agriculture Equipments",
    livestocks: "Livestocks",
    today: "today in",
    todays: "Today's",
    no_data_product:"Product Temporarily Unavailable in Your Region",
    no_data_product_para:"We're sorry, but the product you're looking for is Temporarily not available in your city You can browse our website to find alternatives or head back to Homepage.",
    no_data_market:"Market price is not available for selected date",
    no_data_found:"We're sorry, but the Market price not available for the selected date filter. Please check back later. You can browse our website to find alternative market price categories.",
    deleteAccount: {
        title: "Login",
        acc: "Delete Account",
        mobileNumber: "Mobile Number",
        requestOtp: "Request OTP",
        enterOtp: "Enter OTP",
        verifyOtp: "Verify OTP",
        deleteAccountButton: "Delete Account",
    },
    Cate_pro_des: "Buy fresh farm products for daily use and bulk needs.",
    Equp_pro_des: "Shop quality farm machinery and tools for better farming.",
    Live_pro_des: "Get healthy livestock for farms, dairy, and business needs.",
    Mp_pro_des: "Stay updated with daily market prices for smart buying.",
    Equip_des: "Find the best farming tools and machinery from 100+ sellers in your area. Compare tractors, tillers, harvesters, sprayers, and more to get the right equipment for your farm. Explore and connect with sellers now!",
    New_product:"New Products",
    jack_fruit: "Jack Fruit",
    pomogrante:"Pomegranate",
    apple: "Fresh! Apple",
    Allcategories: "All Categories",
    get_in: "Get In Touch",
    table: "Table",
    graph: "Graph"
};

export const ta = {
    vayal: "வயல்",
    agro: "அக்ரோ",
    vayalagro: "வயல்அக்ரோ",
    logotxt: "வயல்அக்ரோ",
    vayalDesc:
        "பயிர்கள், கால்நடைகள், பண்ணை உபகரணங்கள், டிராக்டர்கள் மற்றும் பலவற்றை உள்ளூரில் வாங்கவும் விற்கவும். காய்கறிகள் மற்றும் பூக்களின் தினசரி விலையை சரிபார்க்கவும்.",
    search: "தேடு",
    home: "முகப்பு",
    market: "சந்தை",
    home_lite: "முகப்பு",
    market_lite: "சந்தை",
    about: "எங்களைப் பற்றி",
    contact: "தொடர்பு",
    articles: "கட்டுரைகள்",
    categories: "வகைகள்",
    advertiseProducts: "விளம்பர பொருட்கள்",
    farmProducts: "வேளாண்மை பொருட்கள்",
    products: "பொருட்கள்",
    farmEquipments: "வேளாண்மை உபகரணங்கள்",
    equipments: "உபகரணங்கள்",
    typeOf:"50+ தயாரிப்பு வகைகள்",
    totalItems: "பொருட்கள்",
    district: "மாவட்டம்",
    citys: "நகரம்",
    units: "அலகுகள்",
    trendMp: "இன்றைய சந்தை விலையின் போக்கு",
    broeseCate: "வகை மூலம் உலாவவும்",
    bannerHead: "விவசாயிகளை மேம்படுத்துதல், சந்தைகளை இணைத்தல்",
    bestSel: "விவசாயத்தில் சிறந்ததை வாங்கவும் விற்கவும்",
    bannerSearch: "உங்கள் விவசாய தயாரிப்பு மற்றும் உபகரணங்களைத் தேடுங்கள்",
    bannerList: "பயிர்கள் | கால்நடைகள் | பண்ணை உபகரணங்கள் டிராக்டர்கள் | காய்கறிகள் பூக்களுக்கான தினசரி விலையை சரிபார்க்கவும்",
    allProduct:"அனைத்து பொருட்கள்",
    PopulrProduct:"பிரபலமான பொருட்கள்",
    DailySells:"தினசரி சிறந்த விற்பனை",
    marketPrice: "சந்தை விலை",
    marketPrices1: "சந்தை விலைகள்",
    privacy: "தனியுரிமை",
    termsOfUse: "பயன்பாட்டு விதிமுறைகள்",
    downloadOnGooglePlay: "கூகுள் பிளேயில் பதிவிறக்கு",
    pages: "பக்கங்கள்",
    quickLinks: "இணைப்புகள்",
    other: "மற்றவை",
    downloadOn: "பதிவிறக்கு",
    sendButton: "அனுப்பு",
    successMessage: "மின்னஞ்சல் வெற்றிகரமாக அனுப்பப்பட்டது!",
    contactUs: "எங்களை தொடர்பு கொள்ளுங்கள்",
    contactDescription:
        "உங்களுக்கு ஏதேனும் கேள்விகள், ஆலோசனைகள் இருப்பின் உதவிக்கு எங்களுடன் உரையாடுகள்.",
    namePlaceholder: "பெயர்",
    lastName: "கடைசி பெயர்",
    emailPlaceholder: "மின்னஞ்சல்",
    messagePlaceholder: "செய்தி",
    copyRight:
        "பதிப்புரிமை © 2022-2023 வயல். அனைத்து உரிமைகளும் பாதுகாப்பு செய்யப்பட்டுவிட்டன.",
    selectProduct: "வகைகள்",
    selectDist: "மாவட்டங்கள்",
    talktous: "தொடர்புகொள்ள",
    talkhead: "உங்கள் உதவிக்கு",
    blogs: "பதிவுகள்",
    blogtag: "",
    blogtag_: "வேளாண் கண்டுபிடிப்புகளின் எதிர்காலம்.",
    view: "பார்க்க",
    viewmore: "மேலும் பார்க்க",
    readmore: "மேலும் படிக்க",
    signup: "பதிவு",
    signupsub: "செய்தி கடிதங்கள் மற்றும் தினசரி புதுப்பிப்புகள்.",
    emails: "மின்னஞ்சல்",
    address: "முகவரி:",
    address1:
        "M/S  வயல் அக்ரோ டெக் (OPC) பிரைவேட் லிமிடெட், 28 NCN கார்டன், திருநகர் (முத்து மஹாலுக்கு எதிரில்) , கோபிசெட்டிபாளையம், ஈரோடு, தமிழ்நாடு.",
    all: "அனைத்தும்",
    category: "வகைகள்",
    buy: "வாங்க",
    sell: "விற்க",
    click: "இங்கே கிளிக் செய்யவும்",
    recentproduct: "சமீபத்திய விலை - பண்ணை பொருட்கள்",
    selectone: "ஒன்றை தேர்ந்தெடு",
    recentequip: "சமீபத்திய விலை - பண்ணை உபகரணங்கள்",
    allLabel: "அனைத்தும்",
    price: "விலை",
    createdon: "பதிவிட்ட நாள்",
    results: "முடிவுகள்",
    quantity: "அளவு",
    posted: "நாள்",
    user: "பயனர் வகை",
    marketplace: "சந்தை இடம்",
    contactno: "தொடர்பு எண்",
    business: "வணிகத்தின் பெயர்",
    sub: "துணைப்பிரிவு",
    clear: "அகற்று",
    producttag: "கால்நடைகள், பண்ணை மற்றும் புதிய பொருட்களுக்கான விளம்பரங்கள்!",
    equiptag: "விவசாயத்திற்கு தேவைப்படும் அனைத்து உபகரணங்கள்.",
    markettag: "பூக்கள், காய்கறிகள் மற்றும் இறைச்சி சந்தைக்கான தினசரி விலைகள்.",
    listdate: "தேதிகளின் பட்டியல்",
    defaultContent: "error",
    selectonemark: "ஒன்றை தேர்ந்தெடு ",
    selectdist: "மாவட்டங்கள்",
    selectCity:"நகரம் தேர்ந்தெடு",
    selectDist1:"மாவட்டம் தேர்ந்தெடு",
    selecttown: "நகரங்கள்",
    city: "நகரங்கள்",
    startdate: "தொடக்க தேதி",
    enddate: "கடைசி தேதி",
    location: "இடம்",
    locationFilter: "இடம் தேர்வு",
    only: "Only 30days of data you will get by Date.",
    newreccomendation: "புதிய பரிந்துரை",
    selectDC: "மாவட்டம் & நகரத்தைத் தேர்ந்தெடுக்கவும்",
    place: "இடம்",
    date: "தேதி",
    history: "விலை வரலாறு",
    recentlyadded: "சமீபத்தில் சேர்க்கப்பட்டவை",
    recentlyaddedtag: "",

    recentlyaddedtag_: "விவசாயத் துறையின் போக்குகளின் பட்டியல்",
    explore: "மேலும் காட்டு",
    promotedProds: "சிறப்பு தயாரிப்புகள்",
    promotedEquips: "சிறப்பு உபகரணங்கள்",
    viewall: "மேலும்",
    daily: "தினசரி",
    cp: "பெயர்:",
    itemid: "ஐட்டம் ஐடி",
    signuptag: "செய்தி கடிதங்கள் மற்றும் தினசரி அறிவிப்புகளுக்கு",
    tamilnadu: "தமிழ்நாடு",
    report: "அறிக்கை சிக்கல்",
    prnm: "விலை குறிப்பிடப்படவில்லை",
    pm: "குறிப்பிடப்படவில்லை",
    sp: "ஒத்த தயாரிப்புகள்",
    reportitem: "இந்த உருப்படியைப் புகாரளிக்கவும்",
    inpropriate: "பொருத்தமற்ற சுயவிவரப் படம்",
    Offensive: "புண்படுத்தும் உள்ளடக்கம்",
    fraud: "மோசடி",
    Livestocks: "கால்நடைகள்",
    reason: "அறிக்கைக்கான காரணம்",
    submit: "அனுப்பு",
    getsite: "தொடர்புடைய தளத்தில் மேலும் பெறவும்",
    useapp: "உங்கள் தயாரிப்பைக் காட்சிப்படுத்த வயல்அக்ரோ அப்ளிகேஷனைப் பயன்படுத்தவும்",
    getapp: "பிளே ஸ்டோரில் பயன்பாட்டைப் பெறுங்கள்",
    recentprice: "சமீபத்திய விலை",
    links: "இணைப்புகள்",
    delivery: "டெலிவரி கிடைக்கிறது",
    getcate: "வகைகள்",
    viewprice: "விலைகளைக் காண்க",
    hideexplore: "குறைவாக காட்டு",
    showmore: "மேலும் பார்க்க",
    historyPrice: "வரலாறு விலை",
    searchFor: "பொருட்கள் பட்டியலில் உள்ளன",
    footerDecs:
        "வயல்அக்ரோ - விவசாய விளம்பரங்கள், வாங்க, விற்க, தினசரி & வரலாறு சந்தை விலைகள், புதுப்பிப்புகள் மற்றும் செய்திகள்",
    history_of: "வரலாறு விலை",
    filer_category: "வகைகள் தேர்வு",
    agriculture_products: "வேளாண்மை பொருட்கள்",
    agriculture_equipments: "வேளாண்மை உபகரணங்கள்",
    livestocks: "கால்நடைகள்",
    today:"இன்றைய",
    todays: "இன்றைய",
    no_data_product:"உங்கள் பகுதியில் பொருள் தற்காலிகமாக கிடைக்கவில்லை",
    no_data_product_para:"மன்னிக்கவும், நீங்கள் தேடுகிற பொருள் உங்கள் நகரத்தில் தற்காலிகமாக கிடைக்கவில்லை. மாற்று பொருள்களை காண எங்கள் இணையதளத்தை பார்வையிடலாம் அல்லது முகப்புப் பக்கத்துக்கு திரும்பலாம்",
    no_data_market:"தேர்ந்தெடுக்கப்பட்ட தேதிக்கு சந்தை விலை கிடைக்கவில்லை",
    no_data_found:"மன்னிக்கவும், நீங்கள் தேர்ந்தெடுக்கப்பட்ட தேதி வடிப்பானுக்கான சந்தை விலை கிடைக்கவில்லை. தயவுசெய்து பிறகு பார்க்கவும். மாற்று சந்தை விலை பிரிவுகளை காண எங்கள் இணையதளத்தை பார்க்கலாம்.",
    deleteAccount: {
        title: "உள்நுழைய",
        acc: "கணக்கை நீக்கு",
        mobileNumber: "மொபைல் எண்",
        requestOtp: "OTP கேட்கவும்",
        enterOtp: "OTP உள்ளிடவும்",
        verifyOtp: "OTP சரிபார்க்கவும்",
        deleteAccountButton: "கணக்கை நீக்கு",
    },
    Cate_pro_des: "தினசரி பயன்பாடு மற்றும் மொத்த தேவைகளுக்கு புதிய பண்ணை பொருட்களை வாங்கவும்",
    Equp_pro_des: "சிறந்த விவசாயத்திற்கான தரமான பண்ணை இயந்திரங்கள் மற்றும் கருவிகளை வாங்கவும்.",
    Live_pro_des: "பண்ணைகள், பால் பண்ணைகள் மற்றும் வணிகத் தேவைகளுக்கு ஆரோக்கியமான கால்நடைகளைப் பெறுங்கள்.",
    Mp_pro_des: "ஸ்மார்ட் வாங்குதலுக்கான தினசரி சந்தை விலைகளுடன் புதுப்பித்த நிலையில் இருங்கள்.",
    Equip_des: "உங்கள் பகுதியில் உள்ள 100+ விற்பனையாளர்களிடமிருந்து சிறந்த விவசாயக் கருவிகள் மற்றும் இயந்திரங்களைக் கண்டறியவும். டிராக்டர்கள், உழவு இயந்திரங்கள், அறுவடை இயந்திரங்கள், தெளிப்பான்கள் மற்றும் பலவற்றை உங்கள் பண்ணைக்கு சரியான உபகரணங்களைப் பெறுவதற்கு ஒப்பிட்டுப் பாருங்கள். இப்போது விற்பனையாளர்களை ஆராய்ந்து இணைக்கவும்!",
    New_product:"புதிய தயாரிப்புகள்",
    jack_fruit: "பலா பழம்",
    pomogrante: "மாதுளை",
    apple: "புதியது! ஆப்பிள்",
    Allcategories: "அனைத்து வகைகள்",
    get_in: "தொடர்பு கொள்ளுங்கள்",
    table: "அட்டவணை",
    graph: "வரைபடம்"
};
