<template>
  <section class="popular-category-section">
    <div class="wrapper">
      <div class="main">
        <div  :class="{ 'categories_head-tamil-fonts': selectedLanguage === 'ta', 'categories_head_En': selectedLanguage !== 'ta' }">
          <h2 :class="{ 'popular-tamil-fonts': selectedLanguage === 'ta', 'popular-title': selectedLanguage !== 'ta' }">{{translations.PopulrProduct}}</h2>
        </div>
        <div class="categories">
          <div
            v-for="product in limitedData"
            :key="product.id"
            @click="triggerPost(product)"
            :class="{ active: selectedCategoryName === product.id }"   
          >
            <p :class="{ 'filter-tamil-fonts': selectedLanguage === 'ta', 'filter-title': selectedLanguage !== 'ta' }">
  {{ selectedLanguage === "en" ? product.name : product.tName }}
</p>
          </div>
          
        </div>
      </div>
      <div class="row" v-if="given_data.length > 0">
        <div v-if="isLoading" class="loader mt-4">
          <div class="row">
            <div
              v-for="product in 4"
              :key="product.id"
              class="col-lg-3 col-md-4 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
              <p class="shimmer-line"></p>
              <p class="shimmer-line"></p>
            </div>
          </div>
        </div>
        <div  v-else
          class="col-lg-3 col-md-4 col-sm-12"
          v-for="product in againlimited"
          :key="product.id"
        >
          <div class="product" @click="getProductLink(product)">
            <div class="img_div">
              <img :src="product.image" alt="" loading="lazy" />
            </div>
            <p
              :class="{ 'type_tamil': selectedLanguage === 'ta', 'type': selectedLanguage !== 'ta' }"
              :style="{
                backgroundColor:
                  product.type === 'Agency' ? '#B0C800' : 'lightskyblue',
              }"
            >
              {{ selectedLanguage === "en" ? product.type : product.tn_type }}
            </p>
            <div class="align">
              <p :class="{ 'sub_category_name_tamil': selectedLanguage === 'ta', 'sub_category_name': selectedLanguage !== 'ta' }">{{ selectedLanguage === "en" ? product.cateName : product.tn_Cate_name }}</p>
              <p class="product-name">{{ selectedLanguage === "en" ? product.name : product.tn_sub_name }}</p>
              <div class="date-location">
                <div>
                  <p :class="{ 'location_name_d_tamil': selectedLanguage === 'ta', 'loction-data_d': selectedLanguage !== 'ta' }">
                    <span
                      ><img
                        src="../assets/images/location-icon.svg"
                        alt=""
                        loading="lazy" /></span>
                    {{ selectedLanguage === "en" ? product.place : product.tn_place }},
                    {{ selectedLanguage === "en" ? product.district : product.tn_district }}
                  </p>
                </div>
                <div>
                  <p class="created">
                    {{
                      new Date(product.created).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })
                    }}
                  </p>
                </div>
              </div>

              <div class="price-view">
                <div>
                  <p :class="{ 'price_tamil': selectedLanguage === 'ta', 'price': selectedLanguage !== 'ta' }">
                    <span
                      ><i
                        class="fa-solid fa-indian-rupee-sign"
                        style="color: #018f43"
                      ></i
                    ></span>
                    {{ product.price }}/ 
                     {{ selectedLanguage === "en" ? product.quantity_type : product.quantity_type_tamil}}
                  </p>
                </div>
                <div>
                  <a >
                    <button :class="{ 'price_button_tamil': selectedLanguage === 'ta', 'price_button': selectedLanguage !== 'ta' }">{{translations.viewmore}}</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="isLoading" class="loader mt-4">
          <div class="row">
            <div
              v-for="product in 4"
              :key="product.id"
              class="col-lg-3 col-md-4 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
              <p class="shimmer-line"></p>
              <p class="shimmer-line"></p>
            </div>
          </div>
        </div>
        <div v-else>
        <div class="nodata-img" >
          <img
            class=""
            src="../assets/images/emptypro.webp"
            loading="lazy"
            alt="empty"
          />
        </div>
        <h2 class="my-3 no-data">
          <!-- {{ translations.no_data_market }}  -->
          Popular Product Not Available!!
        </h2>

        <p class="no-data-para">
          <!-- {{ translations.no_data_found }} -->
          We're sorry, Please check back later. You can browse our website to find
          alternative Products.
        </p>
         </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { getProductRoute } from "../components/product";
import { en, ta } from "../translations.js";

export default {
  name: "PopularComponent",
  props: {
    selectedLanguage: String,
  },
  data() {
    return {
      isLoading: false,
      againpayload: {
        categoryid: "",
      },
      category_data: [],
      given_data: [],
      product: [],
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    limitedData() {
      return this.category_data;
    },
    againlimited() {
      return this.given_data;
    },
  },
  mounted() {
    this.sendData(); // Fetch categories and trigger data fetch for "All"
  },
  methods: {
    async sendData() {
      this.isLoading = true;
      try {
        // Fetch categories
        const response = await axios.get("https://vaiyal-app.herokuapp.com/filtertype");
        const datas = response.data.data || [];
   

    this.category_data = [
      ...datas.map((item) => ({
        id: item.category_id || "N/A",
        name: item.category_name || "No Name",
        tName: item.tn_name || "No Name",
      })),
    ];

        // Trigger data fetch for the default category ("All")
        this.triggerPost(this.category_data[0]);
      } catch (error) {
        console.error("Error during POST request:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async triggerPost(category) {
      this.isLoading = true;
      this.selectedCategoryName = category.id;

      const payload =
        category.id === "All"
          ? { categoryid: "All" }
          : { categoryid: category.id };

      try {
        // Fetch products for the selected category
        const response = await axios.post(
          "https://vaiyal-app.herokuapp.com/filter",
          payload
        );
        const datas = response.data?.data || [];
        this.given_data = datas.map((item) => ({
          product_id: item.product_id || item.equip_product_id,
          cateName: item.category_name || "N/A",
          tn_Cate_name: item.tn_name || "N/A",
          image:
            item.images && item.images[0]
              ? item.images[0]
              : require("../assets/images/No-Data-list.webp"),
          name: item.sub_category_name || "No Name",
          tn_sub_name: item.tn_sub_name || "No Name",
          district: item.market_name || "Tamilnadu",
          tn_district: item.tn_market_name || "",
          place: item.place || "",
          tn_place: item.tn_place || "",
          created: item.created,
          price: item.price,
          quantity_type: item.quantity_type || "",
          quantity_type_tamil: item.quantity_type_tamil,
          type: item.user_type,
          tn_type: item.user_type_tamil,
          lists: item.list || "no lists",
          product: item.name,
          categoryType: item.category_type,
        }));
        console.log("given_data", this.given_data);
      } catch (error) {
        console.error("Error during POST request:", error);
      } finally {
        this.isLoading = false;
      }
    },
    getProductLink(product) {
      const route = this.getProductRoute(product, {
        location: product.district.replace(/\s+/g, "-").toLowerCase(),
        category: product.categoryType.replace(/\s+/g, "-").toLowerCase(),
        categoryName: product.cateName.replace(/\s+/g, "-").toLowerCase(),
        product:
          product.name.replace(/\s+/g, "-").toLowerCase() ||
          product.cateName.replace(/\s+/g, "-").toLowerCase(),
      });
      console.log("checking-route", route);
      window.open(this.$router.resolve(route).href, "_blank");
    },
    getProductRoute: getProductRoute,
  },
};
</script>

<style scoped>
@import url("/src/components/PopularComponent.scss");
</style>


