<template>
  <section class="privacypolicy-section category-page-section">
    <div class="wrapper">
           <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1 :class="{ 'tamil-title-h2': selectedLanguage === 'ta', 'title-h2': selectedLanguage !== 'ta' }">Privacy Policy</h1>
                <div class="address-section">
                  <img src="../assets/images/category-page-home-icon.svg" alt="" />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                   <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="">Privacy Policy</a></p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-3 col-md-3 col-sm-12 side-sticky order-2 order-lg-1">
          <NewproductComponent :selectedLanguage="selectedLanguage"/>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-12 order-1 order-lg-2">
          <div class="privacypolicy-main-div">
            <div class="welcomepage-div" v-for="(section, index) in privacySections" :key="index">
             <h4 class="privacy-side-heading">{{ section.title }}</h4>
             <p class="tempolicy" v-for="(paragraph, idx) in section.content" :key="idx" v-html="paragraph"></p>
           </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 bottom-ads">
        <AdsComponent :selectedLanguage="selectedLanguage"/>

    </div>

  </section>
</template>

<script>
import NewproductComponent from "./NewproductComponent.vue";
import AdsComponent from '../components/AdsComponent.vue';

export default {
  name: "PrivacyPolicy",
     props: {
    selectedLanguage: String
  },
  data() {
    return {
      privacySections: [
        {
          title: "Welcome to VayalAgro's Privacy Policy",
          content: [
            `The creator of this Privacy Policy ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across this Website. This privacy policy contains information about the Website.`,
            `To provide You with Our uninterrupted use of the Website, We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used.`,
         
          ],
        },
        {
          title: "Index",
          content: [
            `<ul> 
            <li>Personal Information</li>
            <li>Information We Collect</li>
            <li>Our Use Of Your Information</li>
             <li>How Information Is Collected</li>
             <li>External Links on The Platform</li>
             <li>Google Analytics</li>
             <li>Google Adsense</li>
              <li>Your Rights</li>
               <li>Compliances</li>
                <li>Confidentiality</li>
                 <li>Other Information Collectors</li>
                  <li>Our Disclosure of Your Information</li>
                   <li>Accessing, Reviewing and Changing Your Profile</li>
                    <li>Security</li>
                     <li>Amendment</li>
                      <li>Consent Withdrawal, Data Download & Data Removal Requests</li>
                      <li>Contact Us</li>
            </ul>`
          ],
        },
        {
          title: "INFORMATION WE COLLECT",
          content: [
             `We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:`,
             `<ul> 
            <li>Personal data such as, but not limited to, Name, Email Id, Mobile number, Password, Age, Address, etc;</li>
            <li>Camera device, media, gallery, GPS track, and Location history of the user, after relevant permissions being sought from the user’s mobile phone.</li>
            <li>Tracking Information such as, but not limited to, the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Platform or not), which URL you next go to (whether this URL is on the Platform or not), your computer or device browser information, and other information associated with your interaction with the Platform;</li>
             <li>Details of Platform usage for analytics.</li>
            </ul>`,
            `Apart from the above information that we collect from users, we do not collect any information related to the Financial Data or Personally Identifiable Information (PII) of the users. Therefore, we take no responsibility for any profit or losses incurred arising out of providing such information voluntarily on the Platform. You acknowledge that only you are responsible for the availability and safety of their financial data and PII related data, during the usage of the platform.`,
            `This privacy policy also applies to data we collect from users but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Website. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:`,
            `<ul>
              <li>To enable the provision of services opted for by you;</li>
             <li>To enable the viewing of content in your interest;</li> 
             <li>To communicate the necessary account and service-related information from time to time;  </li>
             <li>To allow you to receive quality customer care services and data Collection;   </li>
             <li>To comply with applicable laws, rules, and regulations;</li>
              </ul>
              `,
              `Where any service requested by You involves a third party, such information as is reasonably necessary by the Company to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Company may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the ‘unsubscribe’ button or through an email to be sent to <a href="mailto:support@vayalagro.com">support@vayalagro.com</a>. To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Website and opt-out of any non-essential communications from the Website.`,
              `Further, transacting over the internet has inherent risks which can only be avoided by following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.`
          ],
        },
        {
          title: "OUR USE OF YOUR INFORMATION",
          content: [
                `The information provided by you shall be used to provide and improve the service for you and all users.`,
                `<ul> 
                  <li>To provide you with services on your request. </li>
                  <li>For maintaining an internal record.</li>
                  <li>For enhancing the Services provided. </li>
                  <li>For maintaining record under the legal and statutory provisions.</li>
                  </ul>`,
                  `For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.
We use Your tracking information such as Location History, IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.
We use your camera device and media gallery, to enable you to take pictures of your marketplace item or product, and to post on the platform. <br> We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law. <br>  Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.`
          ],
        },
        {
          title: "HOW INFORMATION IS COLLECTED",
          content: [
           `<ul>
            <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever. </li>
            <li>We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned. </li>
            <li>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date. </li>
            <li>We do not intend to collect any information pertaining to a minor child below the age of 18 years. However, if you believe that we are in possession of such information, you can email us to remove the same at <a href="mailto:support@vayalagro.com">support@vayalagro.com</a>. We will remove such information immediately from our servers.
 </li>
            </ul>`
          ],
        },
         {
          title: "EXTERNAL LINKS ON THE PLATFORM",
          content: [
           `The Website may include advertisements, hyperlinks to other Websites. We have no control over any other Platforms or resources or contents available on these other Platforms, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/services or other materials on or available from such Website or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Websites. These external third-party Websites and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Website and review its privacy policy.`
          ],
        },
        {
          title: "GOOGLE ADSENSE",
          content: [
            `Google AdSense is a tool that allows Website publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google uses cookies and tracking technology to deliver ads personalized to a Website user/visitor. In this regard the following terms are specified to the Users:`,
           `<ul>
            <li>Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our Website or other Websites. </li>
            <li>Google's use of advertising cookies enables us and our partners to serve advertisements to you based on their visit to our Platform and/or other Websites on the Internet. </li>
            <li>You may opt-out of personalized advertising by visiting Ads Settings.</li>
            <li>All advertisements of third parties on our Website are for informative purposes only and neither the Website nor the Company guarantees or bears liability for the authenticity of the advertisements.</li>
            <li>At no point will the Company permit its competitors to advertise on the Website.  </li>
            <li>You may visit the links in the advertisements at your own risk or choose not to accept the cookies permitting third parties to display their advertisements. </li>
            </ul>`
          ],
        },
        {
          title: "YOUR RIGHTS",
          content: [
            `Unless subject to an exemption, you have the following rights concerning your data:`,
           `<ul>
            <li>The right to request a copy of your data which we hold about you. </li>
            <li>The right to request for any correction to any personal data if it is found to be inaccurate or out of date.</li>
            <li>The right to withdraw your consent to the processing at any time.</li>
            <li>The right to complain about a supervisory authority. </li>
            <li>The right to obtain information as to whether personal data is transferred to a third country or an international organization.</li>
              </ul>`,
              `Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in`
          ],
        },
        {
          title: `COMPLIANCES`,
          content: [
          ],
        },
        {
          title: `Indian Compliances`,
          content: [
            `<ul>
              <li>This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules thereunder as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.  </li>
              <li>This legal document is published in accordance with the provisions of Rule 3(1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011, and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional Website.  </li>
              </ul>`
          ],
        },
        {
          title: `GDPR Privacy Rights`,
          content: [
            `The General Data Protection Regulation or GDPR gives certain rights to individuals in relation to their personal data. Accordingly, we are happy to offer transparency and access controls to help Users take advantage of those rights. As available, except as limited under applicable law, the rights afforded to individuals are:`,
            `<ul>
              <li>Right of access - the right to be informed of, and request access to, the data we process about you.</li>
              <li>Right to rectification – the right to request that we amend or update your data where it is inaccurate or incomplete. </li>
              <li>Right to erasure - the right to request that we delete your data. </li>
              <li>Right to restrict - the right to request that we temporarily or permanently stop processing all or some of your personal data.  </li>
               <li>Right to object - the right to object to us processing your personal data on grounds relating to your particular situation. Along with the right to object to your personal data being processed for direct marketing purposes.</li>
                <li>Right to data portability - the right to request a copy of your personal data in electronic format and the right to transmit that personal data for use in another party's service.</li>
                <li>Right to object - the right to object to us processing your personal data on grounds relating to your particular situation. Along with the right to object to your personal data being processed for direct marketing purposes.</li>
              </ul>`
          ],
        },
        {
          title: `CONFIDENTIALITY`,
          content: [
            `You further acknowledge that the Website may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your email address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.`,
          ],
        },
        {
          title: `OTHER INFORMATION COLLECTORS`,
          content: [
            `Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Website or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of such third parties, you are subject to ask questions before you disclose your personal information to others.`,
          ],
        },
        {
          title: `OUR DISCLOSURE OF YOUR INFORMATION`,
          content: [
            `We may host surveys for survey creators for our Website who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses. Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force. Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your registered email address.`,
            `We may use third party sites and tools for the purpose of explaining and creating content. This should be treated as fair use of right and incase of any objection the concerned brand of company should speak to the Company before taking any legal objection/action or copyright strike or claim.`,
            `As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:`,
            `<ul> 
              <li>External Service Providers: There may be several services offered by external service providers that help you use our Website. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.  </li>
              <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud, and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.  </li>
              </ul>`
          ],
        },
        {
          title: `ACCESSING, REVIEWING AND CHANGING YOUR PROFILE`,
          content: [
            `You can review and change the information you submitted. An option for facilitating such change shall be present on the Website and such change shall be facilitated by the User. If you change any information, we may or may not keep track of your old information. We will not retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Such prior information shall be completely removed from our databases, including stored ‘back up’ systems. If you believe that any information we are holding on to you is incorrect or incomplete, or to remove your profile so that others cannot view it, the User needs to rectify, and promptly correct any such incorrect information by emailing us at <a href="mailto:support@vayalagro.com">support@vayalagro.com</a>`,
          ],
        },
        {
          title: `SECURITY`,
          content: [
            `We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed. However, as effective as encryption technology is, no security system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee that information you provide won’t be intercepted while being transmitted to the Company over the Internet.`,
          ],
        },
        {
          title: `SEVERABILITY`,
          content: [
            `Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.`,
          ],
        },
        {
          title: `AMENDMENT`,
          content: [
            `Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Website. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Website.`,
          ],
        },
        {
          title: `CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS`,
          content: [
            `To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to <a href="mailto:support@vayalagro.com">support@vayalagro.com</a> .`,
          ],
        },
        {
          title: `CONTACT US`,
          content: [
            `If you have any questions or concerns regarding this privacy policy, you should contact us by sending an email to <a href="mailto:support@vayalagro.com">support@vayalagro.com</a> . or by writing to us at:`,
          ],
        },
      ],
    };
  },
    components: {
    NewproductComponent,
        AdsComponent,

  },
};
</script>

<style>
@import url("/src/components/PrivacyPolicy.scss");
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/NewproductComponent.scss");

</style>