<template>
  <section class="popular-category-section daily-section">
    <div class="wrapper">
      <div class="main">
        <div :class="{ 'categories_head-tamil-fonts': selectedLanguage === 'ta', 'categories_head_En': selectedLanguage !== 'ta' }">
          <h2 :class="{ 'popular-tamil-fonts': selectedLanguage === 'ta', 'popular-title': selectedLanguage !== 'ta' }">{{translations.DailySells}}</h2>
        </div>
        <div class="categories">
          <!-- Tabs -->
         
          <div>
            <p
              @click="selectTab('recommended')"
              :style="
                selectedTab === 'recommended' ? activeStyle : defaultStyle
              "
              :class="{ 'filter-tamil-fonts': selectedLanguage === 'ta', 'filter-title': selectedLanguage !== 'ta' }"
            >
              {{translations.promotedProds}}
            </p>
          </div>
           <div>
            <p
              @click="selectTab('newlyAdded')"
              :style="selectedTab === 'newlyAdded' ? activeStyle : defaultStyle"
              :class="{ 'filter-tamil-fonts': selectedLanguage === 'ta', 'filter-title': selectedLanguage !== 'ta' }"
            >
              {{translations.recentlyadded}}
            </p>
          </div>
          <!-- Carousel Controls -->
          <div class="for_btn">
            <span @click="moveLeft" :class="{ disabled: currentIndex === 0 }">
              <i class="fa-solid fa-chevron-left" style="color: #ffffff"></i>
            </span>
            <span
              @click="moveRight"
              :class="{ disabled: currentIndex >= maxIndex }"
            >
              <i class="fa-solid fa-chevron-right" style="color: #ffffff"></i>
            </span>
          </div>
        </div>
      </div>
      <div v-if="category_data.length > 0">
        <!-- Loader -->
        <div v-if="isLoading" class="loader">
          <div class="row">
            <div
              v-for="data in 4"
              :key="data.id"
              class="col-lg-3 col-md-4 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
              <p class="shimmer-line"></p>
              <p class="shimmer-line"></p>
            </div>
          </div>
        </div>
        <!-- Product List -->
        <div
          class="row for_adjust"
          v-else
          :style="{
            transform: `translateX(-${currentIndex * (0 / itemsPerPage)}%)`,
          }"
        >
          <div
            class="col-lg-3 col-md-4 col-sm-12 for_col-adjust"
            v-for="data in displayedData"
            :key="data.id"
          >
            <div class="product"  @click="getProductLink(data)">
       
              <div class="img_div">
                <img
                  :src="
                    data.images[0] ||
                    require('../assets/images/No-Data-list.webp')
                  "
                  alt="Product Image"
                  loading="lazy"
                />
              </div>
              <p
                class="type"
                :style="{
                  backgroundColor:
                    data.user_type === 'Agency' ? '#B0C800' : 'lightskyblue',
                }"
              >
                {{ selectedLanguage === "en" ? data.user_type : data.user_type_tamil }}
              </p>

              <div class="align">
                <p :class="{ 'sub_category_name_tamil': selectedLanguage === 'ta', 'sub_category_name': selectedLanguage !== 'ta' }">
                  {{ selectedLanguage === "en" ? data.category_name : data.tn_cat_name}}
                  </p>
                <p class="product-name">
                  {{ data.name }} {{ data.business_name }}
                </p>
                <div class="date-location">
                  <div>
                    <p  :class="{ 'location_name_d_tamil': selectedLanguage === 'ta', 'loction-data_d': selectedLanguage !== 'ta' }">
                      <span>
                        <img
                          src="../assets/images/location-icon.svg"
                          alt=""
                          loading="lazy" /></span>
                          {{ selectedLanguage === "en" ? data.place : data.tn_place}}, {{ selectedLanguage === "en" ? data.market_name : data.tn_name}}
                    </p>
                  </div>
                  <div>
                    <p class="created">
                      {{
                        new Date(data.created).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        })
                      }}
                    </p>
                  </div>
                </div>
                <div class="price-view">
                  <div>
                    <p :class="{ 'price_tamil': selectedLanguage === 'ta', 'price': selectedLanguage !== 'ta' }">
                      <span
                        ><i
                          class="fa-solid fa-indian-rupee-sign"
                          style="color: #018f43"
                        ></i></span
                      >{{ data.price }}/ {{ selectedLanguage === "en" ? data.quantity_type : data.quantity_type_tamil}}
                    </p>
                  </div>
                  <div>
                    <button :class="{ 'price_button_tamil': selectedLanguage === 'ta', 'price_button': selectedLanguage !== 'ta' }">{{translations.viewmore}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="isLoading" class="loader">
          <div class="row">
            <div
              v-for="data in 4"
              :key="data.id"
              class="col-lg-3 col-md-4 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
              <p class="shimmer-line"></p>
              <p class="shimmer-line"></p>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="nodata-img">
            <img
              class=""
              src="../assets/images/emptypro.webp"
              loading="lazy"
              alt="empty"
            />
          </div>
          <h2 class="my-3 no-data">
            <!-- {{ translations.no_data_market }}  -->
            Daily Best Sells Product Not Available!!
          </h2>

          <p class="no-data-para">
            <!-- {{ translations.no_data_found }} -->
            We're sorry, Please check back later. You can browse our website to
            find alternative Products.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { getProductRoute } from "../components/product";
import { en, ta } from "../translations.js";

export default {
  name: "PopularComponent",
  props: {
    selectedLanguage: String
  },
  data() {
    return {
      currentIndex: 0, // Current position in the carousel
      itemsPerPage: 4, // Number of items visible at a time
      selectedTab: "recommended", // Tracks which tab is selected
      isLoading: false, // Tracks loading state
      category_data: [], // Data for "Newly Added"
      given_data: [], // Data for "Recommended"
      activeStyle: {
        color: "#018f43",
      },
      defaultStyle: {
        color: "#000000",
        fontWeight: "normal",
        textDecoration: "none",
      },
    };
  },
  computed: {
      translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    displayedData() {
      const currentData =
        this.selectedTab === "newlyAdded"
          ? this.category_data
          : this.given_data;

      const start = this.currentIndex * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return currentData.slice(start, end);
    },
    maxIndex() {
      const currentData =
        this.selectedTab === "newlyAdded"
          ? this.category_data
          : this.given_data;
      return Math.ceil(currentData.length / this.itemsPerPage) - 1;
    },
  },
   
  mounted() {
    this.fetchNewlyAdded();
      this.fetchRecommended(); // Fetch recommended data initially

  },
  methods: {
    async fetchNewlyAdded() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "https://vaiyal-app.herokuapp.com/get/recentlist?marketid=1"
        );
        this.category_data = response.data.data || [];
        console.log('category_data',this.category_data)
      } catch (error) {
        console.error("Error fetching Newly Added data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchRecommended() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          "https://vaiyal-app.herokuapp.com/get/recommendlist?type=equipment"
        );
        this.given_data = response.data?.data || [];
            console.log('given_data',this.given_data)
      } catch (error) {
        console.error("Error fetching Recommended data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    getProductLink(data) {
  const route = this.getProductRoute(data, {
    location: data.market_name?.replace(/\s+/g, "-").toLowerCase() || "unknown-location",
    category: data.category_type?.replace(/\s+/g, "-").toLowerCase() || "unknown-category",
    categoryName: data.category_name?.replace(/\s+/g, "-").toLowerCase() || "unknown-category-name",
    product: data.sub_category_name?.replace(/\s+/g, "-").toLowerCase() || data.category_name.replace(/\s+/g, "-").toLowerCase(),
  });
  console.log("Generated Route:", route);
  window.open(this.$router.resolve(route).href, "_blank");
},

  getProductRoute: getProductRoute,
    selectTab(tab) {
      if (this.selectedTab === tab) return;
      this.selectedTab = tab;
      this.currentIndex = 0; // Reset index when switching tabs
      if (tab === "recommended" && this.given_data.length === 0) {
        this.fetchRecommended();
      }
    },
    moveLeft() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    moveRight() {
      if (this.currentIndex < this.maxIndex) {
        this.currentIndex++;
      }
    },
  },
};
</script>

<style scoped>
@import url("/src/components/PopularComponent.scss");
</style>
