<template>
  <div id="app">
    <div v-if="!inMaintenance">
    <div class="p-0" v-if="showRun && isHomePage && isMarketPage">
  
    <div class="marquee">
      <p class="marquee_text">
        
          {{ runMessage }}
      </p>
    </div>
  </div>
    <HeaderComponent @language-changed="updateLanguage" />
<router-view :selectedLanguage="selectedLanguage"></router-view>
<FooterComponent :selectedLanguage="selectedLanguage"/>
</div>
 <div v-else>
    <MaintanacePage />
  </div>
  </div>
 
</template>

<script>

import FooterComponent from './components/FooterComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import axios from 'axios';
import MaintanacePage from './components/MaintanacePage.vue';

export default {
  name: 'App',
  data() {
    return {
      selectedLanguage: "en", // Default language
       showRun: false,
      runMessage: '',
      isHomePage: true,
      isMarketPage: true,
      inMaintenance: false,
    };
  },
  created(){
     this.checkMaintenanceStatus();
  },
    mounted() {
   
     this.fetchPopup();
   
  },
    watch: {
        '$route': function() {
      this.checkHomePage();
      this.checkMarketPage();
    },
    },
  methods: {
    updateLanguage(newLang) {
      this.selectedLanguage = newLang;
    },
     closePopup() {
        this.showRun = false;
      },
      fetchPopup() {
        // Fetch data from the API
        fetch('https://vaiyal-app.herokuapp.com/maintenance?type=running')
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success' && data.data.isactive) {
                              this.showRun = true;

                this.img = data.data.img;
                this.link = data.data.link;
                this.runMessage = data.data.msg;
                          console.log("running",this.runMessage)

            } else {
              console.log('Failed to fetch data:', data.msg);
            }
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },
      async checkMaintenanceStatus() {
      try {
        const response = await axios.get('https://vaiyal-app.herokuapp.com/maintenance?type=maintenance');
        this.inMaintenance = response.data.data.isactive === true;
      } catch (error) {
        console.error('Error fetching maintenance status:', error);
      }
    },
  checkHomePage() {
      this.isHomePage = this.$route.name === 'Home'
    },
    checkMarketPage() {
    this.isMarketPage = ['MarketPrice', 'pricesublist1', 'pricesublist1', 'AllRecent'].includes(this.$route.name);
},
  },
  components: {
    HeaderComponent,
    FooterComponent,
    MaintanacePage
  },
};

</script>

<style>
@import url("./assets/scss/style.scss");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.marquee {
  position: relative;
  font-family: 'Quicksand', sans-serif;;
  white-space: nowrap;
  overflow: hidden;
  height: 40px;
  margin: 0 auto;
  padding: 0.3rem 0 0.3rem 0;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    background-color: #f4b617;
}

.marquee_text {
  display: inline-block;
  padding-left: 100%;
  padding-top: 3px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: aliceblue;
  animation: marquee-right-left 20s linear infinite;
}

.marquee_text:hover {
  animation-play-state: paused;
}

.marquee_text:hover + .marquee_text {
  animation-play-state: paused;
}

.marquee_text:not(:hover) {
  animation-play-state: running;
}

@keyframes marquee-right-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
