<template>
  <section class="category-page-section">
    <div class="wrapper">
          <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner detail-title-banner">
              <div class="title-content" v-if="product">
                <h1  class="detail-head"  >
                  {{ product.business_name }}
                </h1>
                <div class="address-section">
                 
                  <p class="footer-add" v-if="product">
          <router-link :to=" `/${this.$route.params.location}/agriculture-equipments` "> {{ translations.farmEquipments }}</router-link>
                  </p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                
                  />

                  <p class="footer-add" v-if="product.sub_category_id != '0'">
                    <router-link   :to="{
                        name: 'SubcategoryPage',
                        params: {
                          categoryId: product.category_id,
                          categoryName: product.category_name
                            .replace(/\s/g, '-')
                            .toLowerCase(),
                          category:'agriculture-equipments',
                          location: this.$route.params.location,
                        },
                      }">
              {{ product.category_name  }}
            </router-link>
                  </p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                        v-if="product.sub_category_id != '0'"
                  />

                  <p class="footer-add">
                    <router-link :to="{ name: 'ListComponent', params: { categoryId: product.category_id, categoryName: product.category_name.replace(/\s/g, '-').toLowerCase(), category: 'agriculture-equipments', location: this.$route.params.location } }">
  {{ (product.sub_category_name.trim() !== '' ? product.sub_category_name : product.category_name) }}
</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12  mt-5">
        <div class="row just-align">
          <div class="col-lg-3 col-md-3 col-sm-12 order-2 order-lg-1" >
            <SidebarComponent :selectedLanguage="selectedLanguage" />
            <NewproductComponent :selectedLanguage="selectedLanguage" />

          </div>
        <div class="col-lg-9 col-md-9 col-sm-12 order-1 order-lg-2">
            <div v-if="product">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="image-section">
                  <div class="product-detail-image" >
                    <img
                    class="product-image"
                  v-if="product.images && product.images.length > 0"
                  :src="product.images[selectedImageIndex]"
                  :alt="`Proudct: ${product.name}`"
                  @click="openImage"
                  @error="handleGalleryImageError"
                />
                <img
                  class="p-0 m-0"
                  v-else
                  src="../assets/images/No-Data-list.webp"
                  :alt="`Proudct: ${product.name}`"
                />
                  </div>
                  <div class="slef-image-div">
                    <div
                      class="product-detail-sub-image"
                      v-for="(image, index) in product.images"
                    :key="index"
                    @click="selectImage(index)"
                    >
                      <img
                        class="product-image"
                        :src="image"
                      loading="lazy"
                      :alt="`Proudct: ${product.name}`"
                      @error="handleThumbnailError"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="information-section">
                  <p v-if="product.user_type !== undefined && product.user_type !== ''"  class="detail-type">{{ selectedLanguage === "en" ?  product.user_type : product.user_type_tamil }}</p>
                  <p v-else class="detail-type"> Not mentioned</p>
                  <h2 class="product-name">{{ product.business_name }}</h2>
                  <p class="category-type mt-3">{{translations.category}}: <router-link  :to="{ name: 'ListComponent', params: { categoryId: product.category_id, categoryName: product.category_name.replace(/\s/g, '-').toLowerCase(), category: 'agriculture-equipments', location: this.$route.params.location } }"> {{ selectedLanguage === "en"
                        ? product.sub_category_name.trim() !== ""
                          ? product.sub_category_name
                          : product.category_name
                        : product.tn_sub_name.trim() !== ""
                        ? product.tn_sub_name
                        : product.category_name }} </router-link></p>
                  <h4 class="product-price" v-if="product.price !== '0' && product.price !== '00' && product.price !== ''">₹{{ product.price }}/{{ selectedLanguage === "en" ? product.quantity_type : product.quantity_type_tamil}}</h4>
                  <h4 class="product-price-not" v-else>Price Not Mentioned</h4>

                  <div class="contact-info-div">
                    <div>
                      <img
                        src="../assets/images/detail-component/profile.svg"
                        alt="profile"
                      />
                    </div>
                    <div class="contact-info">
                      <p v-if="product.sellername">{{ product.sellername }}</p>
                      <p v-else>Not Mentioned</p>
                      <p>+91 {{ product.phone_number }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-3" v-if="product.delivery">
                      <div class="delivery-div" >
                      <p class="delivery">
                        <img src="../assets/images/detail-component/delivery.svg" alt="" />
                        {{ translations.delivery }}
                      </p>
                    </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-6 col-sm-3">
                      <div class="report-div"  @click="openForm">
                        <p class="report">
                          <img src="../assets/images/detail-component/report.svg" alt="" /> {{ translations.report }}
                        </p>
                      </div>
                    </div> -->
                    
                  </div>
                   <!-- <div>
                    <div v-if="showForm" class="modal">
                      <div class="modal-content">
                        <button class="close-button" @click="closeForm">
                          <i class="fa-solid fa-x"></i>
                        </button>
                        <h6>{{ translations.reportitem }}</h6>
                        <form @submit.prevent="submitForm">
                          <div
                            v-for="reportType in reportTypes"
                            :key="reportType.id"
                          >
                            <input
                              type="radio"
                              :id="'reportType' + reportType.id"
                              :value="reportType.id"
                              v-model="selectedReportType"
                             :required="!selectedReportType"
                            />
                            <label
                              :for="'reportType' + reportType.id"
                              class="ms-2"
                            >
                              {{
                                selectedLanguage === "en"
                                  ? reportType.report
                                  : reportType.report_tamil
                              }}
                            </label>
                          </div>
                          <div>
                            <label for="additionalDetailsTextarea" class="ms-2"
                              >Additional Details:</label
                            >
                            <br />
                            <textarea
                              id="additionalDetailsTextarea"
                              v-model="formData.additionalDetails"
                              class="w-100"
                              style="min-height: 50px; max-height: 150px"
                            :required="!selectedReportType"
                            ></textarea>
                          </div>
                          <button class="submit mt-3 mb-2" type="submit">
                            {{ translations.submit }}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div> -->
                   
                  <div class="item-info-div">
                    <div class="info-img-div">
                      <img
                        src="../assets/images/detail-component/info.svg"
                        alt="profile"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <p>Item Id</p>
                      <p class="info">{{ product.uniqueId }}</p>
                    </div>
                    <div class="info-img-div">
                      <img
                        src="../assets/images/detail-component/info.svg"
                        alt="profile"
                        loading="lazy"
                      />
                    </div>
                    <div>
                      <p>Item Created</p>
                      <p class="info">{{ formatDate(product.created) }}</p>
                    </div>
                  </div>
                  <div v-if="product.oraddress && Object.keys(product.oraddress).length > 0" class="address-div" >
                    <div class="address-img-div">
                      <img
                        src="../assets/images/detail-component/location.svg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p>
                      {{ product.oraddress.doorNo }} {{ product.oraddress.areaStreet }}
                      {{ product.oraddress.pincode }} {{ product.oraddress.townCity }}
                      {{ product.oraddress.landmark }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row ">
            <div class="col-lg-12">
              <div class="review-div">
    <div class="review-heading-div">
      <p
        class="review-heading"
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === tab.name }"
        @click="setActiveTab(tab.name)"
      >
        {{ tab.label }}
      </p>
    </div>
    <div class="review-content-div">
      <p class="content" v-if="activeTab === 'description'">
         {{ product.descriptions }}
      </p>
      <!-- <p class="content" v-if="activeTab === 'additionalInfo'">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita
        possimus tenetur consequatur fugiat pariatur, temporibus eos quae optio
        debitis velit dignissimos nisi dolore veritatis voluptatem.
      </p>
      <p class="content" v-if="activeTab === 'vendor'">
        Vendor Information: This is where vendor details will be displayed.
      </p> -->
      <!-- <p class="content" v-if="activeTab === 'reviews'">
        Reviews: Users' reviews will be displayed here.
      </p> -->
    </div>
  </div>
            </div>
          </div>
       <div class="row">
              <div class="col-lg-12 mb-4" >
                  <div  :class="{ 'categories_head-tamil-fonts': selectedLanguage === 'ta', 'categories_head': selectedLanguage !== 'ta' }">
                    <h3 :class="{ 'popular-tamil-fonts': selectedLanguage === 'ta', 'popular-title': selectedLanguage !== 'ta' }">{{translations.PopulrProduct}}</h3>
                  </div>
                  <div class="for_detail-btn">
                   <span @click="scrollLeft">
                     <i class="fa-solid fa-chevron-left" style="color: #ffffff"></i>
                   </span>
                   <span @click="scrollRight">
                     <i class="fa-solid fa-chevron-right" style="color: #ffffff"></i>
                   </span>
                 </div>
                <div class="row" v-if="productList.length > 0">
                  <div v-if="isLoading" class="loader mt-4">
                    <div class="row">
                      <div
                        v-for="product in 4"
                        :key="product.id"
                        class="col-lg-3 col-md-4 col-sm-12"
                      >
                        <p class="shimmer-line-img"></p>
                        <p class="shimmer-line"></p>
                        <p class="shimmer-line"></p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="side-product-container" ref="scrollContainer">
                  <div 
                    class="col-lg-4 col-md-4 col-sm-12"
                    v-for="product in productList.slice(0, 8)"
                    :key="product.product_id"
                  >
                  <router-link :to="getProductRoute(product)" target="_blank">
                    <div class="product" >
                      <div class="img_div">
                        <img
                          :src="getProductImage(product)"
                          :alt="`${product.category_name}-${
                            product.product_id || product.equip_product_id
                          }`"
                          @error="handleImageError"
                        />
                      </div>
                      <p
                        :class="{ 'type_tamil': selectedLanguage === 'ta', 'type': selectedLanguage !== 'ta' }"
                        :style="{
                          backgroundColor:
                            product.type === 'Agency'
                              ? '#B0C800'
                              : 'lightskyblue',
                        }"
                      >
                        {{
                          selectedLanguage === "en"
                            ? product.user_type
                      : product.user_type_tamil
                        }}
                      </p>
                      <div class="align">
                        <p
                          :class="{
                            sub_category_name_tamil: selectedLanguage === 'ta',
                            sub_category_name: selectedLanguage !== 'ta',
                          }"
                        >
                          {{
                            selectedLanguage === "en"
                              ? product.cateName
                              : product.tn_Cate_name
                          }}
                        </p>
                        <p class="product-name">
                          {{
                            product.business_name 
                          }}
                        </p>
                        <div class="date-location">
                          <div>
                            <p
                              :class="{
                                'location_name_d_tamil': selectedLanguage === 'ta',
                                'loction-data_d': selectedLanguage !== 'ta',
                              }"
                            >
                              <span
                                ><img
                                  src="../assets/images/location-icon.svg"
                                  alt=""
                                  loading="lazy"
                              /></span>
                              {{
                                selectedLanguage === "en"
                                  ? product.place
                                  : product.tn_place
                              }},
                              {{
                                selectedLanguage === "en"
                                  ? product.market_name
                                  : product.tn_name
                              }}
                            </p>
                          </div>
                          <div>
                            <p class="created">
                              {{
                                new Date(product.created).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "2-digit",
                                  }
                                )
                              }}
                            </p>
                          </div>
                        </div>

                        <div class="price-view">
                          <div>
                            <p
                              :class="{
                                price_tamil: selectedLanguage === 'ta',
                                price: selectedLanguage !== 'ta',
                              }"
                            >
                              <span
                                ><i
                                  class="fa-solid fa-indian-rupee-sign"
                                  style="color: #018f43"
                                ></i
                              ></span>
                              {{ product.price }}/
                              {{
                                selectedLanguage === "en"
                                  ? product.quantity_type
                                  : product.quantity_type_tamil
                              }}
                            </p>
                          </div>
                          <div>
                            <a>
                              <button
                                :class="{
                                  price_button_tamil: selectedLanguage === 'ta',
                                  price_button: selectedLanguage !== 'ta',
                                }"
                              >
                                {{ translations.viewmore }}
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  </div>
                  </div>
                </div>
                <div v-else>
                  <div v-if="isLoading" class="loader mt-4">
                    <div class="row">
                      <div
                        v-for="product in 4"
                        :key="product.id"
                        class="col-lg-3 col-md-4 col-sm-12"
                      >
                        <p class="shimmer-line-img"></p>
                        <p class="shimmer-line"></p>
                        <p class="shimmer-line"></p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                  <div class="nodata-img">
                    <img
                      class=""
                      src="../assets/images/emptypro.webp"
                      loading="lazy"
                      alt="empty"
                    />
                  </div>
                  <h2 class="my-3 no-data">
                    <!-- {{ translations.no_data_market }}  -->
                    Popular Product Not Available!!
                  </h2>

                  <p class="no-data-para">
                    <!-- {{ translations.no_data_found }} -->
                    We're sorry, Please check back later. You can browse our
                    website to find alternative Products.
                  </p>
                  </div>
                </div>
                  <div class="col-lg-12">
        <div class="detail-page-ad-div">
            <Adsense
    data-ad-client="ca-pub-1763856178878021"
    data-ad-slot="6910003215">
</Adsense>
        </div>
        </div>
              </div>
            </div>
            </div>
          <div v-else-if="isLoading" class="loading-spinner">
          <div class="row">
            <div class="col-lg-6">
               <p class="shimmer-line" style="height: 300px"></p>
                <div class="row d-flex">
                <div class="col my-2 mx-1">
                  <p class="shimmer-line" style="height: 50px"></p>
                </div>
                <div class="col my-2 mx-1">
                  <p class="shimmer-line" style="height: 50px"></p>
                </div>
                <div class="col my-2 mx-1">
                  <p class="shimmer-line" style="height: 50px"></p>
                </div>
                <div class="col my-2 mx-1">
                  <p class="shimmer-line" style="height: 50px"></p>
                </div>
                <div class="col my-2 mx-1">
                  <p class="shimmer-line" style="height: 50px"></p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
                <p class="shimmer-line my-2" style="height: 50px"></p>
              <p class="shimmer-line" style="height: 100px"></p>
              <p class="shimmer-line my-2" style="height: 20px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line my-2" style="height: 20px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
            </div>
         
            <div class="col-sm-12">
              <p class="shimmer-line my-2" style="height: 50px"></p>
              <p class="shimmer-line" style="height: 100px"></p>
              <p class="shimmer-line my-2" style="height: 20px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
              <p class="shimmer-line my-2" style="height: 20px"></p>
              <p class="shimmer-line" style="height: 40px"></p>
            </div>
          </div>
        </div>
          </div>
          
        </div>
      </div>
            <div class="col-lg-12 col-md-12 col-sm-12  mt-5">
            </div>
    </div>
    <!-- Full-Screen Image Modal -->
      <div>
          <div v-if="showImage" class="modal">
            <div class="modal-content">
              <button class="close-button" @click="closeImage">
                <i class="fa-solid fa-x"></i>
              </button>
              <div class="showimg">
                <img
                  v-if="selectedImageIndex !== null"
                  :src="product.images[selectedImageIndex]"
                  alt="Selected Product Image"
                  class="selected-image"
                />
                <button
                  v-if="selectedImageIndex > 0"
                  @click="changeImage(-1)"
                  class="prev-button"
                >
                  &#10094;
                </button>
                <button
                  v-if="selectedImageIndex < product.images.length - 1"
                  @click="changeImage(1)"
                  class="next-button"
                >
                  &#10095;
                </button>
              </div>
            </div>
          </div>
        </div>
  </section>
</template>

<script>
import axios from "axios";

import SidebarComponent from "./SidebarComponent.vue";
import NewproductComponent from './NewproductComponent.vue';

import { en, ta } from "../translations.js";
import { getProductRoute } from "./product";
export default {
  components: {
    // BottomAd
    SidebarComponent,
    NewproductComponent
  },
     props: {
    selectedLanguage: String
  },
  data() {
    return {
      productList: [],
      currentPage: 1,
      product: null,
      isLoading: true, // Set isLoading to true
      isImagePopupOpen: false,
      selectedImageIndex: 0, // Index of the clicked image
      showForm: false,
      showImage: false,
      showDeliveryMessage: false,
      formData: {
        name: "",
        selectedOption: null,
      },
      reportTypes: [],
      selectedReportType: null,
      activeTab: "description", // Default active tab
      tabs: [
        { name: "description", label: "Description" },
       // { name: "additionalInfo", label: "Additional Info" },
       // { name: "vendor", label: "Vendor" },
        // { name: "reviews", label: "Reviews (3)" },
      ],
    };
  },

  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  watch: {
    "$route.params.id": function (newId, oldId) {
      // This function will be called whenever the 'id' parameter in the URL changes
      console.log("ID changed. New ID:", newId, "Old ID:", oldId);

      // You can perform actions or fetch data based on the new ID here
      this.fetchProductDetails(newId);
      this.productList = [];
      this.fetchData();
    },
     showImage(newValue) {
      if (newValue) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  mounted() {
    // Fetch data when the component is mounted
    this.fetchDatas();
  },
  methods: {
     scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -350, behavior: "smooth" });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 350, behavior: "smooth" });
    },
      setActiveTab(tabName) {
      this.activeTab = tabName;
    },
    changeImage(direction) {
      const newIndex = this.selectedImageIndex + direction;
      if (newIndex >= 0 && newIndex < this.product.images.length) {
        this.selectedImageIndex = newIndex;
      }
    },
    getProductRoute: getProductRoute,
    navigateToAllList() {
      delete this.$route.params.id;
      this.$router.push({
        name: "LiveComponent",
        params: {
          ...this.$route.params,
        },
      });
    },

    fetchDatas() {
      // Fetch data from the API endpoint
      fetch("https://vaiyal-app.herokuapp.com/reporttypes")
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            this.reportTypes = data.data;
          } else {
            console.error("Failed to fetch data:", data.msg);
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    },
    getProductImage(product) {
      if (
        product.images &&
        product.images.length > 0 &&
        typeof product.images[0] === "string" &&
        product.images[0].trim() !== ""
      ) {
        return product.images[0];
      } else {
        return require("../assets/images/No-Data-list.webp");
      }
    },
    handleImageError(event) {
      event.target.src = require("../assets/images/No-Data-list.webp");
    },
    handleGalleryImageError(event) {
      event.target.src = require("../assets/images/No-Data-list.webp");
    },
    handleThumbnailError(event) {
      event.target.src = require("../assets/images/No-Data-list.webp");
    },
    openForm() {
      this.showForm = true;
        document.body.style.overflow = "hidden";
    },
    closeForm() {
      this.showForm = false;
           document.body.style.overflow = "auto";
    },
    openImage() {
      this.showImage = true;
    },
    closeImage() {
      this.showImage = false;
    },
    submitForm() {
      const selectedReportType = this.selectedReportType || "0"; // Use "0" if no radio option is selected

      // Extract relevant information from the product data
      const productId = this.product.equip_product_id;
      const categoryid = this.product.category_id;
      const subcategoryid = this.product.sub_category_id || "0";

      // Create the payload for the API request
      const requestBody = {
        created: this.formatDate(new Date()), // Assuming you want the current date
        type: "equipments",
        id: productId,
        categoryid: categoryid,
        subcategoryid: subcategoryid,
        userid: "0",
        report_type_id: selectedReportType,
        others: this.formData.additionalDetails,
      };
      // console.log(requestBody);
      // Include the additional details if provided
      if (this.formData.name) {
        requestBody.additionalDetails = this.formData.name;
      }

      // Send a POST request to the API endpoint
      axios
        .post("https://vaiyal-app.herokuapp.com/createreports", requestBody)
        .then((response) => {
          console.log("Report submitted successfully:", response.data);
          // Display a success message
          this.showSuccessMessage();
          // Close the form after successful submission
          this.showForm = false;
          // Reset the form data
          this.formData = {};

          // Reset the form data
          this.selectedReportType = null; // Reset the selected radio option
          this.formData.additionalDetails = ""; // Reset the textarea
          // If you have other form fields, reset them here
        })
        .catch((error) => {
          console.error("Error submitting report:", error);
        });
    },
    async fetchData() {
      try {
        const product = this.$route.params.product.replace(/-/g, " ");
        const response = await axios.post(
          `https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`
          // ` https://vaiyal-app.herokuapp.com/get/productlist?page=${this.currentPage}&term=${product}`
        );

        // Similar products
        response.data.data.map((item) => {
          if (item?.equip_product_id !== this.product?.equip_product_id)
            this.productList.push(item);
        });
        console.log("product list:", this.productList);
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching product list:", error);
      }
    },
    showSuccessMessage() {
      // You can customize the success message display according to your UI/UX
      alert("Report submitted successfully!");
      // Alternatively, you can set a property to control the display in your template
      // this.successMessage = "Report submitted successfully!";
    },
    truncatetitle(name) {
      if (name.length > 22) {
        return name.substring(0, 18) + "...";
      }
      return name;
    },
    goBack() {
      this.$router.go(-1);
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
//      getProductRoute(product) {
//       if (product.product_id) {
//         return { name: 'DetailComponent', params: { id: product.product_id, product: product.sub_category_name.replace(/\s+/g, '-'),        productName: product.name.replace(/\s+/g, '-')
//  } };
//       } else if (product.equip_product_id) {
//         return { name: 'EquipDetail', params: { id: product.equip_product_id, product: product.sub_category_name.replace(/\s+/g, '-'),        businessName: product.business_name.replace(/\s+/g, '-')
//  } };
//       }
//       // Handle the case where neither product_id nor equip_product_id is available.
//       return { name: 'fallbackRoute' };
//     },
    formatDate(date) {
      const dateObject = new Date(date);
      const day = String(dateObject.getDate()).padStart(2, "0");
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const year = dateObject.getFullYear();
      return `${day}-${month}-${year}`;
    },
    async fetchProductDetails(productId) {
      try {
        // const response = await axios.get(`https://vaiyal-app.herokuapp.com/product/${productId}`);
        const response = await axios.get(`https://vaiyal-app.herokuapp.com/equipproduct/${productId}`);
        this.product = response.data.data;
        console.log("equipmwnt", this.product);
         const equipUnique = this.product.equip_product_id; 
          if (!equipUnique || equipUnique === "") {
      this.$router.push({ name: "Error" });
      return; // Prevent further code execution
    }
        this.subCategoryName = this.product?.sub_category_name; // Extract sub-category name
const apiCategoryName = this.product.category_name;
    const currentCategoryName = this.$route.params.categoryName;

    const apiCategoryType = "agriculture-equipments";
    const currentCategoryType = 
      this.$route.params.category === 'agriculture-products' ? 'Agriculture Products' :
      this.$route.params.category === 'agriculture-equipments' ? 'Agriculture Equipments' :
      this.$route.params.category === 'live stocks' ? 'Livestocks' :
      this.$route.params.category; 

    const apiSubcategoryName = this.product.sub_category_name;
    const currentSubcategoryName = this.$route.params.product;

    const apiLocation = this.product.market_name;
    const currentLocation = this.$route.params.location;

//     const apiSeller = this.product.seller;
//     const currentSeller = this.$route.params.seller;
// console.log("apiSeller",apiSeller);
    let needsRouteUpdate = false;
    let newRouteParams = { ...this.$route.params }; 

    if (apiCategoryName && apiCategoryName !== currentCategoryName) {
      newRouteParams.categoryName = apiCategoryName.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }

    if (apiCategoryType && apiCategoryType !== currentCategoryType) {
      newRouteParams.category = apiCategoryType === "live stocks" ? "livestocks" : apiCategoryType.replace("farm", "agriculture").replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    if (apiSubcategoryName && apiSubcategoryName !== currentSubcategoryName) {
      newRouteParams.product = apiSubcategoryName.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    if (apiLocation && apiLocation !== currentLocation) {
      newRouteParams.location = apiLocation.replace(/\s+/g, '-').toLowerCase();
      needsRouteUpdate = true;
    }
    // if (apiSeller && apiSeller !== currentSeller) {
    //   newRouteParams.seller = apiSeller.replace(/\s+/g, '-').toLowerCase();
    //   needsRouteUpdate = true;
    // }
    // If any update is needed, replace the current route params
    if (needsRouteUpdate) {
      this.$router.replace({ ...this.$route, params: newRouteParams });
    }

        console.log("Product response:", this.product);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
      this.isLoading = false;
    },
  },
  created() {
    this.fetchData();

    const productId = this.$route.params.id;
    this.fetchProductDetails(productId);
  },
};
</script>




<style>
@import url("/src/components/DetailComponent.scss");
@import url("/src/components/CategoryPage.scss");
</style>