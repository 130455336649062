<template>
  <section class="product-category-section">
    <div class="wrapper">
      <div class="container">
        <h2 :class="{ 'category-tamil-fonts': selectedLanguage === 'ta', 'category-title': selectedLanguage !== 'ta' }">{{ translations.broeseCate }}</h2>
      </div>
      <div class="col-lg-12">
        <div v-if="isLoading" class="loader">
          <div class="row">
            <div
              v-for="product in 6"
              :key="product.id"
              class="col-lg-2 col-md-2 col-sm-12"
            >
              <p class="shimmer-line-img"></p>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="category_data.length === 0">
            <div class="nodata-img">
              <img
                class=""
                src="../assets/images/emptypro.webp"
                loading="lazy"
                alt="empty"
              />
            </div>
            <h2 class="my-3 no-data">
              {{ translations.no_data_market || 'Product Category Not Available for here!!' }}
            </h2>
            <p class="no-data-para">
              {{ translations.no_data_found || "We're sorry, Please check back later. You can browse our website to find alternative Category." }}
            </p>
          </div>
          <div v-else class="row">
            <div v-for="(item) in displayedProducts" :key="item.id" class="col-lg-2 col-md-3 col-6">
              <router-link
                  :to="
                    item.hasSubCategory
                      ? {
                          name: 'SubcategoryPage',
                          params: {
                            categoryId: item.category_id,
                            categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                            category:
                              item.category_type === 'Live stocks'
                                ? 'livestocks'
                                : item.category_type
                                    .replace(/\s/g, '-')
                                    .replace('farm', 'agriculture'),
                            location: 'tamilnadu',
                          },
                        }
                      : {
                          name: 'ListComponent',
                          params: {
                            product: item.name.replace(/\s/g, '-').toLowerCase(),
                            location: 'tamilnadu',
                            category:
                              item.category_type === 'Live stocks'
                                ? 'livestocks'
                                : item.category_type
                                    .replace(/\s/g, '-')
                                    .replace('farm', 'agriculture'),
                          },
                        }
                  "
                  :title="`Category: ${item.name}`"
                >
              <div class="product-item">
                  <div class="img-div">
                    <img :src="item.image" alt="Product Image">
                  </div>
                  <div class="head-pro-title">
                  <p :class="{ 'tamil-product-title': selectedLanguage === 'ta', 'category-product-title-pr': selectedLanguage !== 'ta' }">
                    {{ selectedLanguage === "en" ? item.name : item.tn_name }}
                  </p>
                  </div>
            
              </div>
                  </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showToggleButton" :class="{ 'tamil-toggle-button': selectedLanguage === 'ta', 'toggle-button': selectedLanguage !== 'ta' }">
        <button @click="toggleShowMore">
          {{ isAllProductsShown ? translations.hideexplore : translations.viewmore }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { en, ta } from "../translations.js";

export default {
  name: "ProductComponent",
   props: {
    selectedLanguage: String
  },
  data() {
    return {
      payload: {
        categorytype: "farm products",
      },
      category_data: [],
      initialBatchCount: 6, 
      additionalBatchCount: 6,
      currentBatchMobile: 1, 
      currentBatchDesktop: 1, 
      isMobileView: window.innerWidth <= 600, 
      isLoading: true,
    };
  },
  created() {
    
  },
  computed: {
     translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
    displayedProducts() {
      if (this.isMobileView) {
        const totalToShow = this.currentBatchMobile * this.additionalBatchCount;
        return this.category_data.slice(0, totalToShow);
      }
      return this.category_data.slice(0, this.currentBatchDesktop * 12);
    },
    showToggleButton() {
      if (this.isMobileView) {
        return this.category_data.length > this.initialBatchCount;
      }
      return this.category_data.length > 12;
    },
    isAllProductsShown() {
      if (this.isMobileView) {
        return this.currentBatchMobile * this.additionalBatchCount >= this.category_data.length;
      }
      return this.currentBatchDesktop * 12 >= this.category_data.length;
    },
  },
  methods: {
    async sendData() {
      this.isLoading = true; 
      try {
        const response = await axios.post(
          "https://vaiyal-app.herokuapp.com/categorytype",
          this.payload
        );
        const datas = response.data?.data || [];
        this.category_data = datas.map((item) => ({
          id: item.category_id || "N/A",
          image: item.images[0] || "N/A",
          name: item.category_name || "No Name",
          tn_name: item.tn_name || "No Name",
          hasSubCategory: item.hasSubCategory || false,
          category_name: item.category_name,
          category_type: item.category_type
        }));
        console.log("this.category_data", datas);
      } catch (error) {
        console.error("Error during POST request:", error);
        this.category_data = [];
      } finally {
        this.isLoading = false;
      }
    },
    toggleShowMore() {
  if (this.isMobileView) {
    if (this.isAllProductsShown) {
      this.currentBatchMobile = 1;
      this.$nextTick(() => {
        this.scrollToHeader();
      });
    } else {
      this.currentBatchMobile++;
    }
  } else {
    if (this.isAllProductsShown) {
      this.currentBatchDesktop = 1;
      this.$nextTick(() => {
        this.scrollToHeader();
      });
    } else {
      this.currentBatchDesktop++;
    }
  }
},
scrollToHeader() {
  const headerElement = document.querySelector(".category-title") || document.querySelector(".category-tamil-fonts");
  if (headerElement) {
    headerElement.scrollIntoView({ behavior: "smooth", block: "center" });
  }
},
    handleResize() {
      this.isMobileView = window.innerWidth <= 600;
    },
  },
  mounted() {
    this.sendData();
    this.handleResize();
    window.addEventListener("resize", this.handleResize); 
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    
  },
};
</script>

<style scoped>
@import url("/src/components/ProductComponent.scss");
</style>