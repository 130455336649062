<template>
  <section class="side-category-section">
    <!-- Show side-category only on List Page or other pages -->
    <div class="row" v-if="!isDetailPage">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="side-category">
          <h3 class="category-name">{{ translations.New_product }}</h3>
          <div class="side-product-list">
            <div class="side-product-image-div">
              <img class="side-product-image" src="../assets/images/jack-fruit.svg" alt="Product Image" loading="lazy">
            </div>
            <div class="side-product-name-div">
              <h4>{{ translations.jack_fruit }}</h4>
            </div>
          </div>
          <div class="side-product-list">
            <div class="side-product-image-div">
              <img class="side-product-image" src="../assets/images/pomegranate.svg" alt="Product Image" loading="lazy">
            </div>
            <div class="side-product-name-div">
              <h4>{{ translations.pomogrante }}</h4>
            </div>
          </div>
          <div class="side-product-list">
            <div class="side-product-image-div">
              <img class="side-product-image" src="../assets/images/apple.svg" alt="Product Image" loading="lazy">
            </div>
            <div class="side-product-name-div">
              <h4>{{ translations.apple }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Show side-app only on Detail Page or other pages -->
    <div class="row mb-3" v-if="!isListPage">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="side-app">
          <p class="try-text">Try Out Our</p>
          <div class="header-logo">
            <a href="" class="d-flex align-items-center">
              <div class="app-div"><img src="../assets/images/vayal-new-logo.png" alt=""></div>
              <p>VAYAL<span>AGRO</span>&#8482;</p>
            </a>
          </div>
          <p class="new-mbl-app">New Mobile App</p>
          <div class="round-img">
            <img src="../assets/images/Ellipse.svg" alt="" class="ellopse-img">
          </div>
          <img src="../assets/images/vertical-app.svg" alt="" class="app-use">
          <a href="https://play.google.com/store/apps/details?id=com.vaiyal.vaiyalapp&referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton_click%26utm_term%3Dadditems%26utm_content%3Dadditem%26utm_campaign%3Dvayalwebvisitors" target="_blank">
          <button>
            Download Now
          </button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { en, ta } from "../translations.js";
export default {
   props: ["selectedLanguage"],
  name: "NewproductComponent",
  data() {
    return {
      isListPage: false,
      isDetailPage: false,
    };
  },
  created() {
    this.checkListPage();
    this.checkDetailPage();
  },
  watch: {
    '$route': function() {
      this.checkListPage();
      this.checkDetailPage();
    },
  },
  mounted() {
    this.checkListPage(); // Check on initial load
    this.checkDetailPage();
  },
    computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  methods: {
    checkListPage() {
      this.isListPage = this.$route.name === 'ListComponent';
    },
    checkDetailPage() {
      this.isDetailPage = this.$route.name === 'DetailComponent' || this.$route.name === 'equipmentdetail' || this.$route.name === 'livestockdetails';
    },
  },
};
</script>

<style>
@import url("/src/components/NewproductComponent.scss");
</style>