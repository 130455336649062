<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1
                  :class="{
                    'tamil-title-h2': selectedLanguage === 'ta',
                    'title-h2': selectedLanguage !== 'ta',
                  }"
                >
                  {{
                    translations[`${$route.params.category.replace(/-/g, "_")}`]
                  }}
                </h1>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add">
                    <a href="/"> &nbsp;{{ translations.home_lite }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3">
        <div class="row" id="row-reverse">
          <div class="col-lg-3 col-md-4 col-sm-12 order-2 order-lg-1">
            <SidebarComponent :selectedLanguage="selectedLanguage" />
            <NewproductComponent :selectedLanguage="selectedLanguage"/>
          </div>
          <div class="col-lg-9 col-md-8 col-sm-12 order-1 order-lg-2">
            <div v-if="categories.length == 0 && isLoading">
              <div class="loader">
                <div class="row">
                  <div
                    v-for="data in 12"
                    :key="data.id"
                    class="col-6 col-sm-6 col-md-4 col-lg-3 mb-3"
                  >
                    <p class="shimmer-line-img"></p>
                    <p class="shimmer-line"></p>
                    <p class="shimmer-line"></p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="categories.length === 0">
              <div class="nodata-img mt-5">
                <img
                  class=""
                  src="../assets/images/emptypro.webp"
                  loading="lazy"
                  alt="empty"
                />
              </div>
              <h2 class="my-3 no-data">
                <!-- {{ translations.no_data_market }}  -->
                Subcategory is Not Available for here!!
              </h2>

              <p class="no-data-para">
                <!-- {{ translations.no_data_found }} -->
                We're sorry, Please check back later. You can browse our website
                to find alternative Category.
              </p>
            </div>
            <div 
              class="subcategorys"
              v-for="(category, index) in categories"
              :key="index"
            >
              <h2 v-if="hasNonEmptySubCategory(category)">
                {{
                  selectedLanguage === "en"
                    ? category.category_name
                    : category.tn_category
                }}
              </h2>
              <div class="row mt-4" v-if="category.hasSubCategory">
                <div
                  class="col-6 col-sm-6 col-md-4 col-lg-3"
                  v-for="subCategory in category.subCategories"
                  :key="subCategory.sub_category_id"
                >
                  <div class="subcategory-grid">
                    <router-link
                      :to="
                        navigateToAllList(
                          category.category_name,
                          subCategory.sub_category_name
                        )
                      "
                    >
                      <div class="sub-cate">
                        <img
                          :src="
                            subCategory.images[0] ||
                            require('../assets/images/No-Data-list.webp')
                          "
                          alt=""
                          class="sub-img"
                        />
                      </div>
                      <h3>
                        {{
                          selectedLanguage === "en"
                            ? subCategory.sub_category_name
                            : subCategory.tn_sub_name
                        }}
                      </h3>
                      <p>
                        {{ subCategory.list }} {{ translations.totalItems }}
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
              <div v-else class="col-lg-3 col-md-4 col-sm-12">
                <div class="subcategory-grid">
                  <router-link :to="navigateToAllList(category.category_name)">
                    <div class="sub-cate">
                      <img
                        :src="
                          category.images[0] ||
                          require('../assets/images/No-Data-list.webp')
                        "
                        alt=""
                        class="sub-img"
                      />
                    </div>
                    <h3>
                      {{
                        selectedLanguage === "en"
                          ? category.category_name
                          : category.tn_category
                      }}
                    </h3>
                    <p>{{ category.list }} {{ translations.totalItems }}</p>
                  </router-link>
                </div>
              </div>
            </div>
            <div v-if="categories.length != 0 && isLoading" class="loader">
                <div class="row">
                  <div
                    v-for="data in 12"
                    :key="data.id"
                    class="col-6 col-sm-6 col-md-4 col-lg-3 mb-3"
                  >
                    <p class="shimmer-line-img"></p>
                    <p class="shimmer-line"></p>
                    <p class="shimmer-line"></p>
                  </div>
                </div>
            </div>
            <div class="subcategorys text-center mt-4">
              <button
                v-if=" currentPage < totalPages && !isLoading "
                @click="loadMore"
                class="show-more-btn"
              >
                {{ translations.viewmore }}
              </button>
            </div>
              <div class="col-lg-12">
  
        </div>
          </div>
        </div>
      </div>
     
    </div>
     <div class="col-lg-12 col-md-12 col-sm-12 bottom-ads">
      <AdsComponent />
    </div>
  </section>
</template>

<script>
import axios from "axios";
import NewproductComponent from "./NewproductComponent.vue";
import SidebarComponent from "./SidebarComponent.vue";
import { en, ta } from "../translations.js";
import { disableScroll } from "../router/index.js";
import AdsComponent from "./AdsComponent.vue";

export default {
  props: {
    selectedLanguage: String,
  },
  data() {
    return {
      isLoading: false,
      categories: [],
      categoryIds: [],
      currentPage: 1,
      totalPages: 1,
      hasMorePages: true,
      filterCategories: [],
      isLocSelected: false,
      categoryType: "farm products",
    };
  },
  components: {
    SidebarComponent,
    NewproductComponent,
    AdsComponent
  },
  watch: {
    $route(to) {
      this.currentPage = 1; // Reset page when route changes
      this.hasMorePages = true;
      this.category = decodeURIComponent(
        to.params.category?.replace(/-/g, " ")
      );
      this.categories = []; // Clear old data
      this.loadData();
    },
    "$route.params": function () {
      this.updateMetaTags(); // Update meta tags whenever route params change
    },
  },
  head() {
    if (this.$route.params.category == "livestocks")
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": "https://vayalagro.com/tamilnadu/livestocks",
              url: "https://vayalagro.com/tamilnadu/livestocks",
              name: "Livestock in Tamil Nadu",
              description:
                "Find quality livestock available in Tamil Nadu including cows, goats, and poultry to support your farming needs.",
              inLanguage: "en-US",
              datePublished: "2024-10-17T00:00:00+00:00",
              dateModified: "2024-10-21T00:00:00+00:00",
              breadcrumb: {
                "@type": "BreadcrumbList",
                "@id": "https://vayalagro.com/tamilnadu/livestocks/#breadcrumb",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home",
                    item: "https://vayalagro.com/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Livestock",
                    item: "https://vayalagro.com/tamilnadu/livestocks",
                  },
                ],
              },
            },
          },
        ],
      };
    else if (this.$route.params.category == "agriculture-equipments")
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": "https://vayalagro.com/tamilnadu/agriculture-equipments",
              url: "https://vayalagro.com/tamilnadu/agriculture-equipments",
              name: "Agriculture Equipments in Tamil Nadu",
              description:
                "Discover a variety of agriculture equipment in Tamil Nadu, including tractors, tillers, and irrigation systems to improve farming efficiency.",
              inLanguage: "en-US",
              datePublished: "2024-10-17T00:00:00+00:00",
              dateModified: "2024-10-21T00:00:00+00:00",
              breadcrumb: {
                "@type": "BreadcrumbList",
                "@id":
                  "https://vayalagro.com/tamilnadu/agriculture-equipments/#breadcrumb",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home",
                    item: "https://vayalagro.com/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Agriculture Equipments",
                    item: "https://vayalagro.com/tamilnadu/agriculture-equipments",
                  },
                ],
              },
            },
          },
        ],
      };
    else
      return {
        script: [
          {
            type: "application/ld+json",
            json: {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": "https://vayalagro.com/tamilnadu/agriculture-products",
              url: "https://vayalagro.com/tamilnadu/agriculture-products",
              name: "Agriculture Products in Tamil Nadu",
              description:
                "Explore a wide range of agriculture products available in Tamil Nadu. Quality seeds, fertilizers, and farming tools to enhance your farming experience.",
              inLanguage: "en-US",
              datePublished: "2024-10-17T00:00:00+00:00",
              dateModified: "2024-10-21T00:00:00+00:00",
              breadcrumb: {
                "@type": "BreadcrumbList",
                "@id":
                  "https://vayalagro.com/tamilnadu/agriculture-products/#breadcrumb",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home",
                    item: "https://vayalagro.com/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Agriculture Products",
                    item: "https://vayalagro.com/tamilnadu/agriculture-products",
                  },
                ],
              },
            },
          },
        ],
      };
  },
  created() {
    this.scrollToTop();
    this.category = decodeURIComponent(
      this.$route.params.category.replace(/-/g, " ")
    );
    const { category, location } = this.$route.params;
    console.log("Received Category:", category);
    console.log("Received Location:", location);
    this.categoryType = category.replace(/-/g, " "); // Reverse slugify if needed
    this.defaultLocation = location; // Use in API calls or UI
    this.loadData();
    if (this.$route.params.market_name) {
      this.location = this.$route.params.market_name;
    }
    if (this.$route.params.product) {
      this.product = this.$route.params.product;
    }
    this.isNameSelected = false;
    this.isLocSelected = false;
    this.loadData();
    this.loadFilterCategories();
  },
  mounted() {
    this.loadData();
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  methods: {
    async loadFilterCategories() {
      try {
        let apiUrl = "https://vaiyal-app.herokuapp.com/categorytype";
        let requestBody = {
          categorytype: this.$route.params.category
            .replace(/-/g, " ")
            .replace("agriculture", "farm"),
        };

        // Check if location is not TamilNadu, then append marketname to apiUrl
        if (this.$route.params.location !== "tamilnadu") {
          apiUrl += `?marketname=${this.$route.params.location}`;
        }

        const response = await axios.post(apiUrl, requestBody, {
          headers: {
            version: "3.0",
          },
        });

        this.filterCategories = response.data.data;
        console.log("filterCategories", this.filterCategories);
      } catch (error) {
        console.error("Error fetching filter categories:", error);
      }
    },
    async loadData() {
      try {
        this.isLoading = true;
        this.loadFilterCategories();
        const apiUrl = `https://vaiyal-app.herokuapp.com/categoryweb?type=${this.category.replace(
          "agriculture",
          "farm"
        )}&limit=3&page=${this.currentPage}`;
        const response = await axios.post(apiUrl);

        if (response.data.status === "success") {
          this.totalPages = response.data.pages;
          this.hasMorePages = this.currentPage < this.totalPages;

          if (this.currentPage === 1) {
       this.categories = response.data.data;
            this.categoryIds = this.categories.map((item) => item.category_id);
          } else {
            this.categories = [...this.categories, ...response.data.data];
          }
          disableScroll();
        } else {
          console.error("API Error:", response.data.message || "Unknown error");
        }
      } catch (error) {
        console.error("Network Error:", error.message);
      } finally {
        this.isLoading = false;
      }
    },
    async loadMore() {
       this.isLoading = true;
       disableScroll();
      this.currentPage++;
      await this.loadData();
      this.isLoading = false;
    },
    hasNonEmptySubCategory(category) {
      return (
        category.subCategories.length ||
        (!category.subCategories.length && !category.hasSubCategory)
      );
    },
    navigateToAllList(catName, subcategoryName = null) {
      // Extract location and category details from the route params
      const location = this.$route.params.location;
      const category = this.$route.params.category;

      let params = {};
      //  const query = { categoryId };

      if (subcategoryName) {
        params = {
          categoryName: catName.replace(/\s+/g, "-").toLowerCase(),
          location: location,
          category: category,
          product: subcategoryName.replace(/\s+/g, "-").toLowerCase(),
          // categoryId: categoryId
        };
        console.log("params test", params);
      } else {
        params = {
          product: catName.replace(/\s+/g, "-").toLowerCase(),
          location: location,
          category: category,
          // categoryId: categoryId
        };
      }

      // Return the route object with params
      return {
        name: "ListComponent",
        params,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (to.params.location === "") {
        window.location.reload();
      } else {
        next();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
</style>
