<template>
  <div>
    <section class="ribben-section d-sm-none">
      <div class="wrapper">
        <div class="col-12 ribben">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="bore download-count">
                <span>13K+ Downloads</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="bore">
                <span>1300+ Agricultural Products</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="buyers">
                <span>1200+ Trust By Buyers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ribben-section-web d-none d-sm-block">
      <div class="wrapper">
        <div class="col-12 ribben-web">
          <div class="scroller-web" ref="scroller">
            <div class="ribben-content-web">
              <div class="bore download-count">
                <span>13K+ Downloads</span>
              </div>
              <div class="bore">
                <span>1300+ Agricultural Products</span>
              </div>
              <div class="buyers">
                <span>1200+ Trust By Buyers</span>
              </div>
            </div>
            <div class="ribben-content-web mx-5">
              <!-- Duplicate content for smooth looping -->
              <div class="bore download-count">
                <span>13K+ Downloads</span>
              </div>
              <div class="bore">
                <span>1300+ Agricultural Products</span>
              </div>
              <div class="buyers">
                <span>1200+ Trust By Buyers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      this.startScrolling();
    });

    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    startScrolling() {
      const scroller = this.$refs.scroller;
      if (!scroller) return; // Prevent error if ref is not yet available

      const speed = this.calculateSpeed();
      let position = 0;
      let isPaused = false;

      scroller.innerHTML += scroller.innerHTML; // Duplicate content for smooth loop

      const animate = () => {
        if (!isPaused) {
          position -= speed;
          if (position <= -scroller.scrollWidth / 2) {
            position = 0;
          }
          scroller.style.transform = `translateX(${position}px)`;
        }
        requestAnimationFrame(animate);
      };

      scroller.addEventListener("mouseenter", () => (isPaused = true));
      scroller.addEventListener("mouseleave", () => (isPaused = false));

      animate();
    },
    calculateSpeed() {
      const width = window.innerWidth;
      return width < 1024 ? 1 : 1; // Speed varies by screen size
    },
    handleResize() {
      this.startScrolling(); // Restart scrolling on resize
    },
  },
};
</script>

<style>
@import url("/src/components/RibbenComponent.scss");
</style>
