var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"side-category-section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('div',{staticClass:"side-category"},[_c('h2',{staticClass:"category-name"},[_vm._v(_vm._s(_vm.translations.category))]),_c('span'),_vm._l((_vm.category_data),function(item){return _c('div',{key:item.id,staticClass:"side-category-list",class:{ 'active-category': _vm.isActiveCategory(item.name) }},[_c('div',{staticClass:"side-category-image-div"},[_c('img',{staticClass:"side-category-image",attrs:{"src":item.image,"alt":"Category Image","loading":"lazy"}})]),_c('div',{staticClass:"side-category-name-div"},[_c('router-link',{attrs:{"to":item.hasSubCategory
                  ? {
                      name: 'SubcategoryPage',
                      params: {
                        categoryId: item.category_id,
                        categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                        category:
                          item.category_type === 'Live stocks'
                            ? 'livestocks'
                            : item.category_type
                                .replace(/\s/g, '-')
                                .replace('farm', 'agriculture'),
                      },
                    }
                  : {
                      name: 'ListComponent',
                      params: {
                        product: item.name.replace(/\s/g, '-').toLowerCase(),
                        location: _vm.$route.params.location,
                        category:
                          item.category_type === 'Live stocks'
                            ? 'livestocks'
                            : item.category_type
                                .replace(/\s/g, '-')
                                .replace('farm', 'agriculture'),
                      },
                    },"title":`Category: ${item.name}`}},[_c('p',[_vm._v(_vm._s(_vm.selectedLanguage === "en" ? item.name : item.tn_name))])])],1),_c('div',{staticClass:"side-category-count-div"},[_c('p',{staticClass:"side-category-count"},[_vm._v(_vm._s(item.list))])])])}),(_vm.isLoading)?_c('div',{staticClass:"loading-spinner"},[_c('div',{staticClass:"loader"},[_c('div',{staticClass:"row"},_vm._l((4),function(data){return _c('div',{key:data.id,staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('p',{staticClass:"shimmer-line"}),_c('p',{staticClass:"shimmer-line"})])}),0)])]):(!_vm.category_data.length)?_c('div',{staticClass:"no-data"},[_vm._v(" No categories available. ")]):_vm._e()],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }