<template>
   <div>
    <PopUp :selectedLanguage="selectedLanguage" />
      <BannerComponent :selectedLanguage="selectedLanguage" @updateMarketId="setMarketId"/>
    <AdsComponent :selectedLanguage="selectedLanguage"/>
    <CategoryComponent :selectedLanguage="selectedLanguage"/>
    <ProductComponent :selectedLanguage="selectedLanguage"/>
    <EquipmentComponent :selectedLanguage="selectedLanguage"/>
    <LiveComponent :selectedLanguage="selectedLanguage"/>
    <PopularComponent :selectedLanguage="selectedLanguage" :marketId="userStore.marketId"
      :marketName="$route.params.market_name"/>
    <DailyComponent :selectedLanguage="selectedLanguage" :marketId="userStore.marketId"
      :marketName="$route.params.market_name"/>
    <ReviewComponent :selectedLanguage="selectedLanguage"/>
    <AppComponent :selectedLanguage="selectedLanguage"/>
    <FrequentlyComponent :selectedLanguage="selectedLanguage"/>
   </div>
</template>

<script>
import { en, ta } from "../translations.js";

import AdsComponent from '../components/AdsComponent.vue';
import AppComponent from '../components/AppComponent.vue';
import BannerComponent from '../components/BannerComponent.vue';
import CategoryComponent from '../components/CategoryComponent.vue';
import DailyComponent from '../components/DailyComponent.vue';
import EquipmentComponent from '../components/EquipmentComponent.vue';
import ReviewComponent from '../components/ReviewComponent.vue';
import LiveComponent from '../components/LiveComponent.vue';
import PopularComponent from '../components/PopularComponent.vue';
import ProductComponent from '../components/ProductComponent.vue';
import FrequentlyComponent from '../components/FrequentlyComponent.vue';
import PopUp from '../components/PopUp.vue';
import { useUserStore } from '../stores/userStore'

export default {
  setup () {
    const userStore = useUserStore()
    
    console.log('userStore: ',userStore.marketPlaceId)
    return { userStore }
  },
  created () {
    if (!this.$route.params.location || this.$route.params.location == 'tamilnadu') {
      this.setMarketId(null)
    }
  },
 name: "HomePage",
  props: ["selectedLanguage"],
    components: {
    BannerComponent,
    AdsComponent,
    CategoryComponent,
    ProductComponent,
    EquipmentComponent,
    LiveComponent,
    PopularComponent,
    DailyComponent,
    AppComponent,
    FrequentlyComponent,
    ReviewComponent,
    PopUp
  },
   data() {
    return {
      showBackToTop: false,
      marketId: "" 
    };
  },
  computed: {
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    }
    
  },
   methods: {
    setMarketId(marketId) {
      this.marketId = marketId;
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 600;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
</script>

<style>

</style>