var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product-category-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"container"},[_c('h2',{class:{ 'category-tamil-fonts': _vm.selectedLanguage === 'ta', 'category-title category-title-live': _vm.selectedLanguage !== 'ta' }},[_vm._v(_vm._s(_vm.translations.Livestocks))])]),_c('div',{staticClass:"col-lg-12"},[(_vm.isLoading)?_c('div',{staticClass:"loader"},[_c('div',{staticClass:"row"},_vm._l((6),function(product){return _c('div',{key:product.id,staticClass:"col-lg-2 col-md-2 col-sm-12"},[_c('p',{staticClass:"shimmer-line-img"})])}),0)]):_c('div',[(_vm.category_data.length === 0)?_c('div',{staticClass:"no-data"},[_vm._m(0),_c('h2',{staticClass:"my-3 no-data"},[_vm._v(" Livestocks Not Available for here!! ")]),_c('p',{staticClass:"no-data-para"},[_vm._v(" We're sorry, Please check back later. You can browse our website to find alternative Category. ")])]):_c('div',{staticClass:"row"},_vm._l((_vm.displayedProducts),function(item){return _c('div',{key:item.id,staticClass:"col-lg-2 col-md-3 col-6"},[_c('router-link',{attrs:{"to":item.hasSubCategory
                ? {
                    name: 'SubcategoryPage',
           
                    params: {
                      categoryId: item.category_id,
                      categoryName: item.name.replace(/\s/g, '-').toLowerCase(),
                      category:
                        item.category_type === 'Live stocks'
                          ? 'livestocks'
                          : item.category_type
                              .replace(/\s/g, '-')
                              .replace('farm', 'agriculture'),
                              location: 'tamilnadu',
                    },
                  }
                : {
                    name: 'ListComponent',
                    params: {
                      product: item.name.replace(/\s/g, '-').toLowerCase(),
                      location: 'tamilnadu',
                      category:
                        item.category_type === 'Live stocks'
                          ? 'livestocks'
                          : item.category_type
                              .replace(/\s/g, '-')
                              .replace('farm', 'agriculture'),
                    },
                  },"title":`Category: ${item.name}`}},[_c('div',{staticClass:"product-item"},[_c('div',{staticClass:"img-div"},[_c('img',{attrs:{"src":item.image,"alt":"Product Image"}})]),_c('p',{class:{ 'tamil-product-title': _vm.selectedLanguage === 'ta', 'category-product-title-pr': _vm.selectedLanguage !== 'ta' }},[_vm._v(_vm._s(_vm.selectedLanguage === "en" ? item.name : item.tn_name))])])])],1)}),0)])]),(_vm.showToggleButton)?_c('div',{class:{ 'tamil-toggle-button': _vm.selectedLanguage === 'ta', 'toggle-button': _vm.selectedLanguage !== 'ta' }},[_c('button',{on:{"click":_vm.toggleShowMore}},[_vm._v(" "+_vm._s(_vm.isAllProductsShown ? _vm.translations.hideexplore : _vm.translations.viewmore)+" ")])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nodata-img"},[_c('img',{attrs:{"src":require("../assets/images/emptypro.webp"),"loading":"lazy","alt":"empty"}})])
}]

export { render, staticRenderFns }