<template>
  <section class="category-page-section">
    <div class="wrapper">
      <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="title-banner">
              <div class="title-content">
                <h1 :class="{ 'tamil-title-h2': selectedLanguage === 'ta', 'title-h2': selectedLanguage !== 'ta' }">History Price</h1>
                <div class="address-section">
                  <img
                    src="../assets/images/category-page-home-icon.svg"
                    alt=""
                  />
                  <p class="footer-add"><a href="/"> &nbsp;Home</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"><a href="/market-price">Market Price</a></p>
                  <img
                    src="../assets/images/next-page.svg"
                    alt=""
                    class="next-page"
                  />
                  <p class="footer-add"> 
                     <router-link :to="`/market-price/tamilnadu/${this.$route.params.categoryName}-market-price${this.$route.params.location}/${this.$route.params.city ?? ''}`">
                    {{ this.$route.params.categoryName }}
                     </router-link>
                    </p>
                </div>
                <!-- <img src="../assets/images/category-page-home-icon.svg" alt=""><span>Home</span> &nbsp; <img src="../assets/images/next-page.svg" alt=""> &nbsp;<span>Product Category </span> -->
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <section class="marketprice-select">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="catgory">
                  <h5>{{ translations.district }}</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                      <md-field>
                        <md-select  v-model="selectedMarketId" id="l1"  @md-opened="disableBodyScroll"
                        @md-closed="enableBodyScroll">
                          <md-option value="0" disabled> {{ translations.selectDist1 }}</md-option>
                          <md-option 
                           v-for="market in markets"
                          :key="market.market_id"
                          :value="market.market_id"
                          class="listoption">
                            {{ selectedLanguage === 'en' ? market.market_name : market.tn_name }}
                            </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="catgory">
                  <h5>{{ translations.citys }}</h5>
                  <div class="div">
                    <div class="category-icon">
                      <img
                        src="../assets/images/MP-select-icon.svg"
                        alt=""
                        class="category-icon-img"
                      />
                    </div>
                    <div class="select-section">
                       <md-field>
                        <md-select  v-model="selectedMarketPlaceId"  @md-opened="disableBodyScroll"
                        @md-closed="enableBodyScroll">
                          <md-option value="0" disabled> {{ translations.selectCity }}</md-option>
                          <md-option 
                            v-for="place in places"
                          :key="place.market_place_id"
                          :value="place.market_place_id">
                            {{ selectedLanguage === 'en' ? place.place : place.tn_place }}
                            </md-option>
                        </md-select>
                      </md-field>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
        <div class="trend-market">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="trend-graph">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="row">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <h1 v-if="recentPriceData.length > 0">
                          <!-- Historical Potato Rates in Coimbatore for the Last 10
                          Days -->
                          <!-- History Price of {{this.tamildata.category_name }} <small>&nbsp; ({{this.tamildata.sub_category_name }})</small> -->
                          {{ selectedLanguage === "en" 
      ? this.tamildata && this.tamildata.category_name 
        ? translations.history_of + " " + this.tamildata.category_name 
        : '' 
      : this.tamildata && this.tamildata.tn_category 
        ? this.tamildata.tn_category + " " + translations.history_of 
        : ''
  }} <small v-if="selectedLanguage === 'en' 
              ? this.tamildata && this.tamildata.sub_category_name 
              : this.tamildata && this.tamildata.tn_sub_name">
    &nbsp;  ({{ selectedLanguage === "en" 
        ? this.tamildata.sub_category_name 
          ? this.tamildata.sub_category_name 
          : this.tamildata.category_name 
        : this.tamildata.tn_sub_name 
          ? this.tamildata.tn_sub_name 
          : this.tamildata.tn_category 
    }})
  </small>
                        </h1>
                       <h1 v-else> History Price of Recent Marketprice</h1>
                      </div>
                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="graph-section">
                          <button @click="toggleView"  :class="{ 'tamil-api-button': selectedLanguage === 'ta', 'api-button': selectedLanguage !== 'ta' }">
                            {{ isPowerOne ? translations.graph : translations.table }}
                          </button>

                          <div class="select-section">
                            <select name="cars" id="cars" disabled>
                              <option value="volvo">Month</option>
                              <option value="saab">Jan</option>
                              <option value="opel">Feb</option>
                              <option value="audi">Aug</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="{ 'powerone': isPowerOne, 'graph-view': !isPowerOne }">
                  <div v-if="isLoading" class="mt-5">
                    <!-- Shimmer effect for loading -->
                    <div
                      v-for="index in 6"
                      :key="index"
                      class="container"
                    >
                      <p class="shimmer-line" style="height: 40px"></p>
                      <p class="shimmer-line" style="height: 40px"></p>
                      <p class="shimmer-line" style="height: 40px"></p>
                    </div>
                  </div>
                  <div v-if="isPowerOne && recentPriceData.length">
                    <div
                      class="table-container mb-5"
                      :class="{ powerone: isPowerOne, powertwo: !isPowerOne }"
                    >
                      <table>
                        <tr>
                          <th>{{translations.category}}</th>
                          <th >{{translations.selectdist}}</th>
                          <th >{{translations.city}}</th>
                          <th>{{ translations.date }}</th>
                          <th>{{translations.price}}</th>
                          <th>{{ translations.units }}</th>
                        </tr>
                        <tr
                          v-for="(item, index) in recentPriceData"
                          :key="index"
                        >
                          <td>
                            <div class="category-name-table">
                               <img src="../assets/images/vegetable.png" alt="" class="veg-icon"/>
                              <p>
                                {{  selectedLanguage === "en" ? tamildata.sub_category_name || tamildata.category_name : tamildata.tn_sub_name || tamildata.tn_category }}
                              </p>
                            </div>
                          </td>
                          <td>{{ selectedLanguage === "en" ? item.market_name : item.tn_name }}
                          </td>
                          <td>  {{
                          selectedLanguage === "en" ? item.place : item.tn_place
                        }}</td>
                          <td>{{ formatCreatedDate(item.created) }}</td>
                          <td class="amt">Rs. {{ item.price }}</td>
                          <td class="weight">{{ selectedLanguage === "en" ? item.quantity_type : item.quantity_type_tamil }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div v-else-if="recentPriceData.length" class="graph-view">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                         <div class="graph-price">
                           <span>Price {{ formatCreatedDate(recentPriceData[0].created) }}</span>
                           <p>Rs.{{ recentPriceData[0].price }}</p>
                         </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                          <div class="monthly-view">
  <span 
    v-for="(option, index) in timeOptions" 
    :key="index" 
    :class="{ selected: selectedOption === option }"
    @click="handleOptionClick(option)">
    {{ option }}
  </span>
</div>
<div v-if="showPopup" class="popup">
  <div class="pop-content">
  <span @click="closePopup">
    <i class="fa-solid fa-circle-xmark" style="color: #008000;"></i>
  </span>
   <div class="popup-vayal-logo">
     <img src="../assets/images/vayal-new-logo.png" alt="" class="popup-logo">
   </div> 
  <p>{{ popupMessage }}</p>
  <button> <a href="/contact">Contact Us</a></button>
  </div>
</div>

                        </div>
                      </div>
                    </div>
                    <div class="grapgh">
                      <div v-if="selectedOption === 'Weekly' && isWeeklyDataEmpty" class="no-data-message">
      <p>No data available for the selected week.</p>
    </div>
                      <apexchart
                        class="chart"
                        type="area"
                        height="350"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </div>
                  </div>
                  <div v-else class="mt-5">
                     <div v-if="isLoading" class="mt-5">
                    <!-- Shimmer effect for loading -->
                    <div
                      v-for="index in 6"
                      :key="index"
                      class="container"
                    >
                      <p class="shimmer-line" style="height: 40px"></p>
                      <p class="shimmer-line" style="height: 40px"></p>
                      <p class="shimmer-line" style="height: 40px"></p>
                    </div>
                  </div>
                    <!-- Shimmer effect for loading -->
                    <div v-else>
                      <div class="nodata-img">
              <img class="" src="../assets/images/emptypro.webp" loading="lazy" alt="empty">
            </div>
              <h2 class="my-3 no-data">
                <!-- {{ translations.no_data_market }}  -->
                Market price is not available for selected date
               </h2>
               
               <p class="no-data-para">
                <!-- {{ translations.no_data_found }} -->
                We're sorry, but the Market price not available for the selected date filter. Please check back later. You can browse our website to find alternative market price categories.
                </p>
                </div>
                  </div>
                </div>
                <div class="top-cities" v-if="isPowerOne && otherPriceData.length > 0">
                  <h3>{{ selectedLanguage === "en" ? otherdata.sub_category_name || otherdata.category_name : otherdata.tn_sub_name || otherdata.tn_category }} Rates in Top Cities</h3>
                </div>
                <div class="more-cities" v-show="isPowerOne">
                  <h6>Search for more cities</h6>
                </div>
<div v-show="isPowerOne">
  <div class="table-container mt-4 history-table" v-show="isPowerOne" v-if="otherPriceData.length > 0">
    <table>
      <tr>
        <th>{{ translations.city }}</th>
        <th>{{ translations.category }}</th>
        <th>{{ translations.date }}</th>
        <th>{{ translations.price }}</th>
        <th>{{ translations.units }}</th>
      </tr>
      <tr v-for="(data, index) in otherPriceData" :key="index">
        <td>{{
                          selectedLanguage === "en" ? data.place : data.tn_place
                        }}</td>
        <td>
          <div class="category-name-table">
              <img src="../assets/images/vegetable.png" alt="" class="veg-icon"/>
            <p>{{ selectedLanguage === "en" ? otherdata.sub_category_name || otherdata.category_name : otherdata.tn_sub_name || otherdata.tn_category }}</p>
          </div>
        </td>
        <td>{{ formatCreatedDate(data.created) }}</td>
        <td class="amt">Rs. {{ data.price }}</td>
        <td class="weight">{{ selectedLanguage === "en" ? data.quantity_type : data.quantity_type_tamil }}</td>
      </tr>
    </table>
  </div>
  <div v-else >
  <p>No other price data available.</p> <!-- Fallback message -->
</div>
</div>

              </div>

              <!-- <div class="col-lg-2 col-md-2 col-sm-12 vertical-add">
                <div class="vertical-ad">
              <Adsense
     data-ad-client="ca-pub-1763856178878021"
     data-ad-slot="4892250513">
</Adsense>
            </div>
            <div class="vertical-ad mt-3">
              <Adsense
     data-ad-client="ca-pub-1763856178878021"
     data-ad-slot="4892250513">
</Adsense>
            </div>
              </div> -->
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { en, ta } from "@/translations";
import { useItemStore, useUserStore } from "@/stores/userStore";
import { mapActions, mapState } from "pinia";
export default {
  name: "PostApi",
  components: {
    apexchart: VueApexCharts,
  },
    props: {
    selectedLanguage: String
  },
  data() {
    return {
      isPowerOne: true, // Initial state
      timeOptions: ["Weekly", "Monthly", "Annually"], // The options to display
      selectedOption: "Monthly", // Default selection
      showPopup: false, // To toggle popup visibility
      popupMessage: "",
      localCategoryName: null,
      localName: null,
      markets: [],
      places: [],
      selectedMarketId: 0,
      selectedMarketPlaceId: 0,
      recentPriceData: [], // Use an array to store recent price data
      otherPriceData: [],
      unselectedMarketplaceIds:[],
      isLoading: false,
      isdetailsLoading: false,
      tamildata: null,
      otherdata: null,
      showGraphView: false,
      responseMessage: null, // To display API response or errors
      chartOptions: {
        chart: {
          type: "area",
          height: 350,
          stacked: false,
          zoom: {
            enabled: false,
          },

          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ["#009954"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 6,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            colorStops: [
              {
                offset: 0,
                color: "#29AB87",
                opacity: 0.7,
              },
              {
                offset: 100,
                color: "#FFFFFF",
                opacity: 0.2,
              },
            ],
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
          colors: ["#009954"],
        },
        tooltip: {
          shared: true,
          theme: "dark",

          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const value = series[seriesIndex][dataPointIndex]; // y value
            const xValue = w.globals.categoryLabels[dataPointIndex]; // Correct way to get the x-axis label

            return `
      <div style="padding: 10px; background: #333; color: #fff; border-radius: 5px;">
        <strong></strong> ${xValue}<br />
        <strong>Rs:</strong> ${value}
      </div>
    `;
          },
        },

        yaxis: {
          labels: {
            style: {},
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        xaxis: {
          type: "category",
          crosshairs: {
            show: true,
            width: 1,
            position: "back",
            stroke: {
              color: "#009954",
              width: 2,
              dashArray: 0,
            },

            x: {
              axisBorder: {
                show: true,
                color: "#009954",
              },
            },
            y: {
              axisBorder: {
                show: true,
                color: "#009954",
              },
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "right",
        },
      },
      series: [],
    };
  },
  computed: {
    ...mapState(useItemStore, ["categoryId", "subCategoryId"]),
    ...mapState(useUserStore, [
      "marketId",
      "marketPlaceId",
      "updateMarketPlaceIdByCity",
    ]),
    translations() {
      return this.selectedLanguage === "en" ? en : ta;
    },
  },
  async created() {
       this.scrollToTop();
    console.log("roytyefdd",this.$route)
    await this.updateItemId(this.$route.params.name.replace(/-/g, " "),"sub_category_name", true);
    await this.updateMarketIdByDist(this.$route.params.location.split("-")[1].replace(/-/g, " "));
    await this.updateMarketPlaceIdByCity(this.$route.params.city.replace(/-/g, " "));
    this.fetchMarkets();
this.fetchOtherPrice(); 
  },
  mounted() {
    this.localCategoryName = decodeURIComponent(this.$route.params.name.replace(/-/g, ' '))

    if (this.$route.params.name === "") {
      this.localName = decodeURIComponent(this.$route.params.name.replace(/-/g, ' '));
    } else {
      this.localName = decodeURIComponent(this.$route.params.name.replace(/-/g, ' ')
      );
    }
  },

  watch: {
    selectedMarketId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedMarketPlaceId = 0;
      }
      this.updateMarketId(this.selectedMarketId);
    },
    selectedMarketPlaceId: {
      handler() {
        this.updateMarketPlaceId(this.selectedMarketPlaceId);
        this.fetchRecentPrice();
        this.fetchOtherPrice();
      },
      immediate: true,
    },
    marketId: {
      handler(newVal, oldVal) {
        console.log("mId changed from" + oldVal + "to" + newVal);
        if (newVal !== oldVal && this.marketId != "0") {
          console.log("changing dist params:");
          if (this.selectedMarketId && this.selectedMarketId !== "") {
            this.fetchPlaces();
          }
        }
      },
    },
    marketPlaceId: {
      handler(newVal, oldVal) {
        console.log("mpId changed from" + oldVal + "to" + newVal);
        if (newVal !== oldVal && this.marketId != "0") {
          console.log("changing city params:");

          let params = { ...this.$route.params };
          for (let district of this.markets) {
            if (district.market_id == this.marketId) {
              params.location = district?.market_name
                ?.replace(/\s+/g, "-")
                ?.toLowerCase();
              console.log("setting location in params:", params.location);
              break;
            }
          }

          if (this.selectedPlace != "0") {
            for (let city of this.places) {
              if (city.market_place_id == this.marketPlaceId) {
                params.city = city?.place?.replace(/\s+/g, "-")?.toLowerCase();
                console.log("setting city in params:", params.city);
                break;
              }
            }
          } else params.city = "";
          (params.location =
            params.location[0] == "-"
              ? params.location
              : `-${params.location}`),
            this.$router.push({
              name: "AllRecent",
              params,
            });
        }
      },
    },
    categoryId: {
      handler(newVal, oldVal) {
        console.log("catId changed from" + oldVal + "to" + newVal);
      },
    },
    subCategoryId: {
      handler(newVal, oldVal) {
        console.log("subcatId changed from" + oldVal + "to" + newVal);
      },
    },
  },
  methods: {
       disableBodyScroll() {
      // Disable body scroll when the dropdown is opened
      document.body.style.overflow = "hidden";
    },
    enableBodyScroll() {
      // Re-enable body scroll when the dropdown is closed
      document.body.style.overflow = "auto";
    },
    ...mapActions(useItemStore, ["updateItemId", "updateMarketCatId"]),
    ...mapActions(useUserStore, [
      "updateMarketId",
      "updateMarketIdByDist",
      "updateMarketPlaceId",
    ]),
    toggleView() {
      this.isPowerOne = !this.isPowerOne;
    },
    formatCreatedDate(dateString) {
      // Convert the date string to a Date object
      const date = new Date(dateString);

      // Get the day, month, and year
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = date.getUTCFullYear();
      // Format the final string as "dd-mm-yyyy"
      return `${day}-${month}-${year}`;
    },

    fetchMarkets() {
      const selectproduct = decodeURIComponent("market price");
      const categoryid = this.categoryId;
      const subcategoryid = this.subCategoryId;
      const marketIdFromURL = this.marketId || null;

      const selectproductMapping = {
        "farm products": "product",
        livestocks: "product",
        "farm equipments": "equipment",
        "market price": "market",
      };

      const mappedSelectProduct =
        selectproductMapping[selectproduct] || selectproduct;

      const apiUrl = `https://vaiyal-app.herokuapp.com/getdistrict?type=${mappedSelectProduct}&${
        subcategoryid
          ? `subcategoryid=${subcategoryid}`
          : categoryid
          ? `categoryid=${categoryid}`
          : ""
      }&recent=true`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          this.markets = data.data;
          console.log("this.market", this.markets);

          if (!this.markets || this.markets.length === 0) {
            return;
          }

          if (
            marketIdFromURL &&
            this.markets.some((market) => market.market_id === marketIdFromURL)
          ) {
            this.selectedMarketId = marketIdFromURL;
            this.updateMarketId(marketIdFromURL);
          } else {
            this.selectedMarketId = 0;
          }

          console.log("Selected Market ID:", this.selectedMarketId);
          this.fetchPlaces(); 
        })
        .catch((error) => {
          console.error("Error fetching markets:", error);
        });
    },

    fetchPlaces() {
  const selectproduct = decodeURIComponent("market price");
  const categoryid = this.categoryId;
  const subcategoryid = this.subCategoryId;
  const marketplaceIdFromURL = this.marketPlaceId;

  const selectproductMapping = {
    "farm products": "product",
    livestocks: "product",
    "farm equipments": "equipment",
    "market price": "market",
  };
  const mappedSelectProduct =
    selectproductMapping[selectproduct] || selectproduct;

  const apiUrl = `https://vaiyal-app.herokuapp.com/getcities?type=${mappedSelectProduct}&${
    subcategoryid
      ? `subcategoryid=${subcategoryid}`
      : categoryid
      ? `categoryid=${categoryid}`
      : ""
  }&recent=true&marketid=${this.selectedMarketId}`;

  fetch(apiUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      this.places = data.data;

      if (
        marketplaceIdFromURL &&
        this.places.some(
          (place) => place.market_place_id === marketplaceIdFromURL
        )
      ) {
        this.selectedMarketPlaceId = marketplaceIdFromURL; // Set the selectedMarketPlaceId from the URL
        this.updateMarketPlaceId(marketplaceIdFromURL);
      } else {
        const selectedCity = this.$route.params.city.replace(/-/g, " ");
        const selectedPlace = this.places.find(
          (place) => place.place.toLowerCase() === selectedCity
        );
        if (selectedPlace) {
          this.selectedMarketPlaceId = selectedPlace.market_place_id;
          this.updateMarketPlaceId(this.selectedMarketPlaceId);
        }
      }

      // Store unselected marketplace IDs
      this.unselectedMarketplaceIds = this.places
        .filter((place) => place.market_place_id !== this.selectedMarketPlaceId)
        .map((place) => place.market_place_id);

      console.log("mpId set inside:", this.marketPlaceId);
      console.log("Unselected Marketplace IDs:", this.unselectedMarketplaceIds); // Debugging

      this.fetchRecentPrice();
    })
    .catch((error) => {
      console.error("Error fetching places:", error);
    });
},
fetchRecentPrice() {
  const payload = {
    subcategoryid: this.subCategoryId,
    categoryid: this.subCategoryId ? undefined : this.categoryId,
    marketplaceid: this.marketPlaceId
  };

  this.isLoading = true;
  this.isdetailsLoading = true;

  fetch('https://vaiyal-app.herokuapp.com/subcategory/getrecentprice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(data => {
      console.log("apiUrl", data.details.category_name);
      this.recentPriceData = data.data; 
      this.tamildata = data.details;
      this.subData = data.details.sub_category_name;
      console.log("this.subData", this.recentPriceData);

      // Check if recentPriceData exists and is an array
      if (!Array.isArray(this.recentPriceData) || this.recentPriceData.length === 0) {
        // this.$router.replace({ path: "/error" });
        return;
      }

      // Sort data in descending order by created timestamp
      if (this.selectedOption === "Monthly") {
    this.updateChartData(this.recentPriceData);
  }

      const apiCategoryName = data.details.category_name;
      const currentCategoryName = this.$route.params.categoryName;

      let needsRouteUpdate = false;
      let newRouteParams = { ...this.$route.params };

      if (apiCategoryName && apiCategoryName !== currentCategoryName) {
        newRouteParams.categoryName = apiCategoryName.replace(/\s+/g, '-').toLowerCase();
        needsRouteUpdate = true;
      }

      if (needsRouteUpdate) {
        this.$router.replace({ ...this.$route, params: newRouteParams });
      }
    })
    .catch(error => {
      console.error('Error fetching recent price:', error);
    })
    .finally(() => {
      this.isLoading = false;
      this.isdetailsLoading = false;
    });
},
fetchOtherPrice() {
  const payload = {
    subcategoryid: this.subCategoryId,
    categoryid: this.subCategoryId ? undefined : this.categoryId,
    marketplaceid: this.unselectedMarketplaceIds, // Use unselectedMarketplaceIds
  };

  this.isLoading = true;
  this.isdetailsLoading = true;

  fetch('https://vaiyal-app.herokuapp.com/subcategory/getotherrecentprice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => response.json())
    .then(data => {
      console.log('API Response for Other Price Data:', data); // Debugging
      this.otherPriceData = data.data; // Ensure this is set correctly
      this.otherdata = data.details; // Ensure this is set correctly
      console.log('Other Price Data:', this.otherPriceData); // Debugging
    })
    .catch(error => {
      console.error('Error fetching other price:', error);
    })
    .finally(() => {
      this.isLoading = false;
      this.isdetailsLoading = false;
    });
},
updateChartData(data) {
  // Sort data by created date
  const processedData = data
    .map(item => ({
      x: item.created,
      y: item.price || 0,
    }))
    .sort((a, b) => new Date(a.x) - new Date(b.x));

  // Format data for the chart
  const formattedData = processedData.map(item => {
    const date = new Date(item.x);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return {
      x: `${day}/${month}`,
      y: item.y,
    };
  });

  // Update the chart's series
  this.series = [
    {
      name: "Product A",
      data: formattedData,
    },
  ];
},

   handleOptionClick(option) {
    if (option === "Annually") {
      // Show popup for the Annually option
      this.popupMessage = `The Annually option is not Available for you. If you need to see a "Annual Market Price" please contact Admin`;
      this.showPopup = true;
              document.body.style.overflow = 'hidden';

      // Auto-hide the popup after 2 seconds
      
    } else {
      this.selectOption(option);
    }
  },
  closePopup(){
this.showPopup = false;
document.body.style.overflow = 'auto';
  },
 selectOption(option) {
  this.selectedOption = option;

  if (option === "Weekly") {
    const now = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(now.getDate() - 7);

    // Filter recentPriceData for the last 7 days
    const weeklyData = this.recentPriceData.filter(item => {
      const itemDate = new Date(item.created);
      return itemDate >= sevenDaysAgo && itemDate <= now;
    });

    console.log("weeklyData", weeklyData);

    if (weeklyData.length === 0) {
      this.isWeeklyDataEmpty = true; // Set flag to true if no data
    } else {
      this.isWeeklyDataEmpty = false; // Reset flag if data exists
      this.updateChartData(weeklyData);
    }
  } else if (option === "Monthly") {
    // Default to the full month's data
    this.isWeeklyDataEmpty = false; // Reset flag
    this.updateChartData(this.recentPriceData);
  }
},
 scrollToTop() {
      window.scrollTo({  
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
@import url("/src/components/CategoryPage.scss");
@import url("/src/components/Ads.scss");
@import url("/src/components/Marketprice.scss");
</style>
