<template>
    <section class="frequently-section">
        <div class="wrapper">
            <div class="row">
                <!-- Questions Section -->
                <div class="col-lg-7 col-md-7 col-sm-12">
                    <div class="frequent">
                        <div class="question-head">
                            <h2>Most Frequently Asked Questions About The VayalAgro.</h2>
                        </div>
                        <div v-for="(question, index) in questions" :key="index" class="question-box"
                            @click="toggleQuestion(index)"
                        >
                            <div class="question-header">
                                <h3>{{ question.text }}</h3>
                                <span class="toggle-icon" :class="{ open: question.isOpen }">+</span>
                            </div>
                            <div class="answer" v-if="question.isOpen">
                                <p>{{ question.answer }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Image Section -->
                <div class="col-lg-5 col-md-5 col-sm-12">
                    <div class="frequent-right">
                        <div class="tree">
                            <img src="../assets/images/Faq-tree.webp" alt="" />
                        </div>
                        <div class="tree-text">
                            <p>You didn't find your question? See more questions and ask us today?</p>
                            <button> <a href="/contact">Contact Us</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            questions: [
                {
                    text: "Is it free to post on your platform?",
                    answer: "Yes! Posting on our platform is completely free, and we do not charge any fees for listings.",
                    isOpen: true, // The first question is open by default
                },
                {
                    text: "How can I advertise with you?",
                    answer: "If you're interested in advertising, please contact us directly. We offer various promotional opportunities to help boost your visibility.",
                    isOpen: false,
                },
                {
                    text: "Are giveaways really free?",
                    answer: "Yes! Giveaways and promotions featured on our platform are 100% free of charge.",
                    isOpen: false,
                },
                {
                    text: "Do you sell or facilitate transactions?",
                    answer: "No, we are only a platform that connects users. We do not sell items, process payments, or facilitate transactions directly.",
                    isOpen: false,
                },
                {
                    text: "How can I get more information?",
                    answer: "If you need more details on anything, feel free to contact us—we’re happy to help!",
                    isOpen: false,
                },
            ],
        };
    },
    methods: {
        toggleQuestion(index) {
            this.questions.forEach((question, i) => {
                if (i === index) {
                    question.isOpen = !question.isOpen; // Toggle the clicked question
                } else {
                    question.isOpen = false; // Close all other questions
                }
            });
        },
    },
};
</script>

<style scoped>
@import url("/src/components/FrequentlyComponent.scss");
</style>
